import { Router } from '@angular/router';
import { MetadataService } from '@app/finxone-web-frontend/app/lib/services/metadata-service/metadata-service.service';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  ButtonActionType,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { map, take } from 'rxjs/operators';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function assignPersonaUser(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  metaDataService: MetadataService,
) {
  let profileData: any;

  const formStateData = store.selectSnapshot(
    FormActionState.getFormActionState,
  )?.[FormActionTypeEnum.MANAGE_USER]?.formData;

  const payloadData = {
    email: formStateData?.email,
    persona: formStateData?.assign_persona,
    roles: formStateData?.assign_role_permission,
  };

  const redirectUrl = {
    buttonActionType: ButtonActionType.ASSIGN_PERSONA,
    textContent: 'Assign',
    urlToNavigate: 'access-granted',
    zoneToNavigate: APP_ZONES.USER_MANAGEMENT,
  };

  store
    .select((state) => state.profile)
    .pipe(
      take(1),
      map((response: any) => {
        profileData = response;
      }),
    )
    .subscribe();
  metaDataService
    .associateUserPersona(profileData.activeOrganisationId, payloadData)
    .pipe(
      take(1),
      map((response) => {
        if (response) {
          if (response?.userExist === false) {
            alertHandlerService.showAlertFn(
              'success',
              'User does not exist. Please add the user.',
            );
            redirectToPage(router, widgetProperties);
          } else {
            alertHandlerService.showAlertFn(
              'success',
              'Assigned Persona successfully.',
            );
            redirectToPage(router, redirectUrl);
          }
        } else {
          alertHandlerService.showAlertFn('error', 'Failed to assign Persona');
        }
      }),
    )
    .subscribe();
}
