import { CurrencyPipe, DatePipe } from '@angular/common';
import Handlebars from 'handlebars';

import {
  addComma,
  addDashesToSortCode,
  nthLetter,
  truncate,
} from '../string.utils';
import { registerComparisonHelpers } from './comparison.helper';
import {
  countryCodeToCircleFlag,
  currencyToCircleFlag,
} from './currency-to-flag.utils';
import { imageFromImageName } from './image.utils';
import { isAfter, isAfterAndBefore } from './is-after.helper';
import { displayFeeCharge, valueToTitleChange } from './title.utils';

export function registerHandlerbarHelpers(
  datePipe: DatePipe,
  currencyPipe: CurrencyPipe,
) {
  registerComparisonHelpers();

  Handlebars.registerHelper('isAfter', isAfter);
  Handlebars.registerHelper('isAfterAndBefore', isAfterAndBefore);
  Handlebars.registerHelper('truncate', truncate);
  Handlebars.registerHelper('nthLetter', nthLetter);
  Handlebars.registerHelper('currencyToFlag', currencyToCircleFlag);
  Handlebars.registerHelper('countryCodeToFlag', countryCodeToCircleFlag);
  Handlebars.registerHelper('imageFromImageName', imageFromImageName);
  Handlebars.registerHelper('addComma', addComma);
  Handlebars.registerHelper('valueToTitleChange', valueToTitleChange);
  Handlebars.registerHelper(
    'feeChargeValue',
    function (feeCharge, unitChargeType, currency) {
      return displayFeeCharge(
        feeCharge,
        unitChargeType,
        currency,
        currencyPipe,
      );
    },
  );
  Handlebars.registerHelper(
    'currencyPipe',
    function (value: number | string, options: any) {
      // Handle null, undefined, and empty string cases
      if (value === null || value === undefined || value === '') {
        return null;
      }

      let numberValue: number;
      if (typeof value === 'number') {
        numberValue = value;
      } else {
        // Replace commas and convert the input to a number
        const sanitizedValue = (value as string).replace(/,/g, '');
        numberValue = parseFloat(sanitizedValue);
      }

      if (isNaN(numberValue)) {
        return null;
      }
      return currencyPipe
        .transform(
          numberValue,
          options.hash.currencyCode,
          options.hash.display,
          options.hash.digitsInfo,
          options.hash.locale,
        )
        ?.replace(/([^\d.,])(\d)/, '$1 $2');
    },
  );
  Handlebars.registerHelper('formatMask', function format(value, pattern) {
    let i = 0;
    const v = value?.toString();
    if (value) {
      return pattern.replace(/#/g, () => v[i++]);
    }

    return '-';
  });
  Handlebars.registerHelper('addDashesToSortCode', addDashesToSortCode);
  Handlebars.registerHelper(
    'datePipe',
    function (value: string | number | Date, options: any) {
      return datePipe.transform(
        value,
        options.hash.format,
        options.hash.timezone,
        options.hash.locale,
      );
    },
  );

  Handlebars.registerHelper('for', function (from, to, incr, block) {
    let accum = '';
    for (let i = from; i < to; i += incr) accum += block.fn(i);
    return accum;
  });

  /**
   * For Extracting time and date from a give string e.g: 02/05/2024 06:08
   * It returns date value formatted like 02 May 2024 (as per e.g.)
   * It returns time value in 12Hr format
   */
  Handlebars.registerHelper('splitDateTime', (dateTimeStr, type) => {
    const parts = dateTimeStr.split(' ');
    const dateStr = parts[0];
    const time = parts[1];

    // Create a date object from the date string
    const [day, month, year] = dateStr
      .split('/')
      .map((num: string) => parseInt(num, 10));
    const date = new Date(year, month - 1, day);

    if (type === 'time') {
      // Split the time into hours and minutes
      // eslint-disable-next-line prefer-const
      let [hours, minutes] = time
        .split(':')
        .map((num: string) => parseInt(num, 10));
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
    } else {
      // Format the date as '28 Nov 2023'
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return `${date.getDate()} ${
        monthNames[date.getMonth()]
      } ${date.getFullYear()}`;
    }
  });
}
