import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertHandlerService {
  showAlert = new Subject<{
    type: string;
    msg: string | undefined;
    headerMsg: string | undefined;
  }>();
  timer = 4000;
  showAlertFn(event: 'success' | 'error', msg?: string, headerMsg?: string) {
    this.showAlert.next({ type: event, msg: msg, headerMsg: headerMsg });
    setTimeout(() => {
      this.resetAlert();
    }, this.timer);
  }

  resetAlert() {
    this.showAlert.next({ type: '', msg: '', headerMsg: '' });
  }
}
