import { CardInfoType } from '@finxone-platform/shared/sys-config-types';

export class GetCards {
  static readonly type = '[Account] GetCards';
}

export class GetCardDetails {
  static readonly type = '[Card] GetCardDetails';
  constructor(public payload: { accountId: string; cardId: string }) {}
}

export class UpdateCurrentCardDetail {
  static readonly type = '[Card] UpdateCurrentCardDetail';
  constructor(public cardItemInfo: CardInfoType, public cardId?: string) {}
}
export class FlipCard {
  static readonly type = '[Card] FlipCard';
}
