import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdHocFeeRequest,
  AddEditFeeGroupRequestPayload,
  AddEditFeeRequestPayload,
  ClientDetailsType,
  ClientListingResponse,
  FeeGroup,
  FeeGroupsListingResponse,
  FeeListingResponse,
} from '@finxone-platform/shared/sys-config-types';
import { Observable } from 'rxjs';
import { ConfigService } from '../config-service/config-service.service';

export interface IFeeManagementService {
  getAllFeeGroups(): Observable<FeeGroup[]>;
}

@Injectable({
  providedIn: 'root',
})
export class FeeManagementService {
  private baseUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public getAllFeeGroups(): Observable<FeeGroup[]> {
    return this.http.get<FeeGroup[]>(`${this.baseUrl}/fee-groups/all`);
  }

  public submitAddEditFeeRequest(
    payload: AddEditFeeRequestPayload,
  ): Observable<AddEditFeeRequestPayload> {
    return this.http.post<AddEditFeeRequestPayload>(
      `${this.baseUrl}/fees`,
      payload,
    );
  }

  public submitEditFeeRequest(
    payload: AddEditFeeRequestPayload | AdHocFeeRequest,
    id: string,
  ): Observable<AddEditFeeRequestPayload | AdHocFeeRequest> {
    return this.http.put<AddEditFeeRequestPayload | AdHocFeeRequest>(
      `${this.baseUrl}/fees/${id}`,
      payload,
    );
  }

  public createAdHocFeeFeeRequest(
    payload: AdHocFeeRequest,
  ): Observable<AdHocFeeRequest> {
    return this.http.post<AdHocFeeRequest>(
      `${this.baseUrl}/fees/ad-hoc`,
      payload,
    );
  }

  public submitAddFeeGroupRequest(
    payload: AddEditFeeGroupRequestPayload,
  ): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      `${this.baseUrl}/fee-groups`,
      payload,
    );
  }

  public submitEditFeeGroupRequest(
    payload: AddEditFeeGroupRequestPayload,
    id: string,
  ): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(
      `${this.baseUrl}/fee-groups/${id}`,
      payload,
    );
  }

  public getFeeListing(
    page: number,
    size: number,
    searchValue?: string,
  ): Observable<FeeListingResponse> {
    let url = `${this.baseUrl}/fees?page=${page}&size=${size}`;
    if (searchValue) {
      url += `&query=${searchValue}`;
    }
    return this.http.get<FeeListingResponse>(url);
  }

  public getClientFeeListing(
    clientId: string,
    page: number,
    size: number,
    searchValue?: string,
  ): Observable<FeeListingResponse> {
    let url = `${this.baseUrl}/fees/client/${clientId}/ad-hoc`;
    if (searchValue) {
      url += `&query=${searchValue}`;
    }
    return this.http.get<FeeListingResponse>(url);
  }

  public getFeeGroupByIdFeeListing(
    selectedGroupId: string,
    page: number,
    size: number,
    searchValue?: string,
  ): Observable<FeeListingResponse> {
    let url = `${this.baseUrl}/fee-groups/${selectedGroupId}/fees?page=${page}&size=${size}`;
    if (searchValue) {
      url += `&query=${searchValue}`;
    }
    return this.http.get<FeeListingResponse>(url);
  }

  public getFeeGroupListing(
    page: number,
    size: number,
    searchValue?: string,
  ): Observable<FeeGroupsListingResponse> {
    let url = `${this.baseUrl}/fee-groups?page=${page}&size=${size}`;
    if (searchValue) {
      url += `&query=${searchValue}`;
    }
    return this.http.get<FeeGroupsListingResponse>(url);
  }

  public getSelectedClientDetails(
    clientId: string,
  ): Observable<ClientDetailsType> {
    const url = `${this.baseUrl}/fees/client/${clientId}`;
    return this.http.get<ClientDetailsType>(url);
  }

  public removeFeeGroup(groupId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/fee-groups/${groupId}`);
  }

  public removeFee(FeeId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/fees/${FeeId}`);
  }

  public removeClientFromFeeGroup(
    feeGroupId: string,
    clientId: string,
  ): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.baseUrl}/fee-groups/${feeGroupId}/client/${clientId}`,
    );
  }

  public updateClientFeeGroupRequest(
    feeGroupId: string,
    clientId: string,
  ): Observable<boolean> {
    return this.http.patch<boolean>(
      `${this.baseUrl}/fee-groups/${feeGroupId}/client/${clientId}`,
      {},
    );
  }

  public getAllClientListing(
    page: number,
    size: number,
    searchValue?: string,
  ): Observable<ClientListingResponse> {
    let url = `${this.baseUrl}/fees/clients?page=${page}&size=${size}`;
    if (searchValue) {
      url += `&query=${searchValue}`;
    }
    return this.http.get<ClientListingResponse>(url);
  }

  public getFeeGroupByIdClientListing(
    selectedGroupId: string,
    page: number,
    size: number,
    searchValue?: string,
  ): Observable<ClientListingResponse> {
    let url = `${this.baseUrl}/fee-groups/${selectedGroupId}/clients?page=${page}&size=${size}`;
    if (searchValue) {
      url += `&query=${searchValue}`;
    }
    return this.http.get<ClientListingResponse>(url);
  }

  public getFeeChargeHistoryListing(
    page: number,
    size: number,
    searchValue?: string,
  ): Observable<FeeListingResponse> {
    let url = `${this.baseUrl}/fees/charges/history?page=${page}&size=${size}`;
    if (searchValue) {
      url += `&query=${searchValue}`;
    }
    return this.http.get<FeeListingResponse>(url);
  }
}
