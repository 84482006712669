import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardAdapterChildComponent } from '@app/finxone-web-frontend/app/lib/services/card-adapter-service/card-adapter-child-component';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import { AutoDestroy } from '@finxone-platform/shared/base-types';
import {
  PaymentProviderType,
  baseCssConfigStyle,
} from '@finxone-platform/shared/sys-config-types';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Subject, map, takeUntil } from 'rxjs';
import { AdapterCardComponent } from '../adapter-card-component/adapter-card.component';

@Component({
  selector: 'finxone-web-frontend-IF-card',
  templateUrl: './IF-card.component.html',
  styleUrls: ['./IF-card.component.scss'],
})
export class IFCardComponent
  extends CardAdapterChildComponent<PaymentProviderType>
  implements OnInit
{
  @AutoDestroy private destroy$: Subject<void> = new Subject<void>();

  public borderStyleCardActivationStripe: baseCssConfigStyle;

  public platformName = '';

  constructor(
    private router: Router,
    private ngxSmartModalService: NgxSmartModalService,
    private configService: ConfigService,
    @Inject(AdapterCardComponent) parent: AdapterCardComponent,
  ) {
    super();
    this.parent = parent;

    this.configService
      .getSystemName()
      .pipe(
        takeUntil(this.destroy$),
        map((systemName) => {
          this.platformName = systemName;
        }),
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.borderStyleCardActivationStripe = {
      'border-bottom-left-radius':
        this.borderStyle['border-bottom-left-radius'],
      'border-bottom-right-radius':
        this.borderStyle['border-bottom-right-radius'],
    };

    this.borderStyle = {
      ...this.borderStyle,
      'background-color':
        this.virtualCardData.type === 'virtual'
          ? this.parent.attri['virtualCardColor']
          : this.parent.attri['physicalCardColor'],
    };
  }

  public formatMaskedPan(maskedPan: string): string {
    return (
      this.parent.prefixLast4PanText +
      maskedPan.slice(maskedPan.length - 4, maskedPan.length)
    );
  }

  public getExpiryDateWithFormat(expiry_date: string) {
    let newExpiryDate = '--/--';
    if (expiry_date && expiry_date.split('-')[0].length == 4) {
      newExpiryDate =
        expiry_date.split('-')[1] + '/' + expiry_date.split('-')[0].slice(2);
    }
    return newExpiryDate;
  }

  public goToCardManagement(): void {
    this.router.navigateByUrl('/zones/card-management/management');
  }

  public openAddCardBottomPopup(): void {
    this.ngxSmartModalService.getModal('add-card').open();
  }
}
