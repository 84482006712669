import {
  PaymentProvider,
  accountServicePaymentRequest,
} from '@finxone-platform/shared/sys-config-types';

export class GetAccount {
  static readonly type = '[Account] GetAccount';
  constructor(public page: number = 1, public limit: number = 10) {}
}

export class GetAccountDetails {
  static readonly type = '[Account] GetAccountDetails';
}

export class GetMonthlySummary {
  static readonly type = '[Account] GetMonthlySummary';
  constructor(public accountId: string) {}
}

export class UpdatePaymentGatewayConfig {
  static readonly type = '[Account] UpdatePaymentGatewayConfig';
  constructor(
    public payload: {
      payment: PaymentProvider;
      sandboxMode?: string;
      supportedCurrency: string[];
      bankProvider: string;
      checkPayeeEnabled: boolean;
      paymentRequest: accountServicePaymentRequest;
    },
  ) {}
}

export class SetLoadingStatus {
  static readonly type = '[Account] SetLoadingStatus';
  constructor(public isLoading: boolean) {}
}

export class UpdateCurrentAccountId {
  static readonly type = '[Account] UpdateCurrentAccountId';
  constructor(public currentAccountId: string) {}
}

export class ClearAccountCache {
  static readonly type = '[Account] ClearAccountCache';
  constructor() {}
}

export class CreateCurrencyAccount {
  static readonly type = '[Account] CreateCurrencyAccount';
  constructor(public currency: string) {}
}

export class CreateCurrencyAccountSuccess {
  static readonly type = '[Account] CreateCurrencyAccountSuccess';
}

export class SetTotalWealth {
  static readonly type = '[Account] SetTotalWealth';
  constructor(public enableTotalWealth: boolean) {}
}

export class GetTotalWealth {
  static readonly type = '[Account] GetTotalWealth';
  constructor(public baseCurrency: string) {}
}
