/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import {
  RemoveSelectedCSVFileFromBulkPaymentsFormState,
  ResetFormDataAction,
  SetFormAction,
  SetFormActionWithId,
  UpdateFormDataAction,
  UpdateFormDataActionWithId,
  UpdateFormPageAction
} from '../actions/form-submission.action';

export type FormDataType = Record<string, string> | any;

export type FormActionType = {
  type: string;
  nextPageUrl?: string | null;
  formData?: FormDataType;
  formPages?: { [key: string]: { isVerified: boolean } };
  isLastPage?: boolean;
};

export type FormSubmissionType = {
  formData?: Record<string, string> | any;
};

export type FormPagesType = {
  formPages: { [key: string]: { isVerified: boolean } };
};

export interface FormActionStateModel {
  response: FormActionType | null;
  [formId: string]: FormActionType | null;
}

const initialFormState: FormActionStateModel = {
  response: {
    type: '',
    nextPageUrl: '',
    formData: {},
    isLastPage: false,
    formPages: {},
  },
};

@State<FormActionStateModel>({
  name: 'FormActionState',
  defaults: initialFormState,
})
@Injectable()
export class FormActionState {
  @Selector()
  static getFormActionState(state: FormActionStateModel) {
    return state;
  }

  static getFormActionStateWithId(id: string) {
    return createSelector(
      [FormActionState],
      (state: FormActionStateModel): FormActionType | null => {
        return state[id] ?? null;
      },
    );
  }
  static getProfilePictureWithIdAndKey<T>(id: string, key: string) {
    return createSelector(
      [FormActionState],
      (state: FormActionStateModel): T => {
        return state[id]?.formData[key] ?? null;
      },
    );
  }

  @Action(SetFormAction)
  setFormAction(
    ctx: StateContext<FormActionStateModel>,
    action: SetFormAction,
  ) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      response: {
        ...state.response,
        ...action.payload,
      },
    });
  }

  @Action(SetFormActionWithId)
  setFormActionWithId(
    ctx: StateContext<FormActionStateModel>,
    action: SetFormActionWithId,
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      [action.id]: {
        ...state[action.id],
        ...action.payload,
        formData: {
          ...state[action.id]?.formData,
          ...action.payload.formData,
        },
      },
    });
  }

  @Action(UpdateFormDataAction)
  updateFormData(
    ctx: StateContext<FormActionStateModel>,
    { payload, id }: UpdateFormDataAction,
  ) {
    let formData: any;
    // unwraps the old format if being used
    if (payload.formData) {
      formData = payload.formData;
    } else {
      formData = payload;
    }

    if (id) {
      this.updateFormDataWithId(
        ctx,
        new UpdateFormDataActionWithId(formData, id),
      );
    } else {
      ctx.setState((state) => {
        const updatedResponse: FormActionType = {
          ...(state.response ?? {}),
          type: '',
          nextPageUrl: '',
          isLastPage: state.response?.isLastPage,
          formData: {
            ...(state.response?.formData ?? {}),
            ...formData,
          },
        };
        return {
          ...state,
          response: updatedResponse,
        };
      });
    }
  }

  @Action(UpdateFormDataActionWithId)
  updateFormDataWithId(
    ctx: StateContext<FormActionStateModel>,
    action: UpdateFormDataActionWithId,
  ) {
    ctx.setState((state) => {
      const updateForm: FormActionType = {
        type: action.formData['type'] ?? '',
        nextPageUrl: state[action.id]?.nextPageUrl ?? '',
        isLastPage: state[action.id]?.isLastPage,
        formData: {
          ...state[action.id]?.formData,
          ...action.formData,
        },
      };

      return {
        ...state,
        [action.id]: updateForm,
      };
    });
  }

  @Action(UpdateFormPageAction)
  updateFormPage(
    { setState }: StateContext<FormActionStateModel>,
    { payload }: UpdateFormPageAction,
  ) {
    setState((state) => {
      const updatedResponse: FormActionType = {
        ...(state.response ?? {}),
        type: '',
        nextPageUrl: '',
        formData: state.response?.formData,
        isLastPage: state.response?.isLastPage,
        formPages: {
          ...(state.response?.formPages ?? {}),
          ...payload.formPages,
        },
      };
      return {
        ...state,
        response: updatedResponse,
      };
    });
  }

  @Action(ResetFormDataAction)
  resetFormData({ setState }: StateContext<FormActionStateModel>) {
    setState(() => {
      return initialFormState;
    });
  }

  @Action(RemoveSelectedCSVFileFromBulkPaymentsFormState)
  removeSelectedCSVFileFromBulkPaymentsFormState({
    setState,
    getState,
  }: StateContext<FormActionStateModel>) {
    const state = getState()?.[FormActionTypeEnum.BULK_PAYMENTS];
    if (state?.formData?.myFiles?.length) delete state.formData.myFiles;
    setState(() => {
      return {
        ...getState(),
        [FormActionTypeEnum.BULK_PAYMENTS]: state,
      };
    });
  }
}
