import { Injectable } from '@angular/core';
import {
  CardInfoType,
  CardStatusType,
  PaginatedResponse,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { UpdateCardManagement } from '../actions/card-management.action';
import {
  GetCardDetails,
  GetCards,
  UpdateCurrentCardDetail,
} from '../actions/card.actions';
import {
  AddProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';
import { CardManagementService } from '../services/card-management-service/card-management-service';
import { CardManagementState } from './card-management.state';

export interface CardStateModel {
  items: CardInfoType[];
  currentCardId: string;
  currentCardDetail: CardInfoType;
}

@State<CardStateModel>({
  name: 'cards',
  defaults: {
    items: [],
    currentCardId: '',
    currentCardDetail: {} as CardInfoType,
  },
  children: [CardManagementState],
})
@Injectable()
export class CardState {
  constructor(
    private cardManagementService: CardManagementService,
    private store: Store,
  ) {}

  @Selector()
  static getCards(state: CardStateModel) {
    return state;
  }

  @Selector()
  static getCurrentCardId(state: CardStateModel) {
    return state.currentCardId;
  }

  @Selector()
  static getCurrentCardDetail(state: CardStateModel) {
    return state.currentCardDetail;
  }

  @Action(GetCards)
  fetchCards(ctx: StateContext<CardStateModel>) {
    try {
      ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCards' }));

      return this.cardManagementService.getCards().pipe(
        tap((cardsList: PaginatedResponse<CardInfoType>) => {
          const [firstCard] = cardsList.result.filter(
            (v) =>
              v.status === CardStatusType.ACTIVE ||
              v.status === CardStatusType.INACTIVE ||
              v.status === CardStatusType.PENDING ||
              v.status === CardStatusType.BLOCKED,
          );
          ctx.setState({
            ...ctx.getState(),
            items: cardsList.result,
            currentCardDetail: firstCard,
            currentCardId: firstCard?.id,
          });

          ctx.dispatch(
            new UpdateCardManagement({ cardStatus: firstCard?.status }),
          );

          ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCards' }));
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCards' }));
          throw _err;
        }),
      );
    } catch (err) {
      ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetCards' }));
      return throwError(() => err);
    }
  }

  @Action(GetCardDetails)
  fetchCardDetails(ctx: StateContext<CardStateModel>, action: GetCardDetails) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetCardDetails' }));
    return this.cardManagementService.getCardDetails(action.payload).pipe(
      tap((response) => {
        let cardList = ctx.getState().items;
        cardList = cardList.map((card) => {
          if (card.id === response.id) {
            return response;
          }
          return card;
        });

        ctx.setState({
          ...ctx.getState(),
          items: cardList,
          currentCardDetail: response,
          currentCardId: response.id,
        });

        ctx.dispatch(new UpdateCardManagement({ cardStatus: response.status }));

        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'GetCardDetails' }),
        );
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'GetCardDetails' }),
        );
        throw _err;
      }),
    );
  }

  @Action(UpdateCurrentCardDetail)
  updateCurrentCardDetail(
    ctx: StateContext<CardStateModel>,
    action: UpdateCurrentCardDetail,
  ) {
    const cardList = [...ctx.getState().items];
    const cardIndex = cardList.findIndex((card) => card.id === action.cardId);
    if (cardIndex !== -1) {
      cardList.splice(
        cardIndex,
        1,
        action.cardItemInfo as unknown as CardInfoType,
      );
    }
    ctx.patchState({
      ...ctx.getState(),
      currentCardId: action.cardItemInfo.id,
      currentCardDetail: action.cardItemInfo,
      items: cardList,
    });
    this.store.dispatch(
      new UpdateCardManagement({ cardStatus: action.cardItemInfo.status }),
    );
  }
}
