<form
  class="is-vertical-align h-100 w-100 pointer-events-none"
  [ngStyle]="contentStyle"
  [formGroup]="intlBeneficiaryCredentialsForm"
>
  <drag-scroll
    [scrollbar-hidden]="true"
    [drag-scroll-disabled]="true"
    [snap-duration]="100"
    (indexChanged)="onIndexChanged($event)"
    class="w-100"
    #beneficiaryDetailsFieldsCarousel
  >
    <ng-container *ngFor="let field of fieldsList">
      <div class="w-100 carousel mr-0" drag-scroll-item>
        <finx-input
          [formControlName]="field.title"
          [label]="
            field.item.requirement === 'required'
              ? field.label + '*' + currentFieldAndCount
              : field.label + currentFieldAndCount
          "
          [inputStyle]="field.currentStyle"
          [textInputFontStyle]="textInputFontStyle"
          (focusEvent)="setCurrentStyles($event)"
          (keydown.Tab)="onKeyDown($event)"
          (input)="valueChangeTrigger()"
          [value]="intlBeneficiaryCredentialsForm.get?.(field.title)?.value"
        ></finx-input>
        <div
          class="text-danger error-message"
          [ngClass]="{ active: !isFormFieldValid && nextBtnFailed }"
        >
          <small>{{ getErrorMessage() }}</small>
        </div>
      </div>
    </ng-container>
  </drag-scroll>

  <div class="nav-buttons w-100 mb-4">
    <button
      (click)="goToPreviousField()"
      [ngStyle]="previousButtonStyle"
      [disabled]="currentIndex === 0"
    >
      <mat-icon [svgIcon]="'left-arrow'" class="button-icon-svg"></mat-icon>
      {{ previousButtonContent }}
    </button>

    <button
      (click)="goToNextField()"
      [ngStyle]="nextButtonStyle"
      [disabled]="currentIndex === fieldsList.length - 1"
    >
      {{ nextButtonContent }}
      <mat-icon [svgIcon]="'arrow-right'" class="button-icon-svg"></mat-icon>
    </button>
  </div>
  @if (!isSubmitTypeAsynchronous) {
  <div class="d-flex">
    <button
      (click)="onSubmit()"
      [ngStyle]="continueButtonStyle"
      class="continue-button w-100"
      [disabled]="intlBeneficiaryCredentialsForm.status === 'INVALID'"
    >
      {{ continueButtonContent }}
    </button>
  </div>
  }
</form>
