import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { OrganisationState } from '../../../state/organisation.state';
import { redirectToPage } from '../cta-button-actions.utils';

/**
 * Fetches the data from Organisation state and call the Update method
 */
export function getOrganisationDataFromStoreAndCallUpdateMethod(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  __: AlertHandlerService,
  metadataService: MetadataService,
) {
  const organisationData = store.selectSnapshot(
    OrganisationState.getOrganisation,
  );
  let orgId = '';
  let formData = {};
  if (organisationData.name) {
    orgId = organisationData.id;
    formData = {
      organisation_companyName: organisationData.name,
      addressCountry: organisationData.address.country,
      addressStreet: organisationData.address.street,
      addressCity: organisationData.address.city,
      addressState: organisationData.address.state,
      addressPostCode: organisationData.address.postalCode,
      identificationNumber: organisationData.identificationNumber,
      incorporationDate: organisationData.incorporationDate,
    };
    upateOrganisationAndRedirect(
      router,
      metadataService,
      orgId,
      formData,
      widgetProperties,
    );
  } else {
    console.error('Organisation data is not avaiable');
  }
}
/**
 * @param orgId: Organisation Id from the Organisation State
 * @param formData: Organisation data from the Organisation State
 * Submit the organisation data in API so that the org kyb status changes in the BE
 * Redirect the user to information confirmation screen after API call is done
 */
export function upateOrganisationAndRedirect(
  router: Router,
  metadataService: MetadataService,
  orgId: string,
  formData: Record<string, string>,
  widgetProperties: BaseWidgetProperties,
) {
  if (orgId) {
    metadataService
      .updateOrganisation(orgId, formData)
      .subscribe((response) => {
        if (response) redirectToPage(router, widgetProperties);
      });
  }
}
