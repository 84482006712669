import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { KeycloakService } from 'keycloak-angular';
import { Observable, catchError, throwError } from 'rxjs';
import { clearLocalStorageExcept } from './utils/utils';
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private alertHandlerService: AlertHandlerService,
    protected keycloakService: KeycloakService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error);
      }),
    );
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage: any;
    let errorObj: any;
    if (this.isJSONString(error.error)) {
      errorObj = JSON.parse(error.error);
    } else {
      errorObj = error.error;
    }
    if (errorObj?.message?.length) {
      if (typeof errorObj.message == 'string') {
        errorMessage = errorObj.message;
      } else {
        errorMessage = errorObj.message[0];
      }
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${
        errorObj?.message ? errorObj.message : 'Server Error'
      }`;
    }

    if (error.status === 401) {
      clearLocalStorageExcept([
        'account',
        'transactions',
        'beneficiary',
        'notifications',
        'lastLoggedInId',
        'APP_PREFERENCES',
      ]);
      this.keycloakService.login({
        redirectUri: window.location.href,
      });
    } else if (error.status !== 401) {
      this.show(errorMessage);
    }
    return throwError(() => new Error(errorMessage));
  }

  show(errorMessage: any) {
    console.log(errorMessage);
    this.alertHandlerService.showAlertFn('error', errorMessage);
  }

  isJSONString(str: string) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
}
