export enum CardStatusType {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  SUSPENDED = 'suspended',
  DECLINED = 'declined',
  TERMINATED = 'terminated',
  PENDING = 'pending',
  INACTIVE = 'inactive',
}

export enum CreateCardStatusType {
  REJECT = 'rejected',
  CREATED = 'created',
}

export enum CardSchemeType {
  VISA = 'visa',
  MASTER = 'mastercard',
}

export enum CardType {
  PHYSICAL = 'physical',
  VIRTUAL = 'virtual',
}
export enum CardSortType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL',
  UNORDERED = 'unordered',
}
export enum AddCardOption {
  ABOVE = 'above',
  END_CAROUSEL = 'end-carousel',
  BOTH = 'both',
}

export enum PaymentProvider {
  MODULR = 'modulr',
  INTEGRATED_FINANCE = 'integratedFinance',
  MEAWALLET = 'meawallet',
}

export type CardInfoType = {
  id: string;
  name?: string;
  limit?: number;
  maxLimit?: number;
  spend?: number;
  expiry: string;
  maskedPan: string;
  type: CardType;
  status: CardStatusType;
  suspensionReason?: string | null;
  terminationReason?: string | null;
  shippingAddress?: {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    city: string;
    postCode: string;
    country: string;
  };
  createdDate: string;
  activatedDate?: string;
};

export type CardAuthenticationType = {
  id: string;
  orgId: string;
  cardId: string;
  status: CardAuthenticationStatus;
  merchant: Merchant;
  transaction: Transaction;
};

export type CardAuthenticationStatus =
  | 'pending'
  | 'accepted'
  | 'rejected'
  | 'cancelled';

export type PaymentProviderType = 'modulr' | 'integratedFinance' | 'meawallet';

export type CardExtraAttributes = {
  client_id?: '';
};
export type CreateCardType = {
  // Used to determine the type of card that is being created
  // should reference the identifier used in the project level config
  cardReferenceType: string;
  // Card name alias
  name?: string;
  shippingAddress?: {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    city: string;
    postCode: string;
    country: string;
  };
  accountIds: string[];
  userId: string;
  organisationId: string;
  type: CardType;
  // Used by modulr need to move where this is located to project settings?
  cardRef?: string;
  packagingRef?: string;
};

export type CreateCardResponse = {
  status: CardStatusType;
  cardId: string;
  accountIds: string[];
  attributes: CardResponseAttributes;
};

export type CardResponseAttributes = {
  serviceProvider?: string;
  managementToken?: string;
  cardProduct?: string;
  cardManufacturer?: string;
  taskId?: string;
};

export type CreateCardData = {
  organisationId: string;
  cardId: string;
  cardDesignId: string;
  cardStatus: CardStatusType;
  cardTypeIdId: string;
  accountId: string;
  userId: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  extra_attributes: any;
};

export type ListCard = {
  accountId: string;
  page: number;
  size: number;
};

export type CardsList = {
  result: CardInfoType[];
  totalPages: number;
  page: number; //Modulr API start page with 0
  totalsize: number;
};

export type CardInfo = {
  cardId: string;
  // used by modulr specifically
  managementToken?: string;
  type?: CardType;
  status?: CardStatusType;
  terminationReason?: string;
  suspensionReason?: string;
};

export type SecureCardInfo = {
  cardId: string;
  token: string;
  publicKey: string;
  tokenPurpose: CardTokenPurpose;
};

export type SecureCardTokenInfo = {
  token: string;
  tokenEncrypted: boolean;
  encryptedSymmetricKey: string;
  initialisationVector: string;
};

export type SecureCardInfoResponse = {
  pan: string;
  cvv2: string;
  pin: string;
};

export type SecureCardPinResponse = {
  pin: string;
};

export type SecureCardCVVRequest = {
  cardId: string;
  token: string;
  authToken: string;
};

export type ChangePinCardRequest = {
  cardId: string;
  token: string;
  authToken: string;
  oldPin: string;
  newPin: string;
};

export type ReplaceCardInfo = {
  cardId: string;
  userId: string;
  replaceReason: CardReplaceReason;
  // Used by modulr need to move where this is located to project settings?
  cardRef?: string;
  packagingRef?: string;
};

export type ReplaceCardRequest = {
  accountId: string;
  cardId: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postalCode: string;
  reason: string;
};

export enum CardReplaceReason {
  LOST = 'lost',
  RENEW = 'renew',
  DAMAGED = 'damaged',
  STOLEN = 'stolen',
}

export type CardReplaceResponse = {
  managementToken?: string;
  cardId: string;
  userId: string;
  expiry: string;
  maxLimit: string;
  pan: string;
};

export type ChangePinRequest = {
  secureToken: string;
  oldPin: string;
  newPin: string;
  accountId: string;
  cardId: string;
  tokenPurpose: string;
};

export type ReplaceCardResponse = {
  cardId: string;
  id: string;
  status: CardStatusType;
};

export enum CardTokenPurpose {
  READ = 'read',
  UPDATE = 'update',
}

export type ThreeDSChallengeInfo = {
  merchant: ThreeDSMerchant;
  transaction: Transaction;
};

export interface ThreeDSMerchant {
  acquirer: null | string;
  id: string;
  name: string;
  url: string;
  categoryCode?: null | string;
  country: string;
  appRedirectUrl?: null | string;
}

export interface Transaction {
  creationDateTime: Date;
  amount: number;
  currency: string;
}

export class CardTransactionRequest {
  public page: number;
  public size: number;
  public cardId?: string;
  public accountId?: string;
  public type?: CardPaymentType[];
  public status?: CardPaymentStatus[];
  public transactionNumber?: string;
  public transactionCurrency?: string;
  public maxAmount?: string;
  public minAmount?: string;
  public dateFrom?: Date;
  public dateTo?: Date;
}

export type CardPaymentStatus =
  | 'authorised'
  | 'settled'
  | 'reversed'
  | 'declined';

export type CardPaymentType =
  | 'payment'
  | 'refund'
  | 'chargeback'
  | 'atm-withdrawal'
  | 'money-transfer';

export type CardTransactionInfoType = {
  id: string;
  cardId: string;
  clientId: string;
  matchingTransactionId: null | string;
  accountId: string;
  accountCurrency: string;
  transactionNumber: string;
  type: string;
  status: string;
  transactionAmount: number;
  transactionCurrency: string;
  feeAmount: number;
  feeCurrency: string;
  billingAmount: number;
  billingCurrency: string;
  settlementAmount: number;
  settlementCurrency: string;
  effectiveTransactionAmount: number;
  effectiveFeeAmount: number;
  transactionExchangeRate: null | number;
  feeExchangeRate: null | number;
  merchant: Merchant;
  pos: Pos;
  serviceProvider: string;
  serviceProviderEntityId: string;
  serviceProviderEntityReference: string;
  description: string;
  reason: string;
  direction: string;
  totalAmount: number;
  creationDateTime: string;
};

export interface Merchant {
  name: string;
  id: string;
  networkId: string;
  taxId: string;
  address: CardMerchantAddress;
  phone: string;
  website: string;
  category: Category;
}

export interface CardMerchantAddress {
  country: string;
  postalCode: string;
  city: string;
  street: string;
}

export interface Category {
  code: string;
  description: string;
}

export interface Pos {
  cardholderPresent: null | boolean;
  cardPresent: null | boolean;
  cardDataInputMethod: null | string;
  authentication: null | string;
  chipFallback: null | string;
  fraud: null | string | boolean;
  securityProtocol: null | string;
  cardAuthentication3DSecureMethod: string;
}
