import { Section } from '../workflow.type';
import {
  WorkflowPageStateConfig,
  WorkflowWidgetConfig,
} from '../workflow.v2.type';

export function isWorkflowWidgetConfig(
  config: WorkflowWidgetConfig | Section,
): config is WorkflowWidgetConfig {
  return (
    (<WorkflowWidgetConfig>config)?.widgetTypeConfig !== undefined &&
    (<WorkflowWidgetConfig>config)?.widgetTypeConfig === 'new'
  );
}

export function isWorkflowPageStateConfig(
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  config: any,
): config is WorkflowPageStateConfig {
  return (
    (<WorkflowPageStateConfig>config)?.pageStateTypeConfig !== undefined &&
    (<WorkflowPageStateConfig>config)?.pageStateTypeConfig === 'new'
  );
}

export const euCountriesList = [
  {
    currencyFlag: '<img src="assets/flags/fr.svg" width="24px">',
    title: 'France',
    key: 'france',
  },
  {
    currencyFlag: '<img src="assets/flags/it.svg" width="24px">',
    title: 'Italy',
    key: 'italy',
  },
  {
    currencyFlag: '<img src="assets/flags/pt.svg" width="24px">',
    title: 'Portugal',
    key: 'portugal',
  },
  {
    currencyFlag: '<img src="assets/flags/nl.svg" width="24px">',
    title: 'Netherlands',
    key: 'netherlands',
  },
  {
    currencyFlag: '<img src="assets/flags/be.svg" width="24px">',
    title: 'Belgium',
    key: 'belgium',
  },
];
