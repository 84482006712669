import {
  APP_ZONES,
  APP_ZONES_TITLE,
  BaseCssConfig,
  ENTITY_TYPE,
  NavBar,
  NavBarDesktop,
  NavBarLayoutItem,
  NavBarMobile,
  PermissionConfig,
  Role,
  StyleConfig,
  WorkflowConfig,
  WorkflowUserType,
  ZONETYPEOPTION,
} from '../workflow.type';

export function cryptoRandomUUID(): string {
  try {
    const crypto = window.crypto;
    return crypto.randomUUID();
  } catch (error) {
    return '';
  }
}

export enum PACKS {
  NEOBANK = 'neobank_pack',
  TRUST_BANK = 'trust_bank_pack',
  FINXONE = 'finxone_pack',
  TRAVEL_MANAGEMENT = 'travel_management_pack',
  REVENIR = 'revenir',
  CARD_MANAGEMENT = 'card_management',
}

export const defaultSignedOutRole = {
  id: '',
  name: 'Signed Out',
  userType: WorkflowUserType.SIGNED_OUT,
  slug: WorkflowUserType.SIGNED_OUT,
  zones: {
    [APP_ZONES.SIGNED_OUT_INTRODUCTION]: null,
    [APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION]: null,
    [APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD]: null,
  },
  is_removable: false,
  navBar: {
    navBarMobile: {
      layout: [],
      iconSize: '24 x 24',
      marginTop: 1,
      marginLeft: 1,
      marginUnit: 'px',
      paddingTop: 1,
      showLabels: true,
      borderColor: '#DADADA',
      borderWidth: 1,
      marginRight: 1,
      navBarColor: '#FDFDFD',
      paddingUnit: 'px',
      marginBottom: 1,
      paddingBottom: 1,
      paddingLeft: 1,
      paddingRight: 1,
      borderWidthUnit: 'px',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 1,
      borderRadiusTopRight: 1,
      borderRadiusBottomLeft: 1,
      borderRadiusBottomRight: 1,
    },
    navBarDesktop: {
      logo: 'logo/path',
      layout: [],
      iconSize: '24 x 24',
      menuType: 'vertical',
      navBarColor: '#FDFDFD',
      userNameColor: '#6A707E',
      logOutColor: '#D55F5A',
      activeItemColor: '##e4e4e4',
      marginTop: 1,
      marginLeft: 1,
      marginUnit: 'px',
      paddingLeft: 1,
      marginRight: 1,
      paddingUnit: 'px',
      marginBottom: 1,
      paddingRight: 1,
      paddingTop: 1,
      paddingBottom: 1,
      width: 200,
      logoType: 'text',
    },
  },
  applied_all_zones: false,
};

//Add new constants from here
export const permissionSections = {
  subscriptions: {
    default: {
      title: 'Default',
    },
    gold: {
      title: 'Gold',
    },
    silver: {
      title: 'Silver',
    },
    bronze: {
      title: 'Bronze',
    },
  },
  zones: {
    onboarding: {
      title: APP_ZONES_TITLE.ONBOARDING_PAGE,
      name: APP_ZONES.ONBOARDING,
      position: 1,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '',
      userTypes: [WorkflowUserType.USER, WorkflowUserType.ORG],
      packs: [PACKS.FINXONE],
    },
    verification_zone: {
      title: APP_ZONES_TITLE.VERIFICATION,
      name: APP_ZONES.VERIFICATION,
      position: 2,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '',
      userTypes: [WorkflowUserType.USER, WorkflowUserType.ORG],
      packs: [PACKS.FINXONE],
    },
    charts_zone: {
      title: APP_ZONES_TITLE.CHART,
      name: APP_ZONES.CHART,
      position: 3,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/charts/spending',
      userTypes: [WorkflowUserType.USER, WorkflowUserType.ORG],
      packs: [PACKS.NEOBANK],
    },
    landing_zone: {
      title: APP_ZONES_TITLE.LANDING,
      name: APP_ZONES.LANDING,
      position: 4,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/landing/home',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.FINXONE],
    },
    profile_zone: {
      title: APP_ZONES_TITLE.PROFILE,
      name: APP_ZONES.PROFILE,
      position: 5,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/profile/profile',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.FINXONE],
    },
    payments_zone: {
      title: APP_ZONES_TITLE.PAYMENT,
      name: APP_ZONES.PAYMENT,
      position: 6,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/payments/transfer',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.NEOBANK],
    },
    user_management_zone: {
      title: APP_ZONES_TITLE.USER_MANAGEMENT,
      name: APP_ZONES.USER_MANAGEMENT,
      position: 7,
      allowedViews: {
        mobile: false,
        tablet: false,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/user-management/user-management',
      userTypes: [WorkflowUserType.ADMIN, WorkflowUserType.ORG],
      packs: [PACKS.FINXONE],
    },
    general_zone: {
      title: APP_ZONES_TITLE.GENERAL,
      name: APP_ZONES.GENERAL,
      position: 8,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/general/general',
      userTypes: [WorkflowUserType.USER, WorkflowUserType.ORG],
      packs: [PACKS.FINXONE],
    },
    menu_zone: {
      title: APP_ZONES_TITLE.MENU_ZONE,
      name: APP_ZONES.MENU_ZONE,
      position: 9,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/menu/menu',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.FINXONE],
    },
    card_management_zone_demo: {
      title: APP_ZONES_TITLE.CARD_MANAGEMENT_ZONE_DEMO,
      name: APP_ZONES.CARD_MANAGEMENT_ZONE_DEMO,
      position: 10,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/card-management_demo/management',
      userTypes: [WorkflowUserType.USER, WorkflowUserType.ORG],
      packs: [PACKS.NEOBANK],
    },
    preferences_zone: {
      title: APP_ZONES_TITLE.USER_PREFERENCE_ZONE,
      name: APP_ZONES.USER_PREFERENCE_ZONE,
      position: 11,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.ENTITYZONEQUESTION,
      entityType: ENTITY_TYPE.USER_PREFERENCE,
      is_role_specific: true,
      url: '',
      userTypes: [WorkflowUserType.USER, WorkflowUserType.ORG],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    'organisation-onboarding': {
      title: APP_ZONES_TITLE.ORGANISATION_ONBOARDING,
      name: APP_ZONES.ORGANISATION_ONBOARDING,
      position: 12,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: false,
      url: '',
      userTypes: [WorkflowUserType.ORG],
      packs: [PACKS.FINXONE],
    },
    organisation_verification: {
      title: APP_ZONES_TITLE.ORGANISATION_VERIFICATION,
      name: APP_ZONES.ORGANISATION_VERIFICATION,
      position: 13,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: undefined,
      is_role_specific: false,
      url: '',
      userTypes: [WorkflowUserType.ORG],
      packs: [PACKS.FINXONE],
    },
    intro: {
      title: APP_ZONES_TITLE.SIGNED_OUT_INTRODUCTION,
      name: APP_ZONES.SIGNED_OUT_INTRODUCTION,
      position: 14,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: undefined,
      is_role_specific: false,
      url: '',
      userTypes: [WorkflowUserType.SIGNED_OUT],
      packs: [PACKS.FINXONE],
    },
    [APP_ZONES.ORGANISATION_PROFILE]: {
      title: APP_ZONES_TITLE.ORGANISATION_PROFILE,
      name: APP_ZONES.ORGANISATION_PROFILE,
      position: 5,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/org-profile/profile',
      userTypes: [WorkflowUserType.ORG],
      packs: [PACKS.FINXONE],
    },
    'first-intro': {
      title: APP_ZONES_TITLE.SIGNED_OUT_FIRST_INTRODUCTION,
      name: APP_ZONES.SIGNED_OUT_FIRST_INTRODUCTION,
      position: 15,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: undefined,
      is_role_specific: false,
      url: '',
      userTypes: [WorkflowUserType.SIGNED_OUT],
      packs: [PACKS.FINXONE],
    },
    login: {
      title: 'Login',
      name: 'login',
      position: 16,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: undefined,
      is_role_specific: false,
      url: 'login',
      userTypes: [],
      packs: [PACKS.FINXONE],
    },
    guest_landing_zone: {
      title: APP_ZONES_TITLE.GUEST_LANDING_ZONE,
      name: APP_ZONES.GUEST_LANDING_ZONE,
      position: 14,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/guest-landing/home',
      userTypes: [WorkflowUserType.USER],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    guest_information_zone: {
      title: APP_ZONES_TITLE.GUEST_INFORMATION_ZONE,
      name: APP_ZONES.GUEST_INFORMATION_ZONE,
      position: 15,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/guest-information/home',
      userTypes: [WorkflowUserType.USER],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    guest_employee_zone: {
      title: APP_ZONES_TITLE.GUEST_EMPLOYEE_ZONE,
      name: APP_ZONES.GUEST_EMPLOYEE_ZONE,
      position: 15,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/guest-employee/home',
      userTypes: [WorkflowUserType.ORG],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    employee_information_zone: {
      title: APP_ZONES_TITLE.EMPLOYEE_INFORMATION_ZONE,
      name: APP_ZONES.EMPLOYEE_INFORMATION_ZONE,
      position: 15,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/employee-information/home',
      userTypes: [WorkflowUserType.ORG],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    employee_user_prefrence_report_zone: {
      title: APP_ZONES_TITLE.USER_PREFRENCE_REPORT_ZONE,
      name: APP_ZONES.USER_PREFRENCE_REPORT_ZONE,
      position: 15,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/employee-user-prefrence-report/home',
      userTypes: [WorkflowUserType.ORG],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    accounts_zone: {
      title: APP_ZONES_TITLE.ACCOUNTS_ZONE,
      name: APP_ZONES.ACCOUNTS_ZONE,
      position: 17,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/accounts/account-transactions',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.NEOBANK],
    },
    [APP_ZONES.BROKER_FACILITY]: {
      title: APP_ZONES_TITLE.BROKER_FACILITY,
      name: APP_ZONES.BROKER_FACILITY,
      position: 20,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      entityType: ENTITY_TYPE.FACILITY,
      is_role_specific: true,
      url: '/zones/broker-facility',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    [APP_ZONES.BROKER_EVENTS]: {
      title: APP_ZONES_TITLE.BROKER_EVENTS,
      name: APP_ZONES.BROKER_EVENTS,
      position: 20,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      entityType: ENTITY_TYPE.EVENT,
      is_role_specific: true,
      url: '/zones/broker-events',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    [APP_ZONES.BROKER_LANDING]: {
      title: APP_ZONES_TITLE.BROKER_LANDING,
      name: APP_ZONES.BROKER_LANDING,
      position: 21,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/broker-landing',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    [APP_ZONES.BROKER_EMPLOYEE]: {
      title: APP_ZONES_TITLE.BROKER_EMPLOYEE,
      name: APP_ZONES.BROKER_EMPLOYEE,
      position: 22,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/broker-employee',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    [APP_ZONES.BROKER_GUEST]: {
      title: APP_ZONES_TITLE.BROKER_GUEST,
      name: APP_ZONES.BROKER_GUEST,
      position: 21,
      allowedViews: {
        mobile: false,
        tablet: false,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/broker-guest',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.TRAVEL_MANAGEMENT],
    },
    forgot_password: {
      title: APP_ZONES_TITLE.SIGNED_OUT_FORGOT_PASSWORD,
      name: APP_ZONES.SIGNED_OUT_FORGOT_PASSWORD,
      position: 22,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: undefined,
      is_role_specific: false,
      url: '/zones/forgot-password',
      userTypes: [WorkflowUserType.SIGNED_OUT],
      packs: [PACKS.FINXONE],
    },
    [APP_ZONES.BENEFICIARY_ZONE]: {
      title: APP_ZONES_TITLE.BENEFICIARY_ZONE,
      name: APP_ZONES.BENEFICIARY_ZONE,
      position: 6,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/beneficiary/all-beneficiaries',
      userTypes: [
        WorkflowUserType.ADMIN,
        WorkflowUserType.USER,
        WorkflowUserType.ORG,
      ],
      packs: [PACKS.NEOBANK],
    },
    [APP_ZONES.REVENIR_ZONE]: {
      title: APP_ZONES_TITLE.REVENIR_ZONE,
      name: APP_ZONES.REVENIR_ZONE,
      position: 24,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/revenir/trips',
      userTypes: [WorkflowUserType.ADMIN, WorkflowUserType.USER],
      packs: [PACKS.REVENIR],
    },
    [APP_ZONES.FEE_MANAGEMENT]: {
      title: APP_ZONES_TITLE.FEE_MANAGEMENT,
      name: APP_ZONES.FEE_MANAGEMENT,
      position: 23,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/fee-management/list',
      userTypes: [WorkflowUserType.ADMIN],
      packs: [PACKS.FINXONE],
    },
    [APP_ZONES.CARD_MANAGEMENT_ZONE]: {
      title: APP_ZONES_TITLE.CARD_MANAGEMENT_ZONE,
      name: APP_ZONES.CARD_MANAGEMENT_ZONE,
      position: 25,
      allowedViews: {
        mobile: true,
        tablet: true,
        desktop: true,
      },
      zoneType: ZONETYPEOPTION.DEFAULT,
      is_role_specific: true,
      url: '/zones/card-management/card-application-pending',
      userTypes: [WorkflowUserType.USER, WorkflowUserType.ORG],
      packs: [PACKS.CARD_MANAGEMENT],
    },
  },
  roles: [],
} as PermissionConfig;

const defaultNeutrals = {
  '0': '#9c9ea3',
  '1': '#00302a',
  '2': '#000b0c',
  '3': '#080808',
};

const defaultAlerts = {
  '0': '#FF2323',
  '1': '#2c70c9',
};

const defaultTopography = {
  palette: {
    '0': '#000000',
    '1': '#757575',
    '2': '#474747',
  },
  fontFamily: 'Mulish',
};

const defaultBorderColors = {
  '0': '#0084d6',
  '1': '#bababa',
  '2': '#bababa',
};

function basePalleteWithPrimaryColor(primary: string) {
  return {
    '0': '#000B0C',
    '1': '#5E6566',
    '2': '#A7A7A7',
    '3': primary,
    '4': '#CED4DA',
    '5': '#FFFFFF',
    '6': '#FFFFFF',
  };
}

export const themeAttributes = {
  activeColourPalette: 0,
  systemName: 'MoneyFin',
  legalEntityName: 'MoneyFin Ltd',
  logo: '',
  loginPageConfig: {
    logoEnabled: false,
  },
  colour_palettes: [
    {
      name: 'default',
      alerts: defaultAlerts,
      palette: basePalleteWithPrimaryColor('#00D8BE'),
      neutrals: defaultNeutrals,
      typography: defaultTopography,
      border_colors: defaultBorderColors,
    },
    {
      name: 'default',
      alerts: defaultAlerts,
      palette: {
        '0': '#000000',
        '1': '#707070',
        '2': '#bababa',
        '3': '#0084d6',
        '4': '#bababa',
        '5': '#d9d9d9',
        '6': '#ffffff',
      },
      neutrals: defaultNeutrals,
      typography: defaultTopography,
      border_colors: defaultBorderColors,
    },
    {
      name: 'default',
      alerts: defaultAlerts,
      palette: {
        '0': '#000B0C',
        '1': '#5E6566',
        '2': '#CED4DA',
        '3': '#3DD5FB',
        '4': '#D1D1D1',
        '5': '#FDFDFD',
        '6': '#FFFFFF',
      },
      neutrals: defaultNeutrals,
      typography: defaultTopography,
      border_colors: defaultBorderColors,
    },
  ],
} as StyleConfig;

export const defaultWorkflowConfig = {
  projectId: '',
  modules: {
    [APP_ZONES.ONBOARDING]: {},
    [APP_ZONES.LANDING]: {},
    [APP_ZONES.PAYMENT]: {},
    [APP_ZONES.PROFILE]: {},
    [APP_ZONES.CHART]: {},
    [APP_ZONES.GENERAL]: {},
    [APP_ZONES.USER_PREFERENCE_ZONE]: {},
  },
  styleConfig: themeAttributes,
  permissionSections: permissionSections,
  customData: {
    data: {},
    questions: {},
  },
} as WorkflowConfig;

export const blankWorkflowConfig = {
  projectId: '',
  modules: {},
  styleConfig: themeAttributes,
  permissionSections: {
    subscriptions: { ...permissionSections.subscriptions },
    roles: [],
    zones: {},
  },
  customData: {
    data: {},
    questions: {},
  },
} as WorkflowConfig;

export class Constants {
  static get defaultBaseCssConfig() {
    return {
      textFont: 'var(--font-family)',
      marginUnit: 'px',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      paddingUnit: 'px',
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      fontSize: 12,
      color: '#000000',
      textAlignment: 'left',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 0,
      borderRadiusBottomLeft: 0,
      borderRadiusTopRight: 0,
      borderRadiusBottomRight: 0,
      borderWidth: 0,
      borderColor: '#ffffff00', //transparent color
      borderStyle: 'solid',
      fontWeight: '700',
      backGroundColor: '#ffffff00', //transparent color
      textInputFontSize: 16,
      textInputBackgroundColor: '#ffffff00',

      verticalAlignment: '',
      disabledTextColor: '',
      disabledBgColor: '',
      selectedobjectfit: 'default',
      selectedOpacity: 100,
      isTextBold: false,
      isiTextItalic: false,
      isTextUnderline: false,
      alertColor: '#2C70C9',
    } as BaseCssConfig;
  }
  static get defaultBaseCssConfigTwo() {
    return {
      marginUnit: 'px',
      marginTop: 70,
      marginLeft: 1,
      marginRight: 1,
      marginBottom: 1,
      paddingUnit: 'px',
      paddingTop: 1,
      paddingLeft: 1,
      paddingRight: 1,
      paddingBottom: 1,
    } as BaseCssConfig;
  }

  static get defaultUiNestedButtonCssConfig() {
    return {
      textFont: 'var(--font-family)',
      textAlignment: 'center',
      marginUnit: 'px',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      paddingUnit: 'px',
      paddingTop: 10,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 10,
      backGroundColor: 'var(--palette-color-3)',
      color: 'var(--palette-color-6)',
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      fontSize: 16,
    } as BaseCssConfig;
  }

  static get defaultUiNestedTransactionButtonCssConfig() {
    return {
      marginUnit: 'px',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      paddingUnit: 'px',
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      borderRadiusTopLeft: 5,
      borderRadiusBottomLeft: 5,
      borderRadiusTopRight: 5,
      borderRadiusBottomRight: 5,
      borderColor: 'var(--palette-color-4)',
      borderWidth: 1,
      borderRadiusUnit: 'px',
    } as BaseCssConfig;
  }
  static get defaultSimpleBalanceBaseCssConfig() {
    return {
      marginUnit: 'rem',
      marginTop: 2,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      paddingUnit: 'px',
      paddingTop: 21,
      paddingLeft: 20,
      paddingRight: 21,
      paddingBottom: 21,
      fontSize: 12,
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 4,
      borderRadiusBottomLeft: 4,
      borderRadiusTopRight: 4,
      borderRadiusBottomRight: 4,
      borderWidth: 1,
      borderColor: 'var(--border-color-0)',
      borderStyle: 'solid',
      fontWeight: '700',
      backGroundColor: 'var(--palette-color-6)',
      textInputBackgroundColor: '#ffffff00',
      textInputFontSize: 16,

      verticalAlignment: '',
      disabledTextColor: '',
      disabledBgColor: '',
      selectedobjectfit: 'default',
      selectedOpacity: 100,
      isTextBold: false,
      isiTextItalic: false,
      isTextUnderline: false,
    } as BaseCssConfig;
  }
  static get defaultBeneficiaryCarouselBaseCssConfig() {
    return {
      marginUnit: 'rem',
      marginTop: 2,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      paddingUnit: 'px',
      paddingTop: 0,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 0,
      fontSize: 12,
      color: 'var(--typography-color-0)',
      textAlignment: 'left',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 4,
      borderRadiusBottomLeft: 4,
      borderRadiusTopRight: 4,
      borderRadiusBottomRight: 4,
      borderWidth: 1,
      borderColor: 'var(--border-color-0)',
      borderStyle: 'solid',
      fontWeight: '700',
      backGroundColor: 'var(--palette-color-6)',

      verticalAlignment: 'center',
      disabledTextColor: '',
      disabledBgColor: '',
      selectedobjectfit: 'default',
      selectedOpacity: 100,
      isTextBold: false,
      isiTextItalic: false,
      isTextUnderline: false,
    } as BaseCssConfig;
  }

  static get defaultMultiRoleBaseCssConfig() {
    return {
      marginUnit: 'px',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
      paddingUnit: 'px',
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      fontSize: 12,
      color: '#000000',
      textAlignment: 'center',
      borderRadiusUnit: 'px',
      borderRadiusTopLeft: 6,
      borderRadiusBottomLeft: 6,
      borderRadiusTopRight: 6,
      borderRadiusBottomRight: 6,
      borderWidth: 1,
      borderColor: '#CED4DA',
      borderStyle: 'solid',
      fontWeight: '700',
      backGroundColor: '#fff',
      verticalAlignment: 'center',
      disabledTextColor: '',
      disabledBgColor: '',
      selectedobjectfit: 'default',
      selectedOpacity: 100,
      isTextBold: false,
      isiTextItalic: false,
      isTextUnderline: false,
    } as BaseCssConfig;
  }
  static readonly allMonths = [
    { name: 'January', key: 0 },
    { name: 'February', key: 1 },
    { name: 'March', key: 2 },
    { name: 'April', key: 3 },
    { name: 'May', key: 4 },
    { name: 'June', key: 5 },
    { name: 'July', key: 6 },
    { name: 'August', key: 7 },
    { name: 'September', key: 8 },
    { name: 'October', key: 9 },
    { name: 'November', key: 10 },
    { name: 'December', key: 11 },
  ];
  static findMonthNameByKey(key: number): string | undefined {
    const month = Constants.allMonths.find((item) => item.key === key);
    return month ? month.name : undefined;
  }
}

const navBarLayout = [
  {
    title: 'Home',
    order: 1,
    navigateToZone: '/zones/landing/home',
    activeIconColor: '#DFE1E3',
    inactiveIconColor: '#131821',
    itemActiveIn: 'page',
    icon: 'home',
    iconSizeWidth: 24,
    iconSizeHeight: 24,
    iconSizeUnit: 'px',
  },
  {
    title: 'Profile',
    order: 2,
    navigateToZone: '/zones/profile/profile',
    activeIconColor: '#DFE1E3',
    inactiveIconColor: '#131821',
    itemActiveIn: 'page',
    icon: WorkflowUserType.USER,
    iconSizeWidth: 24,
    iconSizeHeight: 24,
    iconSizeUnit: 'px',
  },
  {
    title: 'Menu',
    order: 3,
    navigateToZone: '/zones/menu/menu',
    activeIconColor: '#DFE1E3',
    inactiveIconColor: '#131821',
    itemActiveIn: 'page',
    icon: 'more-menu',
    iconSizeWidth: 24,
    iconSizeHeight: 24,
    iconSizeUnit: 'px',
  },
] as NavBarLayoutItem[];

export const defaultNavBarMobile = {
  iconSize: '24 x 24',
  navBarColor: '#FDFDFD',
  showLabels: true,
  labelFontSize: 16,
  layout: navBarLayout,
  marginUnit: 'px',
  marginTop: 1,
  marginRight: 10,
  marginLeft: 10,
  marginBottom: 1,
  paddingUnit: 'px',
  paddingTop: 1,
  paddingBottom: 1,
  borderRadiusUnit: 'px',
  borderRadiusTopLeft: 1,
  borderRadiusTopRight: 1,
  borderRadiusBottomRight: 1,
  borderRadiusBottomLeft: 1,
  borderWidthUnit: 'px',
  borderWidth: 1,
  borderColor: '#DADADA',
} as NavBarMobile;

export const defaultNavBarDesktop = {
  logo: 'logo/path',
  menuType: 'vertical',
  iconSize: '24 x 24',
  userNameColor: '#6A707E',
  logOutColor: '#D55F5A',
  navBarColor: '#FDFDFD',
  logoType: 'text',
  layout: navBarLayout,
  width: 192,
} as NavBarDesktop;

export const defaultNavBar = {
  navBarMobile: defaultNavBarMobile,
  navBarDesktop: defaultNavBarDesktop,
} as NavBar;

export const defaultOrganisationOwnerRole = {
  id: '',
  name: 'Owner',
  slug: 'owner',
  applied_all_zones: false,
  is_removable: false,
  zones: {
    onboarding: null,
    menu_zone: null,
    charts_zone: null,
    general_zone: null,
    landing_zone: null,
    profile_zone: null,
    payments_zone: null,
    verification_zone: null,
    card_management_zone: null,
    user_management_zone: null,
    preferences_zone: null,
  },
  userType: WorkflowUserType.ORG,
};

export const defaultOrganisationAnonymousRoleCssConfig = {
  marginUnit: 'px',
  marginTop: 1,
  marginLeft: 1,
  marginRight: 1,
  marginBottom: 1,
  paddingUnit: 'px',
  paddingTop: 1,
  paddingLeft: 1,
  paddingRight: 1,
  paddingBottom: 1,
  fontSize: 12,
  color: '#000000',
  textAlignment: 'left',
  borderRadiusUnit: 'px',
  borderRadiusTopLeft: 8,
  borderRadiusBottomLeft: 8,
  borderRadiusTopRight: 8,
  borderRadiusBottomRight: 8,
  borderWidth: 1,
  borderColor: 'var(--color-4)',
  borderStyle: 'solid',
  fontWeight: '700',
  backGroundColor: '#ffffff00',
  verticalAlignment: '',
  disabledTextColor: '',
  disabledBgColor: '',
  selectedobjectfit: 'default',
  selectedOpacity: 100,
  isTextBold: false,
  isiTextItalic: false,
  isTextUnderline: false,
};

export const defaultOrganisationVerificationCanvas = {
  id: '',
  name: 'Owner',
  slug: 'Owner',
  applied_all_zones: true,
  is_removable: true,
  zones: {
    organisation_verification: null,
  },
  userType: WorkflowUserType.ORG,
};

export const defaultOrganisationOnboardingZones = {
  'organisation-onboarding': {
    url: '/zones/organisation-onboarding',
    title: 'Onboarding Organisation',
    position: 12,
    zoneType: 'default',
    allowedViews: {
      mobile: true,
      tablet: false,
      desktop: false,
    },
    is_role_specific: false,
  },
  organisation_verification: {
    url: '/zones/organisation-verification',
    title: 'Verification Organisation',
    position: 13,
    allowedViews: {
      mobile: true,
      tablet: false,
      desktop: false,
    },
    is_role_specific: false,
  },
};

export const defaultRole = {
  id: '',
  name: 'Role',
  userType: '',
  slug: '',
  navBar: defaultNavBar,
  applied_all_zones: false,
  is_removable: true,
  zones: {
    onboarding: null,
    verification_zone: null,
    charts_zone: null,
    landing_zone: null,
    profile_zone: null,
    payments_zone: null,
    onboarding_zone: null,
    general_zone: null,
    card_management_zone: null,
    preferences_zone: null,
  },
} as Role;

export const numberOfColumn = 3;
export const numberOfRows = 2;

export const zoneConfiguration = {
  showNextButton: true,
  zonePageType: 'singlePage',
  zoneType: 'default',
};
export const zoneConfigurationTabular = {
  showNextButton: true,
  zonePageType: 'tabular',
  zoneType: 'entity_zone_questions',
};

export const zoneConfigurationSinglePage = {
  showNextButton: true,
  zonePageType: 'singlePage',
  zoneType: ZONETYPEOPTION.ENTITYZONEQUESTION,
};

export const ADDRESS_FIELD_ATTRIBUTES = [
  createFieldAttribute(
    'search_address',
    'Address (Search via Postal Code / Address)',
    'search_address',
  ),
  createFieldAttribute('line_1', 'Address Line 1', 'text'),
  createFieldAttribute('line_2', 'Address Line 2', 'text'),
  createFieldAttribute('line_3', 'Address Line 3', 'text'),
  createFieldAttribute('line_4', 'Address Line 4', 'text'),
  createFieldAttribute('county', 'County', 'text', { required: true }),
  createFieldAttribute('city', 'City', 'text', { required: true }),
  createFieldAttribute('post_code', 'Post Code', 'text', { required: true }),
  createFieldAttribute('country', 'Country', 'country'),
];
function createFieldAttribute(
  name: string,
  label: string,
  type: string,
  decisionConfig = {},
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any = [],
  additionalConfig = {},
) {
  return {
    name,
    decision_config: decisionConfig,
    label,
    type,
    options,
    additional_config: additionalConfig,
  };
}
