import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { UserProfileUpdated } from '../../../actions/user-profile.action';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { mapFormData } from '../../utils';
import { formatNameAsUrl } from '../../zone-url.utils';

export function submitProfileZoneUserProfileChanges(
  store: Store,
  actions: Actions,
  router: Router,
  formSubmissionService: FormSubmissionService,
) {
  const formStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(
      FormActionTypeEnum.EDIT_PROFILE_DETAILS,
    ),
  );
  const mappedFormData = mapFormData(formStateData?.formData);
  const verifiableFieldsVerified =
    mappedFormData['isEmailVerified'] && mappedFormData['isPhoneVerified'];
  if (verifiableFieldsVerified) {
    formSubmissionService.submitProfileUpdateRequest(mappedFormData);
    actions
      .pipe(
        ofActionDispatched(UserProfileUpdated),
        take(1),
        map(() => {
          router.navigateByUrl(
            `/zones/${formatNameAsUrl(APP_ZONES.PROFILE)}/profile`,
          );
          store.dispatch(new ResetFormDataAction());
        }),
      )
      .subscribe();
  }
}
