/* eslint-disable @typescript-eslint/no-unused-vars */
import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  BaseWidgetProperties,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { Observable, catchError, tap } from 'rxjs';
import { UpdateSelectedBeneficiaryStateInfo } from '../../../actions/beneficiary.action';
import { SetFormActionWithId } from '../../../actions/form-submission.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import {
  GetBeneficiaryResponse,
  UpdateBeneficiaryRequest,
} from '../../../services/account-service/account.type';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function submitEditBeneficiaryRequest(
  _widgetProperties: BaseWidgetProperties,
  _store: Store,
  _actions: Actions,
  _router: Router,
  _alertHandlerService: AlertHandlerService,
  __: MetadataService,
  ___: GraphqlServiceService,
  ____: UiZoneWidgetAttributeConfig,
  _accountService: AccountService,
) {
  const formStateData = _store.selectSnapshot(
    FormActionState.getFormActionStateWithId(
      FormActionTypeEnum.INTL_BENEFICIARY_ADD,
    ),
  )?.formData;
  const allFormsValid = !!(
    formStateData?.['isCountryCurrencyFormValid'] &&
    formStateData?.['isCredentialsFormValid'] &&
    formStateData?.['isAddressFormValid']
  );
  if (allFormsValid) {
    const {
      isCountryCurrencyFormValid,
      isCredentialsFormValid,
      isAddressFormValid,
      ...restFormStateData
    } = formStateData;
    const payload: UpdateBeneficiaryRequest = {
      ...restFormStateData,
      country: formStateData.country.key,
    } as UpdateBeneficiaryRequest;
    _accountService
      .updateBeneficiaryInfo(removeEmptyKeysFromPayload(payload))
      .pipe(
        tap((response) => {
          if (response?.id) {
            delete formStateData['isCountryCurrencyFormValid'];
            delete formStateData['isCredentialsFormValid'];
            delete formStateData['isAddressFormValid'];

            _store.dispatch(new UpdateSelectedBeneficiaryStateInfo(response));
            _store.dispatch(
              new SetFormActionWithId(
                { type: '' },
                FormActionTypeEnum.INTL_BENEFICIARY_ADD,
              ),
            );
            redirectToPage(_router, _widgetProperties);
            _alertHandlerService.showAlertFn(
              'success',
              'The beneficiary has been successfully edited.',
              'Beneficiary Update Complete!',
            );
          }
        }),
        catchError<GetBeneficiaryResponse, any>(
          (_err: any, _caught: Observable<GetBeneficiaryResponse>) => {
            _alertHandlerService.showAlertFn(
              'error',
              "There was an error editing the beneficiary's details. Try again.",
              "Failed to update beneficiary's details!",
            );
          },
        ),
      )
      .subscribe();
  } else {
    _alertHandlerService.showAlertFn(
      'error',
      'Please complete the form before proceeding.',
    );
  }
}

function removeEmptyKeysFromPayload(
  formStateData: UpdateBeneficiaryRequest,
): UpdateBeneficiaryRequest {
  const filteredPayload = {} as UpdateBeneficiaryRequest;
  for (const [key, value] of Object.entries(formStateData)) {
    if (formStateData[key as keyof UpdateBeneficiaryRequest] !== '') {
      filteredPayload[key as keyof UpdateBeneficiaryRequest] = value;
    }
  }
  return filteredPayload;
}
