import {
  GetBeneficiaryResponse,
  UpdateBeneficiaryRequest,
} from '../services/account-service/account.type';

export class GetBeneficiary {
  static readonly type = '[Beneficiary] GetBeneficiary';
  constructor(
    public page: number = 1,
    public size: number = 100,
    public searchValue?: string,
    public isLoaderShown = true,
    public currency?: string,
  ) {}
}
export class SetSelectedBeneficiary {
  static readonly type = '[Beneficiary] SetSelectedBeneficiary';
  constructor(public payload: string) {}
}

export class SetBeneficiaryLoadingStatus {
  static readonly type = '[Beneficiary] SetLoadingStatus';
  constructor(public isLoading: boolean) {}
}

export class UpdateSelectedBeneficiaryStateInfo {
  static readonly type = '[Beneficiary] UpdateSelectedBeneficiaryStateInfo';
  constructor(public beneficiaryInfo: GetBeneficiaryResponse) {}
}

export class UpdateBeneficiary {
  static readonly type = '[Beneficiary] UpdateBeneficiary';
  constructor(public payload: UpdateBeneficiaryRequest, public id: string) {}
}
export class ClearBeneficiaryCache {
  static readonly type = '[Beneficiary] ClearBeneficiaryCache';
}

export class GetBeneficiaryRequiredFieldsAction {
  static readonly type = '[Beneficiary] GetBeneficiaryRequiredFieldsAction';
  constructor(public countryCode: string, public currencyCode: string) {}
}
