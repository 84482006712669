import Handlebars from 'handlebars';

export function registerComparisonHelpers() {
  Handlebars.registerHelper('eq', eq);
  Handlebars.registerHelper('and', and);
  Handlebars.registerHelper('gt', gt);
  Handlebars.registerHelper('gte', gte);
  Handlebars.registerHelper('lt', lt);
  Handlebars.registerHelper('lte', lte);
  Handlebars.registerHelper('not', not);
  Handlebars.registerHelper('seq', seq);
  Handlebars.registerHelper('xor', xor);
  Handlebars.registerHelper('neq', neq);
  Handlebars.registerHelper('stringPropertySet', stringPropertySet);
  Handlebars.registerHelper('isMultipleOfTwo', function (index) {
    return index % 2 === 0;
  });
}

export function eq(...params: any[]) {
  if (params[2] === 'obj') {
    return JSON.stringify(params[0]) === JSON.stringify(params[1]);
  }
  return params[0] == params[1];
}

export function and(...params: any[]) {
  return params[0] && params[1];
}

export function gt(...params: any[]) {
  if (params[2] == null) {
    return parseFloat(params[0]) > parseFloat(params[1]);
  } else if (params[2] == 'string') {
    return params[0] > params[1];
  }
  return;
}

export function gte(...params: any[]) {
  if (params[2] == null) {
    return parseFloat(params[0]) >= parseFloat(params[1]);
  } else if (params[2] == 'string') {
    return params[0] >= params[1];
  }
  return;
}

export function lt(...params: any[]) {
  if (params[2] == null) {
    return parseFloat(params[0]) < parseFloat(params[1]);
  } else if (params[2] == 'string') {
    return params[0] < params[1];
  }
  return;
}

export function lte(...params: any[]) {
  if (params[2] == null) {
    return parseFloat(params[0]) <= parseFloat(params[1]);
  } else if (params[2] == 'string') {
    return params[0] <= params[1];
  }
  return;
}

export function not(...params: any[]) {
  return !params[0];
}

export function seq(...params: any[]) {
  return params[0] === params[1];
}

export function xor(...params: any[]) {
  return (params[0] && !params[1]) || (!params[0] && params[1]);
}
export function neq(...params: any[]) {
  return params[0] !== params[1];
}

export function stringPropertySet(inputString: string): boolean {
  if (inputString) {
    return true;
  } else {
    return false;
  }
}
