<div class="alert">
  <finxone-platform-alert-success-message
    *ngIf="alertType === 'success'"
    [headerMsg]="headerMsg"
    [msg]="alertMsg"
  ></finxone-platform-alert-success-message>

  <finxone-platform-alert-error-message
    [msg]="alertMsg"
    [headerMsg]="headerMsg"
    *ngIf="alertType === 'error'"
  ></finxone-platform-alert-error-message>
</div>
