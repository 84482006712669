import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ExchangeTransactionResponse,
  NotificationTypeEnum,
  TransactionType,
  transactionStatusMapping,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import {
  ClearNotificationsCache,
  GetNotification,
  SetNotificationLoadingStatus,
  SetPaymentRequestSummary,
  UpdateNotifications,
} from '../actions/notification.action';
import {
  AddProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';
import {
  GetBeneficiaryResponse,
  NotificationModel,
  TransactionDetailResponse,
} from '../services/account-service/account.type';
import { ExchangeService } from '../services/exchange-service/exchange-service.service';

export interface PaymentRequestSummary {
  id: string;
  amount: string;
  currency: string;
  paymentReference: string;
  paymentReason: string;
  requester: {
    userId: string;
    name: string;
    accountId: string;
    accountName: string;
    destinationIdentifier: {
      accountNumber: string;
      'sort-code': string;
    };
  };
  approver: {
    userId: string;
    name: string;
  };
  beneficiary: {
    name: string;
    destinationIdentifier: {
      accountNumber: string;
      'sort-code': string;
    };
  };
  approverStatus: string;
  approvedOrRejectedDate: string;
  status: string;
  createdDate: string;
}
export interface NotificationStateModel {
  isLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedNotification: any;
  notificationList: NotificationModel[];
  paymentRequestSummary?: PaymentRequestSummary;
}
@State<NotificationStateModel>({
  name: 'notifications',
  defaults: {
    isLoading: false,
    selectedNotification: {},
    notificationList: [],
  },
})
@Injectable()
export class NotificationState {
  constructor(
    private accountService: AccountService,
    private exchangeService: ExchangeService,
    private router: Router,
  ) {}

  @Selector()
  static getNotification(state: NotificationStateModel) {
    return state;
  }

  @Selector()
  static getLoadingStatus(state: NotificationStateModel) {
    return state.isLoading;
  }

  @Action(SetNotificationLoadingStatus)
  setLoadingStatus(
    ctx: StateContext<NotificationStateModel>,
    action: SetNotificationLoadingStatus,
  ) {
    try {
      ctx.patchState({
        ...ctx.getState(),
        isLoading: action.isLoading,
      });
      return true;
    } catch (err) {
      return throwError(() => err);
    }
  }

  @Action(GetNotification)
  fetchNotification(ctx: StateContext<NotificationStateModel>) {
    try {
      const currentUrl = this.router.url;
      let notificationId: string = '';

      // Check if the URL is defined and has the expected format
      if (!this.isValidUrl(currentUrl)) {
        console.error('Invalid or undefined URL:', currentUrl);
        return of(null);
      }

      const paramData = currentUrl.split('/');
      notificationId = paramData[4] ?? '';

      const notificationState = ctx.getState();
      if (!notificationState) {
        return of(null);
      }

      const notificationItem = notificationState.notificationList.find(
        (stage) => stage.novuId === notificationId,
      );

      ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetNotification' }));

      if (!notificationItem) {
        console.error('Notification item not found for id:', notificationId);
        return of(null);
      }

      const notificationType = notificationItem.payload?.[
        'notificationType'
      ] as NotificationTypeEnum;

      switch (notificationType) {
        case NotificationTypeEnum.TRANSACTION_INCOMING:
        case NotificationTypeEnum.TRANSACTION_OUTGOING:
          return this.handleTransactionNotification(ctx, notificationItem);
        case NotificationTypeEnum.EXCHANGE_COMPLETED:
        case NotificationTypeEnum.EXCHANGE_FAILED:
          return this.handleExchangeNotification(ctx, notificationItem);
        case NotificationTypeEnum.BENEFICIARY_ADDED:
          return this.handleBeneficiaryAddedNotification(ctx, notificationItem);
        default:
          return this.handleDefaultNotification(ctx, notificationItem);
      }
    } catch (err) {
      ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetNotification' }));
      return throwError(() => err);
    } finally {
      ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetNotification' }));
    }
  }

  public isValidUrl(url: string): boolean {
    return typeof url === 'string' && url.includes('/');
  }

  public handleTransactionNotification(
    ctx: StateContext<NotificationStateModel>,
    notificationItem: NotificationModel,
  ): Observable<TransactionDetailResponse> {
    const transactionId =
      notificationItem.payload?.['paymentId'] ??
      notificationItem.payload?.['transactionId'] ??
      '';
    const transactionType =
      transactionStatusMapping[
        notificationItem.payload?.['notificationType'] ??
          NotificationTypeEnum.TRANSACTION_INCOMING
      ] ?? TransactionType.INCOMING;

    return this.accountService
      .getTransactionDetail(transactionId, transactionType as TransactionType)
      .pipe(
        tap((notificationData: TransactionDetailResponse) => {
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'GetNotification' }),
          );

          ctx.patchState({
            ...ctx.getState(),
            isLoading: false,
            selectedNotification: {
              ...notificationData,
              notificationType: notificationItem.payload['notificationType'],
            },
          });
        }),
        catchError((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'GetNotification' }),
          );
          throw _err;
        }),
      );
  }

  public handleExchangeNotification(
    ctx: StateContext<NotificationStateModel>,
    notificationItem: NotificationModel,
  ): Observable<ExchangeTransactionResponse> {
    const transactionId = notificationItem.payload?.['id'] ?? '';
    return this.exchangeService.getExchangeDetail(transactionId).pipe(
      tap((notificationData: ExchangeTransactionResponse) => {
        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'GetNotification' }),
        );

        ctx.patchState({
          ...ctx.getState(),
          isLoading: false,
          selectedNotification: {
            ...notificationData,
            notificationType: notificationItem.payload['notificationType'],
          },
        });
      }),
      catchError((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'GetNotification' }),
        );
        throw _err;
      }),
    );
  }

  public handleDefaultNotification(
    ctx: StateContext<NotificationStateModel>,
    notificationItem: NotificationModel,
  ): Observable<boolean> {
    ctx.patchState({
      ...ctx.getState(),
      isLoading: false,
      selectedNotification: {
        ...notificationItem.payload,
        createdAt: notificationItem.createdAt,
        notificationType: notificationItem.payload['notificationType'],
      },
    });
    return of(true);
  }

  public handleBeneficiaryAddedNotification(
    ctx: StateContext<NotificationStateModel>,
    notificationItem: NotificationModel,
  ): Observable<GetBeneficiaryResponse> {
    const beneficiaryId = notificationItem.payload?.['beneficiaryId'] ?? '';
    return this.accountService.getBeneficiaryDetail(beneficiaryId).pipe(
      tap((notificationData: GetBeneficiaryResponse) => {
        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'GetNotification' }),
        );

        ctx.patchState({
          ...ctx.getState(),
          isLoading: false,
          selectedNotification: {
            ...notificationData,
            notificationType: notificationItem.payload['notificationType'],
          },
        });
      }),
      catchError((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'GetNotification' }),
        );
        throw _err;
      }),
    );
  }

  @Action(UpdateNotifications)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateNotifications(ctx: StateContext<any>, action: UpdateNotifications) {
    ctx.patchState({
      notificationList: action.notificationList,
      isLoading: false,
    });
  }

  @Action(SetPaymentRequestSummary)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addPaymentRequestSummaryInState(
    ctx: StateContext<any>,
    action: SetPaymentRequestSummary,
  ) {
    ctx.patchState({
      paymentRequestSummary: action.paymentRequestSummary,
    });
  }

  @Action(ClearNotificationsCache)
  clearAccountCache(ctx: StateContext<NotificationStateModel>) {
    ctx.patchState({
      isLoading: false,
      selectedNotification: {},
      notificationList: [],
    });
  }
}
