import { AccountInfoType } from './account.type';
import { FeeFrequencyTypeEnum, FeeUnitChargeTypeEnum } from './common.enum';
import { WidgetNames } from './config.type';
import {
  Constants,
  cryptoRandomUUID,
} from './default-constants/workflow-constants.type';
import { WidgetTitles } from './workflow.type';
import { WorkflowWidgetConfig } from './workflow.v2.type';

export type AddFeeRequest = {
  name: string;
  currency: string;
  clientId?: string;
  accountId?: string;
  feeGroupId?: string;
  unitChargeType: FeeUnitChargeTypeEnum;
  unitChargeInfo: {
    feeCharge: number;
    minFeeAmount?: number;
    maxFeeAmount?: number;
  };
  frequencyType: FeeFrequencyTypeEnum;
  frequencyInfo: {
    day?: string;
    weekDay?: string;
    month?: string;
    quarter?: string;
    startDate?: Date;
    endDate?: Date;
  };
};

export type FeeInfoType = {
  id: string;
  name: string;
  currency: string;
  feeGroupId?: string;
  unitChargeType: FeeUnitChargeTypeEnum;
  unitChargeInfo: {
    feeCharge: number;
    minFeeAmount?: number;
    maxFeeAmount?: number;
  };
  frequencyType: FeeFrequencyTypeEnum;
  frequencyInfo: {
    day?: string;
    weekDay?: string;
    month?: string;
    quarter?: string;
    startDate?: Date;
    endDate?: Date;
  };
  status: string;
};

export type FeeGroup = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
};

export type AddEditFeeRequestPayload = {
  id?: string;
  name: string;
  currency: string;
  feeGroupId: string;
  unitChargeType: string;
  unitChargeInfo: {
    feeCharge: string;
    minFeeAmount?: string;
    maxFeeAmount?: string;
  };
  frequencyType: string;
  frequencyInfo: {
    day?: string;
    weekDay?: string;
    month?: string;
    quarter?: string;
    startDate?: string;
    endDate?: string;
  };
  status?: string;
};

export type AddEditFeeGroupRequestPayload = {
  name: string;
  description: string;
};

export type FeeItem = {
  showMenuPopup?: boolean;
  id: string;
  feeName: string;
  currency: string;
  unitChargeType: string;
  feeCharge: string;
  minimumFeeAmount: string;
  maximumFeeAmount: string;
  status: string;
  createdAt: string;
  adHocFee?: {
    accountId: string;
    organisationId: string;
  };
  feeGroupId: {
    id: string;
    name: string;
    description: string;
    createdAt: string;
  };
  feeFrequencySettings: {
    id: string;
    frequencyType: string;
    weekDay: string | null;
    day: string | null;
    quarter: string | null;
    month: string | null;
    startDate: string;
    endDate: string | null;
    createdAt: string;
  };
};

export type FeeListingResponse = {
  result: FeeItem[];
  limit: number;
  page: number;
  totalPages: number;
  totalItems: number;
};
export type FeeGroupItem = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  clientLinked: number;
};

export type FeeGroupsListingResponse = {
  result: FeeGroupItem[];
  limit: number;
  page: number;
  totalPages: number;
  totalItems: number;
};

export type ClientListingResponse = {
  result: ClientDetails[];
  limit: number;
  page: number;
  totalPages: number;
  totalItems: number;
};

export type ClientDetails = {
  id: string;
  name: string;
  attributes: {
    address: {
      address1: string;
      address2: string;
      address3: string;
      address4: string;
      postalCode: string;
      country: string;
      city: string;
      state: string;
    };
    identificationNumber: string;
  };
  clientType: string;
  displayName: string;
  feeGroupName?: string;
  feeGroupId: string;
  accountList?: AccountInfoType[];
};

export enum ListingTypeEnum {
  FEE_LISTING = 'fee-listing',
  CLIENT_LISTING = 'client-listing',
  GROUP_FEE_LISTING = 'group_fee-listing',
  GROUP_CLIENT_LISTING = 'group_client-listing',
  FEE_CHARGE_HISTORY_LISTING = 'fee-charge-history-listing',
  CLIENT_FEE_LISTING = 'client-fee-listing',
}

export const unitChargeOptions = [
  {
    value: FeeUnitChargeTypeEnum.PERCENTAGE_WITH_NO_MIN_MAX,
    title: 'Percentage with no Minimum/Maximum Fee',
  },
  {
    value: FeeUnitChargeTypeEnum.PERCENTAGE_WITH_MIN,
    title: 'Percentage with Minimum Fee',
  },
  {
    value: FeeUnitChargeTypeEnum.PERCENTAGE_WITH_MAX,
    title: 'Percentage with Maximum Fee',
  },
  {
    value: FeeUnitChargeTypeEnum.PERCENTAGE_WITH_MIN_MAX,
    title: 'Percentage with Minimum and Maximum Fee',
  },
  { value: FeeUnitChargeTypeEnum.FIXED_FEE, title: 'Fixed Fee' },
];
export const frequencyOptions = [
  { id: 'default', label: 'Select frequency' },
  { id: FeeFrequencyTypeEnum.ONE_OFF, label: 'One-off' },
  { id: FeeFrequencyTypeEnum.DAILY, label: 'Daily' },
  { id: FeeFrequencyTypeEnum.WEEKLY, label: 'Weekly' },
  { id: FeeFrequencyTypeEnum.MONTHLY, label: 'Monthly' },
  { id: FeeFrequencyTypeEnum.QUARTERLY, label: 'Quarterly' },
  { id: FeeFrequencyTypeEnum.ANNUALLY, label: 'Annual' },
];

const defaultTableHeaderSettingCssConfig = {
  tableHeaderBackgroundColor: 'var(--palette-color-5)',
  tableHeaderTextColor: 'var(--typography-color-0)',
  tableHeaderFontSize: 16,
  tableHeaderFontUnit: 'px',
  tableHeaderFontFamily: 'Mulish',
};

const baseWidgetConfig = {
  css_config: {
    ...Constants.defaultBaseCssConfig,
    textAlignment: 'center',
    verticalAlignment: 'center',
    shadowBlur: 0,
    shadowSpread: 0,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    iconSize: 24,
  },
  widgetProperties: {
    icon: 'search',
    showEntity: false,
    textContent: '',
  },
  pageIndex: 0,
  zone: '',
  iconConfig: {
    leftIcon: '',
    rightIcon: '',
  },
  tableHeaderSetting: {
    css_config: defaultTableHeaderSettingCssConfig,
  },
  rowSettings: {
    rowBgColor: 'var(--palette-color-6)',
    showErrorDuplicationRowBgColor: false,
  },
  tableGridSetting: {
    showGridLines: false,
    gridLinesColor: 'var(--border-color-1)',
  },
  viewPaginationOption: ['05', '10', '20'],
  viewTableSize: 'p-datatable-lg',
  columns: [],
};

export const feeClientListingWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.FEE_CLIENT_LISTING_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.FEE_CLIENT_LISTING_WIDGET,
    widgetConfigDisplayName: WidgetTitles.FEE_CLIENT_LISTING_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.FEE_CLIENT_LISTING_WIDGET,
      label: WidgetTitles.FEE_CLIENT_LISTING_WIDGET,
      ...baseWidgetConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 7,
    minItemCols: 15,
    minItemRows: 30,
    rows: 30,
    cols: 39,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export const groupsListingWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.FEE_GROUPS_LISTING,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.FEE_GROUPS_LISTING,
    widgetConfigDisplayName: WidgetTitles.FEE_GROUPS_LISTING,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.FEE_GROUPS_LISTING,
      label: WidgetTitles.FEE_GROUPS_LISTING,
      ...baseWidgetConfig,
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 7,
    minItemCols: 15,
    minItemRows: 30,
    rows: 30,
    cols: 39,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;

export type OrgAttributes = {
  country?: string[];
  city?: string[];
  state?: string[];
  address1?: string[];
  address2?: string[];
  address3?: string[];
  address4?: string[];
  postalCode?: string[];
  feeGroupId?: string[];
  flatName?: string[];
  propertyName?: string[];
  propertyNumber?: string[];
  incorporationDate?: string[];
  identificationNumber?: string[];
  phoneNumber?: string[];
};

export const assignGroupWidgetConfig = {
  widgetTypeConfig: 'new',
  uniqueId: cryptoRandomUUID(),
  name: WidgetNames.ASSIGN_GROUP_WIDGET,
  widgetConfiguration: {
    widgetDisplayName: WidgetTitles.ASSIGN_GROUP_WIDGET,
    widgetConfigDisplayName: WidgetTitles.ASSIGN_GROUP_WIDGET,
    attributes: {
      elementsConfig: [],
      name: WidgetNames.ASSIGN_GROUP_WIDGET,
      label: WidgetTitles.ASSIGN_GROUP_WIDGET,
      css_config: {
        ...Constants.defaultBaseCssConfig,
        color: 'var(--palette-color-1)',
        fontSize: '16',
        borderColor: 'var(--palette-color-2)',
        backGroundColor: 'var(--palette-color-6)',
        borderRadiusUnit: 'px',
        paddingTop: 5,
      },
      widgetProperties: {
        textContent: '',
        showInputLable: 'Category',
        inputFieldSettings: {
          css_config: {
            backgroundColor: 'var(--border-color-6)',
            inactiveBorderColor: 'var(--border-color-2)',
            inactiveBackgroundColor: 'var(--border-color-6)',
            borderRadiusTopLeft: 5,
            borderRadiusTopRight: 5,
            borderRadiusBottomLeft: 5,
            borderRadiusBottomRight: 5,
            borderWidth: 1,
            borderColor: 'var(--border-color-1)',
            inputTextColor: 'var(--typography-color-0)',
            borderStyleUnit: 'px',
            inputHeight: 44,
            inputWidth: 100,
            iconSize: 24,
            iconSizeUnit: 'px',
          },
        },
      },
    },
    isRemovable: true,
  },
  gridPosition: {
    x: 0,
    y: 7,
    rows: 3,
    cols: 8,
    resizeEnabled: true,
    dragEnabled: true,
  },
  widgetIconPath: '',
} as WorkflowWidgetConfig;
export type UpdateOrganisationAttributes = {
  name: string;
  attributes: OrgAttributes;
  displayName: string;
};

export type ClientDetailsType = {
  id: string;
  name: string;
  attributes: {
    address: {
      address1: string;
      address2: string;
      address3: string;
      address4: string;
      postalCode: string;
      country: string;
      city: string;
      state: string;
    };
    identificationNumber: string;
    phoneNumber: string;
  };
  clientType: string;
  displayName: string;
  feeGroupId: string;
  feeGroupName: string;
  accountList: AccountInfoType[];
};

export type AdHocFeeRequest = {
  name: string;
  currency: string;
  clientId: string;
  accountId: string;
  unitChargeType: string;
  unitChargeInfo: {
    feeCharge: number;
    minFeeAmount: number;
    maxFeeAmount: number;
  };
  frequencyType: string;
  frequencyInfo: {
    startDate: string;
  };
  status?: string;
};
