import { Component, Input } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AlertHandlerService } from '@finxone-platform/shared/services';

@Component({
  selector: 'finxone-platform-alert-success-message',
  templateUrl: './alert-success-message.component.html',
  styleUrls: ['./alert-success-message.component.scss'],
})
export class AlertSuccessMessageComponent {
  @Input() msg: string | undefined = 'Your Sample text here.';
  @Input() successalertMsgdiv: {
    background: string;
    border: string;
    color: string;
    'border-width': string;
  };
  @Input() successalertErroriconColor: { color: string };
  @Input() successalertHeaderColor: { color: string; 'font-family': string };
  @Input() successcloseIconColor: { color: string };
  @Input() headerMsg: undefined | string = 'Success';
  constructor(private alertHandlerService: AlertHandlerService) {}
  close() {
    this.alertHandlerService.resetAlert();
  }
}
