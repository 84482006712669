import { Injectable } from '@angular/core';
import {
  EventGuestStateModel,
  UserInfoType,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap, throwError } from 'rxjs';
import {
  AddProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';

import {
  GetEventGuestDetails,
  ResetEventGuestState,
} from '../actions/event-guest.action';
import { MetadataService } from '../services/metadata-service/metadata-service.service';

@State<EventGuestStateModel>({
  name: 'eventGuest',
  defaults: {} as EventGuestStateModel,
})
@Injectable()
export class EventGuestState {
  constructor(private metadataService: MetadataService) {}

  @Selector()
  static getEventGuestDetails(state: EventGuestStateModel) {
    return state;
  }

  @Action(GetEventGuestDetails)
  fetchEventGuest(
    ctx: StateContext<EventGuestStateModel>,
    action: GetEventGuestDetails,
  ) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetEventGuestDetails' }));
    try {
      return this.metadataService.getUser(action.id).pipe(
        tap((userProfileData: UserInfoType) => {
          ctx.setState({
            id: userProfileData.id,
            firstName: userProfileData.firstName,
            middleName: userProfileData.middleName,
            lastName: userProfileData.lastName,
            fullName: userProfileData.fullName,
            logo: userProfileData.logo as string,
            email: userProfileData.email,
            dob: userProfileData.dob,
            country: userProfileData.country,
            phoneNumber: userProfileData.phoneNumber,
            address1: userProfileData.address1,
            address2: userProfileData.address2,
            city: userProfileData.city,
            county: userProfileData.county,
            postalCode: userProfileData.postalCode,
            phoneCode: userProfileData.phoneCode,
            custom_questions: userProfileData.custom_questions,
            flatName: userProfileData.flatName,
            propertyName: userProfileData.propertyName,
            propertyNumber: userProfileData.propertyNumber,
            userName: userProfileData.userName as string,
            extraAttributes: userProfileData?.extraAttributes,
          });
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'GetEventGuestDetails' }),
          );
        }),
      );
    } catch (err) {
      ctx.dispatch(
        new RemoveProgressBarStack({ uniqueId: 'GetEventGuestDetails' }),
      );
      return throwError(() => err);
    }
  }

  @Action(ResetEventGuestState)
  resetFormData({ setState }: StateContext<EventGuestStateModel>) {
    setState(() => {
      return {};
    });
  }
}
