import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { KeycloakAngularModule } from 'keycloak-angular';
import { SharedModule } from 'primeng/api';
import { NavbarDesktopComponent } from './navbar-desktop/navbar-desktop.component';

@NgModule({
  declarations: [NavbarDesktopComponent],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    KeycloakAngularModule,
    MatIconModule,
  ],
  exports: [NavbarDesktopComponent],
})
export class LayoutsModule {}
