import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceLocalData } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ProfileState } from './state/user-profile.state';

@Injectable()
export class httpInterceptor implements HttpInterceptor {
  constructor(private _route: Router, private store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const updatedToken = localStorage.getItem('token')
      ? `Bearer ${localStorage.getItem('token')}`
      : '';
    const deviceInfo = localStorage.getItem('deviceInfo');
    let deviceData: DeviceLocalData;
    try {
      deviceData = JSON.parse(deviceInfo ?? '{}');
    } catch (error) {
      deviceData = {} as DeviceLocalData;
    }
    const deviceManufacturer = deviceData?.manufacturer ?? '';
    const deviceOS = deviceData?.os ?? '';
    const deviceModel = deviceData?.deviceModel ?? '';
    const deviceId = deviceData?.deviceId ?? '';
    const profileData = this.store.selectSnapshot(ProfileState.getProfile);
    const organisation = profileData?.activeOrganisationId ?? '';
    const role = profileData?.activeRole ?? '';
    let authHeaderValue = undefined;

    if (/finxone.com/.exec(req.url) || /localhost/.exec(req.url)) {
      authHeaderValue = {
        Authorization: updatedToken,
      };
    } else {
      authHeaderValue = {};
    }
    req = req.url.includes('file/file-upload')
      ? req.clone({
          setHeaders: {
            ...authHeaderValue,
            token: updatedToken,
            organisation: organisation,
            role: role,
            'X-Finxone-Org-Id': organisation,
            'X-Finxone-Role': role,
            'X-Finxone-User-Agent': window.navigator.userAgent,
            'X-Finxone-Device-Id': deviceId,
            'X-Finxone-Device-Manufacture': deviceManufacturer,
            'X-Finxone-Device-Os': deviceOS,
            'X-Finxone-Device-Model': deviceModel,
            Accept: '*/*',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET/POST/PUT/DELETE',
          },
        })
      : req.clone({
          setHeaders: {
            ...authHeaderValue,
            token: updatedToken,
            organisation: organisation,
            role: role,
            Accept: 'application/json',
            'X-Finxone-Org-Id': organisation,
            'X-Finxone-Role': role,
            'X-Finxone-User-Agent': window.navigator.userAgent,
            'X-Finxone-Device-Id': deviceId,
            'X-Finxone-Device-Manufacture': deviceManufacturer,
            'X-Finxone-Device-Os': deviceOS,
            'X-Finxone-Device-Model': deviceModel,
            'Content-Type': 'application/json; charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET/POST/PUT/DELETE',
          },
        });
    return next.handle(req);
  }
}
