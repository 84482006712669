export enum KycLevel {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

export enum PaymentStatusScreen {
  PAYMENT_SUCCESS = 'payment-success',
  PAYMENT_DECLINED = 'payment-declined',
  PAYMENT_PROCESSING = 'payment-processing',
  PAYMENT_REQUEST_SENT = 'request-sent-successfully',
  PAYMENT_REQUEST_FAILED = 'request-failed-to-send',
}

export enum InternationalPaymentStatusScreen {
  PAYMENT_SUCCESS = 'intl-payment-success',
  PAYMENT_DECLINED = 'intl-payment-failed',
  PAYMENT_PROCESSING = 'intl-payment-processing',
}

export enum NotificationDeviceOS {
  ANDROID = 'android',
  IOS = 'ios',
}

export enum DocumentMonthsUpdate {
  NONE = 'None',
  LAST_MONTH = 'Last Month',
  PREVIOUS_3_MONTHS = 'Previous 3 Months',
  PREVIOUS_6_MONTHS = 'Previous 6 Months',
}

export enum DocumentType {
  CSV = 'csv',
  PDF = 'pdf',
  CONTEXT = 'context',
}

export enum DownloadDocumentType {
  BANK_STATEMENT = 'Bank Statement',
  PROOF_OF_PAYMENT = 'Proof of Payment',
  PROOF_OF_EXCHANGE = 'Proof of Exchange',
}

export enum TemplateTypeEnum {
  PDF = 'pdf',
  EMAIL = 'email',
  SMS = 'sms',
}

export enum StatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ExchangeFixedType {
  BUY = 'buy',
  SELL = 'sell',
}

export enum DownloadDocumentTypeKey {
  BANK_STATEMENT_PDF = 'bank-statement-pdf',
  BANK_STATEMENT_CSV = 'bank-statement-csv',
  PROOF_OF_PAYMENT_PDF = 'payment-proof-pdf',
  PROOF_OF_PAYMENT_CSV = 'payment-proof-csv',
  PROOF_OF_EXCHANGE_PDF = 'exchange-proof-pdf',
  PROOF_OF_EXCHANGE_CSV = 'exchange-proof-csv',
}

export enum NotificationChannelEnum {
  EMAIL = 'email',
  SMS = 'sms',
  PUSH = 'push',
}

export enum PaymentTypeSelector {
  PAYMENT_REQUESTOR = 'payment-requestor',
  PAYMENT_INITIATOR = 'payment-initiator',
  PAYMENT_APPROVER = 'payment-approver',
}

export enum FeeFrequencyTypeEnum {
  ONE_OFF = 'one-off',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export enum FeeUnitChargeTypeEnum {
  PERCENTAGE_WITH_NO_MIN_MAX = 'percentage-with-no-min-max',
  PERCENTAGE_WITH_MIN_MAX = 'percentage-with-min-max',
  PERCENTAGE_WITH_MIN = 'percentage-with-min',
  PERCENTAGE_WITH_MAX = 'percentage-with-max',
  FIXED_FEE = 'fixed-fee',
}

export enum SumsubReviewStatus {
  INIT = 'init',
  PEDNING = 'pending',
  COMPLETED = 'completed',
  QUEUED = 'queued',
  PRECHECKED = 'prechecked',
  ONHOLD = 'onHold',
}

export enum FeeChargeStatusEnum {
  CHARGED = 'charged',
  REVERTED = 'reverted',
  PENDING = 'pending',
  FAILED = 'failed',
}
