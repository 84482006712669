import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import {
  AddProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';
import { GetTripTransactions } from '../actions/trip-transactions.action';
import { TripTransactionStateType } from '../services/account-service/account.type';
import { RevenirService } from '../services/revenir-service/revenir.service';

@State<TripTransactionStateType>({
  name: 'tripTransactions',
  defaults: {
    result: [],
    totalPages: 1,
    page: 1,
    limit: 10,
  },
})
@Injectable()
export class TripTransactionState {
  constructor(private revenirService: RevenirService) {}

  @Selector()
  static getTripTransactions(state: TripTransactionStateType) {
    return state;
  }
  @Action(GetTripTransactions)
  fetchTransactions(
    ctx: StateContext<TripTransactionStateType>,
    action: GetTripTransactions,
  ) {
    try {
      ctx.dispatch(
        new AddProgressBarStack({ uniqueId: 'GetTripTransactions' }),
      );
      return this.revenirService.getTripTransactions().pipe(
        tap((transactionList: any) => {
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'GetTripTransactions' }),
          );
          ctx.patchState(transactionList);
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'GetTripTransactions' }),
          );
          throw _err;
        }),
      );
    } catch (err) {
      ctx.dispatch(
        new RemoveProgressBarStack({ uniqueId: 'GetTripTransactions' }),
      );
      return throwError(() => err);
    }
  }
}
