import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({
  providedIn: 'root',
})
export class RevenirService {
  private baseUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public getTripTransactions(): Observable<any[]> {
    // return this.http.get<tripTransaction[]>(`${this.baseUrl}/fee-groups/all`);

    const staticData = {
      result: [
        {
          title: 'PRADA',
          status: '',
          price: '598.00',
          vatReclaim: 'VAT Reclaim 119.60',
          iconStatus: '',
          isError: false,
        },
        {
          title: 'PRADA 2',
          status: 'Receipt mismatch',
          price: '718.00',
          vatReclaim: 'VAT Reclaim 143.60',
          iconStatus: 'error',
          isError: true,
        },
        {
          title: 'Mc Donald',
          status: '',
          price: '718.00',
          vatReclaim: 'VAT not reclaimable',
          iconStatus: '',
          isError: false,
        },
        {
          title: 'Mc Donald 2',
          status: '',
          price: '498.00',
          vatReclaim: 'VAT Reclaim 149.60',
          iconStatus: '',
          isError: true,
        },
        {
          title: 'PRADA 3',
          status: 'Receipt mismatch',
          price: '718.00',
          vatReclaim: 'VAT not reclaimable',
          iconStatus: 'warning',
          isError: true,
        },
      ],
      totalPages: 1,
      limit: 10,
      page: 1,
    };

    return of(staticData) as any;
  }
}
