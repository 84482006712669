import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
    UpdatePaymentRequest,
    UpdatePaymentRequestFailed,
    UpdatePaymentRequestSuccess,
} from '../../../actions/payment-request.action';
import { PaymentDeclined } from '../../../actions/payment.action';
import { PaymentRequestUpdateRequest } from '../../../services/account-service/account.type';
import { NotificationState } from '../../../state/notifications.state';

export function approvePaymentRequest(
  store: Store,
  actions: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const paymentRequestSummary = store.selectSnapshot(
    NotificationState.getNotification,
  ).paymentRequestSummary;
  if (paymentRequestSummary) {
    actions
      .pipe(
        ofActionDispatched(UpdatePaymentRequestSuccess),
        take(1),
        map(() => {
          router.navigateByUrl(`/zones/payments/payment-request-success`, {
            replaceUrl: true,
          });
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(PaymentDeclined),
        take(1),
        map(() => {
          router.navigateByUrl(`/zones/payments/payment-request-failed`, {
            replaceUrl: true,
          });
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(UpdatePaymentRequestFailed),
        take(1),
        map(() => {
          router.navigateByUrl(`/zones/profile/notification-centre`, {
            replaceUrl: true,
          });
        }),
      )
      .subscribe();

    router.navigateByUrl(`/zones/payments/payment-request-processing`, {
      replaceUrl: true,
    });

    const updatePayload: PaymentRequestUpdateRequest = {
      id: paymentRequestSummary.id,
      approvedStatus: 'approved',
    };

    store.dispatch(new UpdatePaymentRequest(updatePayload));
  } else {
    alertHandlerService.showAlertFn(
      'error',
      'Payment request data not available, please try again',
    );
    console.error('Payment request data not available, please try again');
  }
}
