import { Router } from '@angular/router';
import { FormSubmissionService } from '@app/finxone-web-frontend/app/lib/services/form-submission-service/form-submission-service.service';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  SystemRole,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { first, map } from 'rxjs';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { ProfileState } from '../../../state/user-profile.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';

export function checkPaymentRequest(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  action: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  formSubmissionService: FormSubmissionService,
  configService: ConfigService,
) {
  checkBalanceAndRedirect(store, alertHandlerService);

  let activeUser: SystemRole;
  let activeUserType = '';
  const profile = store.selectSnapshot(ProfileState.getProfile);
  if (formSubmissionService.currentPageIsValid()) {
    configService
      .getRoles()
      .pipe(
        first(),
        map((roles) => {
          Object.entries(roles).forEach(([roleKey, value]) => {
            if (roleKey === profile.activeRole) {
              activeUser = value;
            }
          });
          if (activeUser?.userType) {
            activeUserType = activeUser.userType;
          }
        }),
      )
      .subscribe();
    //get system config from form state
    const systemConfig = store.selectSnapshot(
      AccountState.getPaymentGatewayConfig,
    );
    const activeOrg = profile.orgList?.find(
      (org) => org.id === profile.activeOrganisationId,
    );

    //if exists system config then redirect to payment approver/requestor flow
    if (systemConfig?.paymentRequest?.enabled && activeUserType === 'org') {
      const isPaymentInitiator = !!activeOrg?.roles.find((value) =>
        systemConfig?.paymentRequest?.paymentInitiatorRoles.includes(value),
      );
      const isPaymentRequestor = !!activeOrg?.roles.find((value) =>
        systemConfig?.paymentRequest?.paymentRequestorRoles.includes(value),
      );
      const isPaymentApprover = !!activeOrg?.roles.find((value) =>
        systemConfig?.paymentRequest?.paymentApproverRoles.includes(value),
      );
      //Only initiator role have permission to make payment directly
      if (isPaymentInitiator) {
        router.navigateByUrl(
          `zones/${formatNameAsUrl(
            APP_ZONES.PAYMENT,
          )}/payment-summary-initiator`,
        );
      } else if (isPaymentRequestor || isPaymentApprover) {
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(
            APP_ZONES.PAYMENT,
          )}/payment-summary-requestor`,
        );
      } else {
        alertHandlerService.showAlertFn(
          'error',
          "You don't have permission to make a payment or to request for a payment",
        );
      }
    } else {
      redirectToPage(router, widgetProperties);
    }
  } else {
    alertHandlerService.showAlertFn(
      'error',
      ' Please ensure that all the details are provided and correct.',
    );
  }
}

function checkBalanceAndRedirect(
  store: Store,
  alertHandlerService: AlertHandlerService,
) {
  const formStateData = store.selectSnapshot(FormActionState.getFormActionState)
    .response?.formData;
  const activeAccountId = store.selectSnapshot(
    AccountState.getCurrentAccountId,
  );

  const accountsData = store.selectSnapshot(AccountState.getAccounts);

  if (formStateData?.['amount']) {
    if (!formStateData || !accountsData) {
      throw new Error('formData or accountsData is undefined or null');
    }

    const account = accountsData.accounts.find(
      (account) => account.accountId === activeAccountId,
    );
    if (!account) {
      throw new Error('Account with activeAccountId not found');
    }

    const amountPlusFees = Number(
      formStateData?.['totalAmount'] || formStateData?.['amount'],
    );
    const accountBalance = Number(account.balance);
    if (isNaN(accountBalance) || isNaN(amountPlusFees)) {
      throw new Error('Account balance or payment amount NaN');
    }

    const isBalanceAvailable = accountBalance >= amountPlusFees;

    if (!isBalanceAvailable) {
      alertHandlerService.showAlertFn(
        'error',
        'Enter a lower amount to continue.',
        'Insufficient balance.',
      );
      throw new Error('Enter a lower amount to continue.');
    }
  }
}
