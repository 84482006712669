import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  OnboardingConfigFlowTypes,
  UiZoneWidgetAttributeConfig,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UpdateFormDataAction } from '../../../actions/form-submission.action';
import { AccountService } from '../../../services/account-service/account-service.service';
import { ConfigService } from '../../../services/config-service/config-service.service';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { KeycloakWrapperService } from '../../../services/keycloak-wrapper-service/keycloak-wrapper.service';
import { MetadataService } from '../../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { RoleState } from '../../../state/role.state';

export function handleBusinessUserRedirectionOnOnboardingFlows(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  keycloakService: KeycloakWrapperService,
  _: AlertHandlerService,
  __: MetadataService,
  ___: GraphqlServiceService,
  ____: UiZoneWidgetAttributeConfig,
  _____: AccountService,
  ______: FormSubmissionService,
  configService: ConfigService,
) {
  const onboardingFlow = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  )?.onboardingFlow;
  const role = store.selectSnapshot(RoleState.getRole)?.role ?? 'owner';
  if (onboardingFlow === OnboardingConfigFlowTypes.PERSONAL) {
    if (widgetProperties['urlToNavigate'] == 'login') {
      store.dispatch(
        new UpdateFormDataAction({
          formData: { redirectToOrgOnboardingFlow: true },
        }),
      );
      const redirectUri = window.location.origin + '/post-auth';
      keycloakService.login({ redirectUri: redirectUri });
    }
    if (widgetProperties['urlToNavigate'] == 'sign-up') {
      const pageUrl = configService.getPageIndexPageUrl(
        APP_ZONES.ONBOARDING,
        1,
      );
      router.navigateByUrl(`/zones/onboarding/${pageUrl}/${role}/default`);
    }
  }
  if (onboardingFlow === OnboardingConfigFlowTypes.BUSINESS) {
    const onboardingOrgId = store.selectSnapshot(
      FormActionState.getFormActionState,
    )?.response?.formData?.onboardingOrgId;
    if (widgetProperties['urlToNavigate'] == 'login') {
      keycloakService.login({
        redirectUri:
          window.location.origin +
          `/associate-user-organisation/${onboardingOrgId}`,
      });
    }
    if (widgetProperties['urlToNavigate'] == 'sign-up') {
      const pageUrl = configService.getPageIndexPageUrl(
        APP_ZONES.ONBOARDING,
        1,
      );
      router.navigateByUrl(
        `/zones/onboarding/${pageUrl}/${role}/default/${onboardingOrgId}`,
      );
    }
  }
}
