export function isAfter(localTimeString: string): boolean {
  const timeHourMinute = localTimeString.split(':').map((s) => parseInt(s));
  const hour = timeHourMinute[0];
  const minute = timeHourMinute[1];
  const now = new Date();
  const setTime = new Date();
  setTime.setHours(hour);
  setTime.setMinutes(minute);

  if (now > setTime) {
    return true;
  } else {
    return false;
  }
}

export function isAfterAndBefore(
  localTimeStringAfter: string,
  localTimeStringBefore: string,
): boolean {
  const timeHourMinuteAfter = localTimeStringAfter
    .split(':')
    .map((s) => parseInt(s));
  const hourAfter = timeHourMinuteAfter[0];
  const minuteAfter = timeHourMinuteAfter[1];
  const secondAfter = timeHourMinuteAfter[2];
  const now = new Date();
  const setTimeAfter = new Date();
  setTimeAfter.setHours(hourAfter);
  setTimeAfter.setMinutes(minuteAfter);
  setTimeAfter.setSeconds(secondAfter);

  const timeHourMinuteBefore = localTimeStringBefore
    .split(':')
    .map((s) => parseInt(s));
  const hourBefore = timeHourMinuteBefore[0];
  const minuteBefore = timeHourMinuteBefore[1];
  const secondBefore = timeHourMinuteBefore[2];
  const setTimeBefore = new Date();
  setTimeBefore.setHours(hourBefore);
  setTimeBefore.setMinutes(minuteBefore);
  setTimeBefore.setSeconds(secondBefore);

  if (now > setTimeAfter && now < setTimeBefore) {
    return true;
  } else {
    return false;
  }
}
