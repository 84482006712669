import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  PaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
  PaymentDeclined,
  PaymentSuccess,
} from '../../../actions/payment.action';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';
import { submitInitiatorPayment } from './submit-initiator-payment';

export function paymentApproverInitiateDomesticPayment(
  widgetProperties: BaseWidgetProperties,
  _store: Store,
  _actions: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const isSmsAuthEnabled = _store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  _actions
    .pipe(
      ofActionDispatched(PaymentSuccess),
      take(1),
      map(() => {
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/${
            PaymentStatusScreen.PAYMENT_SUCCESS
          }-initiator`,
          {
            replaceUrl: true,
          },
        );
      }),
    )
    .subscribe();

  _actions
    .pipe(
      ofActionDispatched(PaymentDeclined),
      take(1),
      map(() => {
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(
            APP_ZONES.PAYMENT,
          )}/payment-failed-initiator`,
          {
            replaceUrl: true,
          },
        );
      }),
    )
    .subscribe();
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties);
  } else {
    submitInitiatorPayment(_store, router, alertHandlerService);
  }
}
