import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: 'iframe',
})
export class CachedSrcDirective {
  @Input()
  public get cachedSrc(): string {
    return this.elRef.nativeElement.src;
  }
  public set cachedSrc(src: string) {
    if (src) {
      if (this.elRef.nativeElement.src !== src) {
        this.renderer.setAttribute(this.elRef.nativeElement, 'src', src);
      }
    } else {
      this.renderer.setAttribute(
        this.elRef.nativeElement,
        'srcdoc',
        'I-Frame Widget',
      );
    }
  }

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
}
