import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
  AddEmployeeToTripAction,
  AddEmployeeToTripSuccessAction,
} from '../../../actions/crud-entity.action';
import { ResetFormDataAction } from '../../../actions/form-submission.action';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function addEmployeeToTripAction(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  actions: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const addEmployeeToTripStateValue = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(
      FormActionTypeEnum.EMPLOYEE_DETAILS,
    ),
  );

  if (addEmployeeToTripStateValue?.formData['tripId']) {
    store.dispatch(
      new AddEmployeeToTripAction(
        addEmployeeToTripStateValue?.formData['tripId'],
      ),
    );

    actions
      .pipe(
        ofActionDispatched(AddEmployeeToTripSuccessAction),
        take(1),
        map(() => {
          redirectToPage(router, widgetProperties);
          store.dispatch(new ResetFormDataAction());
          alertHandlerService.showAlertFn(
            'success',
            'Employee has been added to the trip.',
          );
        }),
      )
      .subscribe();
  } else {
    alertHandlerService.showAlertFn(
      'error',
      'Please select trip to add an employee into.',
    );
  }
}
