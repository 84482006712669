export enum PaymentRequestStatus {
    SUCCESS = 'success',
    FAILED = 'failed',
    PENDING = 'pending',
    CANCELLED = 'cancelled',
  }
  
  export enum PaymentApproverStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
  }

  export enum BulkPaymentMasterStatus {
    PENDING = 'pending',
    COMPLETED = 'completed',
    PARTIALLY_COMPELTED = 'partially completed',
    FAILED = 'failed',
    IN_PROGRESS = 'in progress',
    AUTHENTICATED = 'authenticated',
    CANCELLED= 'cancelled',
    REVIEW_REQUEST = 'review_request',
    PENDING_APPROVAL = 'pending approval',
    APPROVED = 'approved',
    REJECTED = 'rejected',
  }

  export enum BulkPaymentBeneficiaryStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed'
  }

  export enum BulkPaymentBeneficiaryExistStatus {
    EXISTING = 'existing',
    NEW = 'new',
    PENDING = 'pending',
  }

  export enum BulkPaymentScheduleType {
    INSTANT = 'instant',
    SCHEDULE = 'schedule',
  }

  export enum BulkPaymentTransactionStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
    DELETED = 'deleted',
    CANCELLED= ' cancelled'
  }

  export enum BulkPaymentStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
    DUPLICATE_RECORDS = 'duplicate records'
  }