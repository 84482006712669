import { Component } from '@angular/core';
import { AlertHandlerService } from '@finxone-platform/shared/services';
// eslint-disable-next-line @nx/enforce-module-boundaries

@Component({
  selector: 'finxone-web-frontend-alert-handler',
  templateUrl: './alert-handler.component.html',
  styleUrls: ['./alert-handler.component.scss'],
})
export class AlertHandlerComponent {
  alertType = '';
  alertMsg: string | undefined;
  headerMsg: string | undefined = 'Error!';
  constructor(private alertHandlerService: AlertHandlerService) {}
  ngOnInit() {
    this.alertHandlerService.showAlert.subscribe((res) => {
      this.alertType = res.type;
      this.alertMsg = res.msg;
      this.headerMsg = res.headerMsg;
    });
  }
}
