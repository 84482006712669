import { Injectable } from '@angular/core';
import {
  AdHocFeeRequest,
  AddEditFeeRequestPayload,
  ClientDetailsType,
  ClientListingResponse,
  FeeGroup,
  FeeGroupItem,
  FeeGroupsListingResponse,
  FeeItem,
  FeeListingResponse,
} from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';
import {
  FetchSelectedClientDetails,
  GetAllClientListing,
  GetAllFeeGroups,
  GetClientFeeListing,
  GetFeeChargeHistoryListing,
  GetFeeGroupByIdClientListing,
  GetFeeGroupByIdFeeListing,
  GetFeeGroupsListing,
  GetFeeListing,
  GetSelectedFeeGroup,
  ResetSelectedClient,
  ResetSelectedFee,
  ResetSelectedFeeGroup,
  SetSelectedClientFee,
  SetSelectedFee,
  ValidationFeeDetails,
} from '../actions/fee-management.action';
import { FeeManagementService } from '../services/fee-management-service/fee-management.service';

export interface FeeManagementStateModel {
  feeListing: FeeListingResponse;
  clientFeeListing: FeeListingResponse;
  clientListing: ClientListingResponse;
  feeGroupListing: FeeGroupsListingResponse;
  feeChargeHistoryListing: FeeListingResponse;
  selectedFeeGroupData: FeeGroupItem;
  selectedFeeGroupByIdFeeListing: FeeListingResponse;
  selectedGroupByIdClientListing: ClientListingResponse;
  allFeeGroups: FeeGroup[];
  selectedFeeData?: FeeItem;
  addEditFeeData?: AddEditFeeRequestPayload | AdHocFeeRequest;
  selectedClientDetails?: ClientDetailsType;
  selectedClientFeeData?: FeeItem;
}
@State<FeeManagementStateModel>({
  name: 'feeManagement',
  defaults: {
    feeListing: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    clientListing: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    feeGroupListing: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    selectedFeeGroupData: {
      id: '',
      name: '',
      description: '',
      createdAt: '',
      clientLinked: 0,
    },
    selectedFeeGroupByIdFeeListing: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    selectedGroupByIdClientListing: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    feeChargeHistoryListing: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    clientFeeListing: {
      result: [],
      limit: 0,
      page: 0,
      totalPages: 0,
      totalItems: 0,
    },
    allFeeGroups: [],
  },
})
@Injectable()
export class FeeManagementState {
  constructor(
    private feeManagementService: FeeManagementService,
    private store: Store,
  ) {}

  @Selector()
  static getFeeManagement(state: FeeManagementStateModel) {
    return state;
  }

  @Action(GetFeeListing)
  fetchFeeListing(
    ctx: StateContext<FeeManagementStateModel>,
    action: GetFeeListing,
  ) {
    return this.feeManagementService
      .getFeeListing(action?.page, action?.size, action?.searchValue)
      .pipe(
        map((response) => {
          ctx.patchState({
            ...ctx.getState(),
            feeListing: response,
          });
        }),
        catchError((error) => {
          console.error('Error fetching fee listing:', error);
          return of(null);
        }),
      );
  }

  @Action(GetClientFeeListing)
  fetchClientFeeListing(
    ctx: StateContext<FeeManagementStateModel>,
    action: GetClientFeeListing,
  ) {
    return this.feeManagementService
      .getClientFeeListing(
        action.clientId,
        action?.page,
        action?.size,
        action?.searchValue,
      )
      .pipe(
        map((response) => {
          ctx.patchState({
            ...ctx.getState(),
            clientFeeListing: response,
          });
        }),
        catchError((error) => {
          console.error(`Error fetching client's fee listing:`, error);
          return of(null);
        }),
      );
  }

  @Action(GetFeeGroupByIdFeeListing)
  getFeeGroupByIdFeeListing(
    ctx: StateContext<FeeManagementStateModel>,
    action: GetFeeGroupByIdFeeListing,
  ) {
    const getStateData = ctx.getState();
    return this.feeManagementService
      .getFeeGroupByIdFeeListing(
        getStateData.selectedFeeGroupData?.id,
        action?.page,
        action?.size,
        action?.searchValue,
      )
      .pipe(
        map((response) => {
          ctx.patchState({
            ...ctx.getState(),
            selectedFeeGroupByIdFeeListing: response,
          });
        }),
        catchError((error) => {
          console.error('Error fetching fee listing:', error);
          return of(null);
        }),
      );
  }

  @Action(GetFeeGroupsListing)
  fetchFeeGroupsListing(
    ctx: StateContext<FeeManagementStateModel>,
    action: GetFeeGroupsListing,
  ) {
    return this.feeManagementService
      .getFeeGroupListing(action?.page, action?.size, action?.searchValue)
      .pipe(
        map((response) => {
          ctx.patchState({
            ...ctx.getState(),
            feeGroupListing: response,
          });
        }),
        catchError((error) => {
          console.error('Error fetching fee group listing:', error);
          return of(null);
        }),
      );
  }

  @Action(FetchSelectedClientDetails)
  fetchSelectedClientDetails(
    ctx: StateContext<FeeManagementStateModel>,
    action: FetchSelectedClientDetails,
  ) {
    if (action?.clientId) {
      return this.feeManagementService
        .getSelectedClientDetails(action?.clientId)
        .pipe(
          map((response) => {
            ctx.patchState({
              ...ctx.getState(),
              selectedClientDetails: response,
            });
          }),
          catchError((error) => {
            console.error('Error fetching fee group listing:', error);
            return of(null);
          }),
        );
    } else {
      console.error('Active Org Id Not Found');
      return of(null);
    }
  }

  @Action(GetSelectedFeeGroup)
  getSelectedFeeGroup(
    ctx: StateContext<FeeManagementStateModel>,
    action: { id: string },
  ) {
    const getState = ctx.getState();
    const selectedItem = getState.feeGroupListing?.result?.find(
      (item) => item.id === action.id,
    );
    if (selectedItem) {
      ctx.patchState({
        ...ctx.getState(),
        selectedFeeGroupData: selectedItem,
      });
    }
  }

  @Action(GetAllFeeGroups)
  getAllFeeGroups(ctx: StateContext<FeeManagementStateModel>) {
    return this.feeManagementService.getAllFeeGroups().pipe(
      map((response) => {
        ctx.patchState({
          ...ctx.getState(),
          allFeeGroups: response,
        });
      }),
      catchError((error) => {
        console.error('Error fetching all groups :', error);
        return of(null);
      }),
    );
  }

  @Action(ResetSelectedFeeGroup)
  resetSelectedFeeGroup(ctx: StateContext<FeeManagementStateModel>) {
    ctx.patchState({
      ...ctx.getState(),
      selectedFeeGroupData: {
        id: '',
        name: '',
        description: '',
        createdAt: '',
        clientLinked: 0,
      },
    });
  }

  @Action(SetSelectedFee)
  setSelectedFee(
    ctx: StateContext<FeeManagementStateModel>,
    action: { id: string },
  ) {
    const getState = ctx.getState();
    const selectedItem = getState.feeListing?.result?.find(
      (item) => item.id === action.id,
    );
    if (selectedItem) {
      ctx.patchState({
        ...ctx.getState(),
        selectedFeeData: selectedItem,
      });
    }
  }

  @Action(SetSelectedClientFee)
  setSelectedClientFee(
    ctx: StateContext<FeeManagementStateModel>,
    action: { id: string },
  ) {
    const getState = ctx.getState();
    const selectedItem = getState.clientFeeListing?.result?.find(
      (item) => item.id === action.id,
    );
    if (selectedItem) {
      ctx.patchState({
        ...ctx.getState(),
        selectedFeeData: selectedItem,
      });
    }
  }

  @Action(ValidationFeeDetails)
  validationFeeDetails(
    ctx: StateContext<FeeManagementStateModel>,
    action: { res: AddEditFeeRequestPayload | AdHocFeeRequest },
  ) {
    ctx.patchState({
      ...ctx.getState(),
      addEditFeeData: action?.res,
    });
  }

  @Action(ResetSelectedFee)
  resetSelectedFee(ctx: StateContext<FeeManagementStateModel>) {
    const state = ctx.getState();
    delete state?.selectedFeeData;
    ctx.patchState({
      ...state,
    });
  }

  @Action(ResetSelectedClient)
  resetSelectedClient(ctx: StateContext<FeeManagementStateModel>) {
    const state = ctx.getState();
    delete state?.selectedClientDetails;
    ctx.patchState({
      ...state,
    });
  }

  @Action(GetAllClientListing)
  getAllClientListing(
    ctx: StateContext<FeeManagementStateModel>,
    action: GetAllClientListing,
  ) {
    return this.feeManagementService
      .getAllClientListing(action?.page, action?.size, action?.searchValue)
      .pipe(
        map((response) => {
          ctx.patchState({
            ...ctx.getState(),
            clientListing: response,
          });
        }),
        catchError((error) => {
          console.error('Error fetching client listing:', error);
          return of(null);
        }),
      );
  }

  @Action(GetFeeGroupByIdClientListing)
  getFeeGroupByIdClientListing(
    ctx: StateContext<FeeManagementStateModel>,
    action: GetFeeGroupByIdClientListing,
  ) {
    const getStateData = ctx.getState();
    return this.feeManagementService
      .getFeeGroupByIdClientListing(
        getStateData.selectedFeeGroupData?.id,
        action?.page,
        action?.size,
        action?.searchValue,
      )
      .pipe(
        map((response) => {
          ctx.patchState({
            ...ctx.getState(),
            selectedGroupByIdClientListing: response,
          });
        }),
        catchError((error) => {
          console.error('Error fetching client listing:', error);
          return of(null);
        }),
      );
  }

  @Action(GetFeeChargeHistoryListing)
  fetchFeeChargeHistoryListing(
    ctx: StateContext<FeeManagementStateModel>,
    action: GetFeeListing,
  ) {
    return this.feeManagementService
      .getFeeChargeHistoryListing(
        action?.page,
        action?.size,
        action?.searchValue,
      )
      .pipe(
        map((response) => {
          ctx.patchState({
            ...ctx.getState(),
            feeChargeHistoryListing: response,
          });
        }),
        catchError((error) => {
          console.error('Error fetching fee charge history listing:', error);
          return of(null);
        }),
      );
  }
}
