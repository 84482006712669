import { Injectable } from '@angular/core';
import { CardInfoType } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { GetMonthlySummary } from '../actions/account.action';
import {
  AddProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';
import { AccountDebitCreditSummary } from '../services/account-service/account.type';

export interface MonthlySummaryStateModel {
  items: CardInfoType[];
}

@State<AccountDebitCreditSummary>({
  name: 'monthlysummary',
  defaults: {
    total_credits: 0,
    total_debits: 0,
    last_updated: '',
  },
})
@Injectable()
export class MonthlySummaryState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static getMonthlySummary(state: AccountDebitCreditSummary) {
    return state;
  }
  @Action(GetMonthlySummary)
  fetchCards(
    ctx: StateContext<AccountDebitCreditSummary>,
    action: GetMonthlySummary,
  ) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetMonthlySummary' }));
    try {
      return this.accountService.getMonthlySummary(action.accountId).pipe(
        tap((resultFeed: AccountDebitCreditSummary) => {
          ctx.setState({
            total_credits: resultFeed.total_credits,
            total_debits: resultFeed.total_debits,
            last_updated: resultFeed.last_updated,
          });
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'GetMonthlySummary' }),
          );
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'GetMonthlySummary' }),
          );
          throw _err;
        }),
      );
    } catch (err) {
      return throwError(() => err);
    }
  }
}
