/* eslint-disable @typescript-eslint/no-explicit-any */
import { Type } from '@angular/core';
import { PaymentProvider } from './cards.type';
import { ButtonActionType } from './common.type';
import {
  ColourPaletteType,
  KycConfig,
  LayoutWidgetConfig,
  PageWidgetConfig,
  PaymentConfig,
  ProfileConfig,
  SectionNames,
  VerificationConfig,
  WidgetNames,
} from './config.type';
import { PACKS } from './default-constants/workflow-constants.type';
import {
  UiZoneConfig,
  UiZoneDevicesConfig,
  UiZoneWidgetAttributeConfig,
} from './ui-system-config.v2.type';
import {
  NestedWorkflowWidgetConfiguration,
  WorkflowZoneConfig,
  WorkflowZoneDevicesConfig,
} from './workflow.v2.type';

export enum PAGELAYOUTTYPE {
  SECTION = 'section',
  WIDGET = 'widgetPage',
}

export enum WIDGETYPE {
  profileWidget = 'profileWidget',
  profileproperty = 'profileproperty',
}

export enum Page {
  ACCOUNT_PAGE = 'account-page',
  ADDRESS_PAGE = 'address-page',
  ADDITIONAL_FIELDS_PAGE = 'additional-fields-page',
  CONSENT_VERIFICATION = 'consent-verification',
  AWAIT_VERIRIFICATION = 'await-verification',
  SUCCESSFUL_VERIFICATION = 'successful-verification',
  PROFILE_DETAIL_VERIFICATION = 'profile_detail_verification',

  PAYMENT_TRANSFER = 'payment-transfer',
  TRANSFER_CONFIRMED = 'transfer-confirmed',
  TRANSFER_FAILED = 'transfer-failed',
  TRANSFER_LOADING = 'transfer-loading',

  ACCOUNT_BALANCE = 'account-balance',
  ACCOUNT_BALANCE_WIDGET = 'account_balance_widget',

  HOME_PAGE = 'home-page',

  BURNDOWN_CHART = 'burndown_chart',

  PROFILE_PAGE = 'profile-page',
  PRIVACY_PAGE = 'privacy-page',

  // Should function lie a landing or chart page however the holder page need to be
  // Landing page and chart pages should be able to use this page in the future
  DYNAMIC_ZONE_HOLDER_PAGE = 'dynamic-zone-holder-page',
  QUESTION_WIDGET = 'question_widget',
  MOT_VERIFICATION_PAGE = 'mot_verification',
  COMPANY_HOUSE_CHECK = 'company_house_check',
  ACCOUNT_REGISTRATION = 'account-registration',
  CUSTOM_QUESTION_WIDGET = 'custom_question_widget',
  PROFILE_WIDGET = 'profile-widget-page',

  CHART_WIDGET_INTEGRATION = 'chart-widget-integration-component',
  INDIVIDUAL_ACCOUNT_PAGE = 'individual-account-page',
  DESKTOP_INDIVIDUAL_ACCOUNT_PAGE = 'DesktopIndividualAccountPageComponent',
  USER_MANAGEMENT = 'UsersComponent',
  USER_MANAGEMENT_WIDGET = 'user_management_widget',
  TEXT_ITEM_WIDGET = 'text_item_widget',
  QUICK_LINKS_WIDGET = 'quick_links_widget',
  RESEND_WIDGET = 'resend_links_widget',
  VIEW_CARD_DELIVERY_STATUS = 'view_card_delivery_status',
  CARD_ACTIVATION = 'card_activation',
  ACTIVATE_CARD = 'activate-card',
  ACTIVATE_CARD_OTP = 'activate-card-otp',
  LOGOUT = 'logout',
  SWITCH_NAVIGATOR_QUICK_LINK = 'switch_navigator_quick_link',
  MULTI_ICON_QUICK_LINK_WIDGET = 'multi_icon_quick_link_widget',
  ACCOUNT_CREDENTIALS = 'account-credentials',
}

export type ComponentLabel = Page | WidgetNames | SectionNames;

export type WidgetFieldOptions = {
  position?: number;
  id?: string;
  field_type: string;
  field_name: string;
  label: string;
  type: string;
  show: boolean;
};
export type WidgetFields = {
  field_name: string;
  label: string;
  type: string;
  show: boolean;
  options: WidgetFieldOptions[];
  section?: string;
  uniqueId?: number;
  timer?: number;
  placeholder?: string;
  showDetailsButton?: boolean;
};

export type WidgetConfiguration = {
  additionalfields: WidgetFields[];
};

export type RightWidgetConfiguration = {
  nestedWidgets: {
    [uniqueId: string]: NestedWorkflowWidgetConfiguration;
  };
  screenName: string;
  subtitle?: string;
  elementsConfig: WidgetFields[];
  isRemovable: boolean;
  isWidgetPage?: boolean;
  attributes?: any;
  page?: string;
  uniqueId: number | string;
  showMargin: boolean;
  showPadding: boolean;
  showTextColor?: boolean;
  showFontStyle?: boolean;
  showHorizontalAlign?: boolean;
  showVerticalAlign?: boolean;
  showImageCategoryOption?: boolean;

  isVisible: boolean;

  name: string;
  nestedConfigKey?: string;
};
export type FieldAttribute = {
  name: string;
  label: string;
  type: string;
  options: [];
  decision_config: {
    required: string;
  };
  additional_config: unknown;
  css_config: BaseCssConfig;
  slug?: string;
  value?: string | boolean | number;
};

export interface WidgetLayoutAttributes extends UiZoneWidgetAttributeConfig {
  field_attributes: any[];
  label: string;
  show: boolean;
  sub_heading: string;
  timer?: number;
  columns?: Columns[];
  css_config: BaseCssConfig;
  slug?: string;
  title?: string;
  subTitle?: string;
  widgetName?: string;
  widgetIndex?: number;
  isSectionPopup?: boolean;
  isSeparateZone?: boolean;
  widgetProperties: BaseWidgetProperties;
}

export type Columns = {
  value: string;
  label: string;
  selected: boolean;
  handleBarKey?: string;
};
export type WidgetLayout = {
  name: string;
  position: number;
  attributes: WidgetLayoutAttributes;
  uniqueId?: number | string;
};

export type WidgetStyle = {
  [key: string]: string | undefined;
};

export type LayoutConfig = {
  layout: LayoutWidgetConfig[];
  widgetSections?: LayoutWidgetConfig[];
  gridOptions?: gridOptions;
  sumsublayout?: LayoutWidgetConfig[];
  pages?: PageWidgetConfig[];
};

export type Role = {
  id: string;
  name: string;
  userType: string;
  slug: string;
  zones: RoleZones;
  navBar: NavBar;
  applied_all_zones: boolean;
  is_removable: boolean;
};

export type NavBar = {
  navBarMobile: NavBarMobile;
  navBarDesktop: NavBarDesktop;
};

export type NavBarMobile = {
  iconSize: string;
  navBarColor: string;
  showLabels: boolean;
  labelFontSize?: number;
  layout: NavBarLayoutItem[];
  marginUnit: string;
  marginTop: number;
  marginRight: number;
  marginLeft: number;
  marginBottom: number;
  paddingUnit: string;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  borderRadiusUnit: string;
  borderRadiusTopLeft: number;
  borderRadiusTopRight: number;
  borderRadiusBottomRight: number;
  borderRadiusBottomLeft: number;
  borderWidthUnit: string;
  borderWidth: number;
  borderColor: string;
};

export type NavBarDesktop = {
  logo: string;
  menuType: 'vertical' | 'horizontal';
  iconSize: string;
  userNameColor: string;
  logOutColor: string;
  navBarColor: string;
  activeItemColor: string;
  marginUnit: string;
  marginTop: number;
  marginRight: number;
  marginLeft: number;
  marginBottom: number;
  paddingUnit: string;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  width: number;
  logoType: 'text' | 'icon';
  layout: NavBarLayoutItem[];
};

export type NavBarLayoutItem = {
  title: string;
  order: number;
  navigateToZone: string;
  activeIconColor: string;
  inactiveIconColor: string;
  itemActiveIn: 'page' | 'zone';
  icon: string;
  iconSizeWidth: number;
  iconSizeHeight: number;
  iconSizeUnit: string;
};

export type Subscriptions = {
  default: SubscriptionType;
  gold: SubscriptionType;
  silver: SubscriptionType;
  bronze: SubscriptionType;
};

export type SubscriptionType = {
  title: string;
};

export type PermissionSectionsZones = {
  [key: string]: ZoneDetail;
};

export type ZoneDetail = {
  title: string;
  name: string;
  position: number;
  allowedViews: {
    [key: string]: boolean;
  };
  zoneType: ZONETYPEOPTION | undefined;
  entityType?: ENTITY_TYPE;
  is_role_specific: boolean;
  url: string;
  userTypes?: string[];
  packs?: PACKS[];
};

export enum ENTITY_TYPE {
  USER_PREFERENCE = 'user_preference',
  FACILITY = 'facility',
  TRIP = 'trip',
  EVENT = 'event',
  EMPLOYEE = 'employee',
}

export enum ZONETYPEOPTION {
  ENTITYZONEQUESTION = 'entity_zone_questions',
  DEFAULT = 'default',
}

export type RoleZones = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]: CanvasZone | null;
};

export type CanvasZone = {
  canvases: Canvases;
};

export type Canvases = {
  name: string;
  subscription: CanvasSubscription;
};

export type DefaultConfig = {
  mobile: WorkflowZoneConfig | UiZoneConfig;
  tablet: WorkflowZoneConfig | UiZoneConfig;
  desktop: WorkflowZoneConfig | UiZoneConfig;
};
export type Default = {
  config: DefaultConfig;
};

export type CanvasSubscription = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [key: string]:
    | UiZoneDevicesConfig
    | WorkflowZoneDevicesConfig
    | null
    | undefined;
};

export type gridOptions = {
  numRows: number;
  numCols: number;
  gridConfig: Array<gridConfig>;
};

export type gridConfig = {
  index: number;
  gridRow: string;
  gridCol: string;
  classProp: {
    [key: string]: boolean | string | Type<object>;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type AllTheConfigOptionsClass = {} | null;

export type PermissionConfig = {
  subscriptions: Subscriptions;
  zones: PermissionSectionsZones;
  roles: Role[];
};

export type SystemZones = {
  [key: string]: SystemZoneDetail;
};

export type SystemZoneDetail = {
  title: string;
  roles: SystemZoneRoles;
};

export type SystemZoneRoles = {
  [key: string]: SystemRoleCanvas;
};

export type SystemRoleCanvas = {
  [key: string]: SystemDefaultCanvasConfig;
};

export type SystemDefaultCanvasConfig = {
  [key in SupportedDevice]: UiZoneConfig;
};

export type DeviceConfig = {
  organisations: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    group: {};
    individual: IndividualConfig;
  };
};

export type SupportedDevice = 'mobile' | 'desktop' | 'tablet';

export type IndividualConfig =
  | LayoutConfig
  | PaymentConfig
  | ProfileConfig
  | VerificationConfig
  | KycConfig;

export type OrganisationConfig = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  group: {};
  individual: IndividualConfig;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type SystemAllTheConfigOptions = {};
export type SectionPageConfiguration = {
  page: ComponentLabel;
  position: number;
  page_zone: string;
  page_section: string;
  componentLabel: ComponentLabel;
  isAutoBuild?: boolean;
  slug?: string;
};

export interface ActiveState extends SectionPageConfiguration {
  currentModuleType: PAGELAYOUTTYPE;
  currentSectionItem: number;
  prevZone: string;
  clearScreenContainer: boolean;
}

export const supportedFonts: Array<{ name: string; value: string }> = [
  {
    name: 'Mulish',
    value: 'Mulish',
  },
  {
    name: 'Roboto',
    value: 'roboto',
  },
  {
    name: 'Montserrat',
    value: 'montserrat',
  },
  {
    name: 'Metropolis',
    value: 'metropolis',
  },
  {
    name: 'Ubuntu',
    value: 'Ubuntu',
  },
  {
    name: 'Open Sans',
    value: 'Open Sans',
  },
  {
    name: 'Lato',
    value: 'Lato',
  },
  {
    name: 'Prata',
    value: 'Prata',
  },
  {
    name: 'Inter',
    value: 'Inter',
  },
  {
    name: 'Michroma',
    value: 'Michroma',
  },
];

export type StyleConfig = {
  activeColourPalette: number;
  colour_palettes: Array<ColourPaletteType>;
  systemName: string;
  legalEntityName: string;
  loginPageConfig: {
    logoEnabled: boolean;
  };
  logo: string;
  font_list?: string[];
};

export type GridPosition = {
  x: number;
  y: number;
  cols: number;
  rows: number;
  minItemRows?: number;
  minItemCols?: number;
  resizeEnabled: boolean;
  dragEnabled: boolean;
  layerIndex?: number;
  uniqueId?: string | number;
  name?: string;
};

export interface GridsterLayerEvent {
  layerIndex: number;
  uniqueId: string | number;
  gridPosition: GridPosition;
  index: number;
}

export type Section = {
  uniqueId: number | string;
  isMultiple?: boolean;
  widgetConfiguration: RightWidgetConfiguration;
  pages: SectionPageConfiguration[];
  name: WidgetNames | SectionNames;
  title: string;
  shape: string;
  type: string;
  no_of_blocks: string[];
  display: string;
  position: number;
  gridPosition?: GridPosition;
  pageType: string;
  widgetPages?: Section[];
  isSectionPopup?: boolean;
  image?: string;
};

export type ModuleAttributes = {
  slug?: string;
};

export enum SINK_TYPE {
  HASURA = 'hasura',
  METADATA = 'metadata',
}

export type customDataQuestionDataType = {
  data_type: string; // datetime, string, number, stringArray
  entity: string; // user_preference
  sink: string; // metadata || hasura
  isUpdateToHasura?: boolean;
};

export type questionPropertiesOption = {
  text: string;
  value: string;
  suboption?: { text: string; value: string; checked?: boolean }[];
  checked?: boolean;
};

export type questionProperties = {
  name: string;
  label: string;
  type: string;
  options: questionPropertiesOption[];
  preSelectSubAnswer: string;
};

export type customDataQuestionType = {
  questionUniqueId: string;
  type: string; // checkbox, dropdown
  header: string;
  subHeader: string;
  questionProperties: questionProperties;
  validationRules: ValidationRules[];
};

export type customDataType = {
  data: {
    [questionUniqueId: string]: customDataQuestionDataType;
  };
  questions: {
    [questionUniqueId: string]: customDataQuestionType;
  };
};

export type WorkflowConfig = {
  version?: string;
  projectId: string;
  permissionSections: PermissionConfig;
  styleConfig: StyleConfig;
  packs?: string[];
  customData: customDataType;
};

export type ActiveConfig = {
  activeViewScreen: {
    page: string;
  };
  activeSection: string;
  activeCanvasSubscription: string;
  activeZone: string;
  activeRole: string;
  activeRoleIndex: number;
  activeDevice: SupportedDevice;
  activeUserType: WorkflowUserType;
  // activePageIndex is used to determin page section shown, will use activePageState to find
  // the active Page state in the pageStates array
  activePageIndex: number;
  activePageStateIndex: number | null;

  isFullSectionPage: boolean;
  activeScreenWidth: number;
  activeScreenHeight: number;
  isAppWindowPreview?: boolean;
};

export enum WorkflowUserType {
  ORG = 'org',
  ADMIN = 'admin',
  USER = 'user',
  SIGNED_OUT = 'signed-out',
}

export enum APP_ZONES {
  ONBOARDING = 'onboarding',
  VERIFICATION = 'verification_zone',
  CHART = 'charts_zone',
  LANDING = 'landing_zone',
  PROFILE = 'profile_zone',
  PAYMENT = 'payments_zone',
  USER_MANAGEMENT = 'user_management_zone',
  MENU_ZONE = 'menu_zone',
  GENERAL = 'general_zone',
  CARD_MANAGEMENT_ZONE_DEMO = 'card_management_zone_demo',
  USER_PREFERENCE_ZONE = 'preferences_zone',
  ORGANISATION_ONBOARDING = 'organisation-onboarding',
  ORGANISATION_VERIFICATION = 'organisation_verification',
  ORGANISATION_PROFILE = 'org_profile_zone',
  SIGNED_OUT_FIRST_INTRODUCTION = 'first-intro',
  SIGNED_OUT_INTRODUCTION = 'intro',
  LOGIN = 'login',
  GUEST_LANDING_ZONE = 'guest_landing_zone',
  GUEST_EMPLOYEE_ZONE = 'guest_employee_zone',
  EMPLOYEE_INFORMATION_ZONE = 'employee_information_zone',
  GUEST_INFORMATION_ZONE = 'guest_information_zone',
  USER_PREFRENCE_REPORT_ZONE = 'employee_user_prefrence_report_zone',
  ACCOUNTS_ZONE = 'accounts_zone',
  BROKER_FACILITY = 'broker_facility',
  BROKER_EVENTS = 'broker_events',
  BROKER_LANDING = 'broker_landing',
  BROKER_EMPLOYEE = 'broker_employee',
  BROKER_GUEST = 'broker_guest',
  SIGNED_OUT_FORGOT_PASSWORD = 'forgot_password',
  BENEFICIARY_ZONE = 'beneficiary_zone',
  REVENIR_ZONE = 'revenir_zone',
  FEE_MANAGEMENT = 'fee_management_zone',
  CARD_MANAGEMENT_ZONE = 'card_management_zone',
}

export enum WORKFLOW_POPUPS {
  CUSTOM_QUESTION_WIDGET = 'customQuestionPopup',
  IMAGE_WIDGET = 'imageWidgetPopup',
  CAROUSEL_WIDGET = 'carouselWidgetPopup',
  SIGNUP_AUTO_BUILD_WIDGET = 'signupAutoBuildWidget',
  INTEGRATION_WIDGET = 'integrationWidget',
  QUESTION_SUMMARY_WIDGET = 'questionSummaryWidget',
  ORGANISATION_PROFILE = 'workflowManagerOrganisationProfilePopup',
  USER_MANAGEMENT = 'workflowManagerManageUserPopup',
  BENEFICIARY_ZONE = 'workflowManagerBeneficiaryZonePopup',
}

export type LineAdditionalConfig = {
  lineThickness: number;
  lineWidth: number;
  paddingBottom: number;
  paddingTop: number;
  paddingLinked: boolean;
  color: string;
  show: boolean;
  showlineWidget: boolean;
  contentAlignment: string;
};

export interface CardManagementAdditionalConfig extends WidgetLayoutAttributes {
  color: string;
  show: boolean;
  contentAlignment: string;
  isSeparateZone: boolean;
}

export type CardManagementPopupOption = {
  type: ComponentLabel;
  index: number;
};

export type BaseCssConfig = {
  marginUnit: string;
  marginTop: number;
  marginLeft: number;
  marginRight: number;
  marginBottom: number;
  paddingUnit: string;
  paddingTop: number;
  paddingLeft: number;
  paddingRight: number;
  paddingBottom: number;
  showPadding?: boolean;
  fontSize?: number;
  fontUnit?: string;
  color?: string;
  textAlignment?: string;
  borderRadiusUnit?: string;
  borderRadiusTopLeft?: number;
  borderRadiusBottomLeft?: number;
  borderRadiusTopRight?: number;
  borderRadiusBottomRight?: number;
  inputBorderRadiusUnit?: string;
  inputBorderRadiusTopLeft?: number;
  inputBorderRadiusBottomLeft?: number;
  inputBorderRadiusTopRight?: number;
  inputBorderRadiusBottomRight?: number;
  borderWidth?: number;
  borderColor?: string;
  borderStyle?: string;
  inputBorderWidth?: number;
  inputBorderColor?: string;
  inputBorderStyle?: string;
  inputIdleBackgroundColor?: string;
  fontWeight?: string;
  backGroundColor?: string;
  selectedBorderColor?: string;
  verticalAlignment?: string;
  disabledTextColor?: string;
  disabledBgColor?: string;
  selectedobjectfit?: string;
  selectedOpacity: number;
  isTextBold: boolean;
  isiTextItalic: boolean;
  isTextUnderline: boolean;
  textFont?: string;
  shadowBlur: number;
  shadowSpread: number;
  shadowOffsetX: number;
  shadowOffsetY: number;
  shadowColor: string;
  noAlignment?: boolean;
  inputPlaceholderColor?: string;
  inputCustomStyling?: string;
  textInputFontSize?: number;
  textInputFontUnit?: string;
  textInputBackgroundColor?: string;
  iconUnit?: string;
  iconSize?: number;
  iconColor?: string;
  leftIconColor?: string;
  leftIconUnit?: string;
  rightIconUnit?: string;
  righticonSize?: number;
  rightIconColor?: string;
  lefticonSize?: number;
  width?: number;
  height?: number;
  dimensionUnit?: string;
  alertColor?: string;
};

export type NotificationSettingsWidgetLevel = {
  channel: string;
  isActive: boolean;
  category: string;
};

export type InputFieldSettings = {
  showFlags?: boolean;
  isIconAllowed?: boolean;
  sellAccountLabel?: string;
  buyAccountLabel?: string;
  amountInputLabel?: string;
  currencyLabel?: string;
  countryLabel?: string;
  companyNameLabel?: string;
  isAllowed?: boolean;
  label?: string;
  css_config: InputCssConfig;
  addEditfeeInputsConfiguration?: Record<string, Record<string, string>>;
  addFeeGroupFields?: Record<string, string>;
  isTimerSeparated?: boolean;
};

export type InputCssConfig = {
  inactiveBorderColor: string;
  backgroundColor: string;
  inactiveBackgroundColor: string;
  borderRadiusTopLeft: number;
  borderRadiusTopRight: number;
  borderRadiusBottomLeft: number;
  borderRadiusBottomRight: number;
  borderWidth: number;
  borderColor: string;
  borderStyleUnit: string;
  inputHeight: number | undefined;
  inputWidth: number;
  iconSize: number;
  iconSizeUnit: string;
  inputTextColor: string;
};

export type AllowedCurrencyType = {
  code: string;
  name: string;
};

export type AllowedCountryType = {
  key: string;
  currencyFlag: string;
  title: string;
};

export type BaseWidgetProperties = {
  textContent: string;
  paymentGateway?: PaymentProvider;
  icon?: string;
  headerColor?: string;
  subheaderColor?: string;
  preSelectSubAnswer?: string;
  [key: string]: any;
  dateType?: DatePickerTypes;
  animationSource?: string;
  assetType?: string;
  assetUrl?: string;
  animationType?: string;
  animationFileName?: string;
  uploadFileId?: string;
  righticon?: string;
  lefticon?: string;
  notificationSettings?: NotificationSettingsWidgetLevel;
  inputFieldSettings?: InputFieldSettings;
  allowedCurrencies?: AllowedCurrencyType[];
  allowedCountries?: AllowedCountryType[];
  selectedDefaultCurrency?: string;
  context?: 'Buy' | 'Sell';
  showFlag?: boolean;
  widgetContext?: any;
  buttonActionType?: ButtonActionType; // for defining action of CTA button Widget
  headerConfig?: {
    headerFontSize?: number;
    headerFontUnit?: string;
    headerTextFont?: string;
    headerColor?: string;
  };
  subHeaderConfig?: {
    subHeaderFontSize?: number;
    subHeaderFontUnit?: string;
    subHeaderTextFont?: string;
    subheaderColor?: string;
  };
};

export enum DatePickerTypes {
  DATEPICKER = 'datepicker',
  INPUT = 'input',
}
export enum ValidationRuleTypes {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  MINLENGTH = 'minlength',
  MAXLENGTH = 'maxlength',
  EMAIL = 'email',
  MINVALUE = 'min',
  MAXVALUE = 'max',
}
export type ValidationRules = {
  // isRequired: boolean | undefined;
  type: ValidationRuleTypes;
  value: string;
};

export type baseCssConfigStyle = {
  'margin-top'?: string;
  'margin-left'?: string;
  'margin-right'?: string;
  'margin-bottom'?: string;
  'padding-top'?: string;
  'padding-left'?: string;
  'padding-right'?: string;
  'padding-bottom'?: string;
  'font-size'?: string;
  color?: string;
  'text-align'?: string;
  'border-top-left-radius'?: string;
  'border-top-right-radius'?: string;
  'border-bottom-right-radius'?: string;
  'border-bottom-left-radius'?: string;
  'border-width'?: string;
  'border-color'?: string;
  'border-style'?: string;

  'font-weight'?: string;
  'background-color'?: string;
  border?: string;
  height?: string;
  width?: string;
  display?: string;
  'align-items'?: string;
  'justify-content'?: string;
  position?: string;
  'background-size'?: string;
  'object-fit'?: string;
  bottom?: string;
  top?: string;
  left?: string;
  right?: string;
};

export enum TextAlignToJustifyContent {
  left = 'start',
  center = 'center',
  right = 'end',
}

export type LineAdditionalConfigStyle = {
  line: {
    'border-bottom': string;
    width: string;
  };
  content: {
    'padding-bottom': string;
    'padding-top': string;
    'justify-content': string;
    visibility: string;
  };
};

export interface TextAdditionalConfig extends UiZoneWidgetAttributeConfig {
  color: string;
  show: boolean;
  widgetProperties: BaseWidgetProperties;
}

export interface TextItemWidgetConfig extends UiZoneWidgetAttributeConfig {
  color: string;
  showTextItemWidget: boolean;
  title: string;
  subTitle: string;
  showQuickLinksWidget?: boolean;
  url?: string;
  btnFnName?: string;
  isButton?: boolean;
  widgetProperties: BaseWidgetProperties;
}

export interface QuickLinksWidgetConfig extends UiZoneWidgetAttributeConfig {
  zone: string;
  pageUrl: string;
  title: string;
  role: string;
  widgetProperties: BaseWidgetProperties;
}

export interface SwitchNavigatorQuickLinkWidgetConfig
  extends UiZoneWidgetAttributeConfig {
  linksList: {
    textContent: string;
    pageUrl: string;
    zone: string;
  }[];
  role: string;
  widgetProperties: BaseWidgetProperties;
}

export interface LogoutWidgetConfig extends UiZoneWidgetAttributeConfig {
  title: string;
  widgetProperties: BaseWidgetProperties;
}

export interface NotificationWidgetConfig extends UiZoneWidgetAttributeConfig {
  widgetProperties: BaseWidgetProperties;
}

export interface AddCurrencyAccountWidgetConfig
  extends UiZoneWidgetAttributeConfig {
  widgetProperties: BaseWidgetProperties;
}

export interface H1AdditionalConfig extends UiZoneWidgetAttributeConfig {
  color: string;
  show: boolean;
  widgetProperties: BaseWidgetProperties;
}

export interface QuillWidgetType extends UiZoneWidgetAttributeConfig {
  color: string;
  show: boolean;
}

export interface CardManagementType extends UiZoneWidgetAttributeConfig {
  color: string;
  show: boolean;
  contentAlignment: string;
}

export interface QuillAdditionalConfigStyle {
  text: {
    width: string;
  };
  content: {
    color: string;
    visibility: string;
  };
  baseConfig: baseCssConfigStyle;
  h1Config: {
    'font-size': string;
    'font-weight': string;
  };
}
export interface CardManagementStyle {
  content: {
    color: string;
    visibility: string;
    'justify-content': string;
  };
  baseConfig: baseCssConfigStyle;
}
export interface CardManagmentConfigStyle {
  text: {
    width: string;
  };
  content: {
    color: string;
    visibility: string;
  };
  baseConfig: baseCssConfigStyle;
}

export interface IFrameAdditionalConfig extends UiZoneWidgetAttributeConfig {
  iframeUrl: string;
  verticalHeight: string;
  showIFrameWidget: boolean;
}

export interface IFrameAdditionalConfigStyle {
  content: {
    height: string;
  };
  baseConfig: baseCssConfigStyle;
}

export interface NavBarIconStyle {
  width: string;
  height: string;
}

export interface BeneficiarySelectionWidgetConfig
  extends UiZoneWidgetAttributeConfig {
  title: string;
  widgetProperties: BaseWidgetProperties;
}

export interface ExchangeCurrencyAdditionalConfig {
  contextPage: string;
  showFlag: boolean;
  sellAccountLabel: string;
  buyAccountLabel: string;
  amountLabel: string;
}

export enum APP_ZONES_TITLE {
  ONBOARDING_PAGE = 'Onboarding Zone',
  LANDING = 'Landing Zone',
  CHART = 'Charts Zone',
  PAYMENT = 'Payments Zone',
  PROFILE = 'Profile Zone',
  VERIFICATION = 'Verification Zone',
  USER_MANAGEMENT = 'Manage Users Zone',
  MENU_ZONE = 'Menu Zone',
  GENERAL = 'General Zone',
  CARD_MANAGEMENT_ZONE_DEMO = 'Card Management Zone (demo)',
  USER_PREFERENCE_ZONE = 'Preferences Zone',
  ORGANISATION_ONBOARDING = 'Onboarding Organisation',
  ORGANISATION_VERIFICATION = 'Verification Organisation',
  ORGANISATION_PROFILE = 'Business Profile Zone',
  SIGNED_OUT_FIRST_INTRODUCTION = 'First Time Introduction (App)',
  SIGNED_OUT_FORGOT_PASSWORD = 'Forgot Password',
  SIGNED_OUT_INTRODUCTION = 'Introduction',
  GUEST_LANDING_ZONE = 'Guest Landing Zone',
  GUEST_EMPLOYEE_ZONE = 'Guest Employee Zone',
  EMPLOYEE_INFORMATION_ZONE = 'Employee Information Zone',
  GUEST_INFORMATION_ZONE = 'Guest Information Zone',
  USER_PREFRENCE_REPORT_ZONE = 'User Preferences Report',
  ACCOUNTS_ZONE = 'Accounts Zone',
  BROKER_FACILITY = 'Broker Facility',
  BROKER_EVENTS = 'Broker Events',
  BROKER_LANDING = 'Broker Landing',
  BROKER_EMPLOYEE = 'Broker Employee',
  BROKER_GUEST = 'Broker Guest',
  BENEFICIARY_ZONE = 'Beneficiary Zone',
  TRIP_ZONE = 'Trip Zone',
  REVENIR_ZONE = 'Revenir Zone',
  FEE_MANAGEMENT = 'Fee Management',
  CARD_MANAGEMENT_ZONE = 'Card Management Zone',
}

export type AppGridCanvasBody = {
  index: number;
  value: Role;
  tableEntries: Array<{
    key: string;
    value: CanvasZone | null;
    position: number;
    allowedViews: {
      [key: string]: boolean;
    };
  }>;
};

export type AllowedViews = {
  [key: string]: boolean;
};

export enum DELIVERY_STATUS {
  Ordered = 'Ordered',
  Dispatched = 'Dispatched',
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  InProgress = 'In Progress',
}

export enum DELIVERY_STATUS_ICON {
  Cart = 'cart',
  Signout = 'sign-out-common',
  Inbox = 'inbox',
  Checkcircle = 'check-circle-common',
}

export interface ChangePinModel {
  currentPin: string;
  newPin: string;
  confirmPin: string;
}

export enum WidgetTitles {
  SIGNUP = 'Signup',
  SIGNUP_NEW = 'Signup New',
  VERIFICATION = 'Verification',
  QUESTION_WIDGET = 'Question Widget',
  MOT_VERIFICATION = 'Vehicle Verification',
  ACCOUNT_BALANCE_WIDGET = 'Card and Account Balance',
  CARD_MANAGEMENT = 'Card Management',
  CARD_MANAGEMENT_ZONE = 'Card Management Zone',
  FREEZE_CARD = 'Freeze Card',
  REPLACE_CARD = 'Replace Card',
  CHANGE_PIN = 'Change Pin',
  LOST_CARD = 'Lost Card',
  VIEW_PIN = 'View Pin',
  VIEW_CONFIRM_DETAIL = 'View Confirm Detail',
  EDIT_CONFIRM_DETAIL = 'Edit Confirm Detail',
  VIEW_CARD_DELIVERY_STATUS = 'Card Delivery Status',
  TRACK_CARD_DELIVERY_STATUS = 'Track Card Delivery Status',
  CARD_ACTIVATION = 'Card Activation',
  ACCOUNT_MONTHLY_IN_AND_OUT_WIDGET = 'Monthly Income and Expenditure',
  PAYMENT_BUTTON = 'Payment Button',
  RECENT_TRANSACTION_LIST_WIDGET = 'Transaction History',
  PAYMENT_TRANSFER = 'Payment Transfer',
  PROFILE_PAGE = 'Profile',
  USER_PRIVACY_WIDGET = 'Privacy',
  BURNDOWN_CHART = 'Spending Graph',
  AUTO_BUILD_FORM = 'Auto Build Form',
  LINE_WIDGET = 'Line Widget',
  TEXT_WIDGET = 'Text',
  H1_WIDGET = 'H1 Header',
  IFRAME_WIDGET = 'IFrame Widget',
  ADD_FIELDS = 'Add Fields',
  USER_PROPERTIES = 'User Properties',
  ACCOUNT_NUMBER = 'Account Number',
  SORT_CODE = 'Sort Code',
  IBAN_NUMBER = 'IBAN Number',
  PROFILE_DETAILS = 'Profile Details',
  LANDING = 'Landing',
  VERIFICATION_WIDGET = 'Verification Widget',
  QUICK_LINKS_WIDGET = 'Quick Links',
  RESEND_WIDGET = 'Resend Links',
  IMAGE_WIDGET = 'Image',
  CAROUSEL_WIDGET = 'Carousel',
  LOGO_WIDGET = 'Logo',
  SIMPLE_BALANCE_WIDGET = 'Simple Balance',
  SUMSUB_Title = 'Sumsub Section',
  ACTIVATE_CARD = 'activate-card',
  ACTIVATE_CARD_OTP = 'activate-card-otp',
  USER_DETAIL_WIDGET = 'User Details',
  ACCOUNT_DETAIL_WIDGET = 'Account Details',
  CONTACT_DETAIL_WIDGET = 'Contact Details',
  IMPORT_USER_WIDGET = 'Import User',
  ACCOUNT_LIST_WIDGET = 'Account List',
  PASSWORD_SETTING_WIDGET = 'Password Setting',
  ADDRESS_WIDGET = 'Address',
  CTA_BUTTON_WIDGET = 'CTA - Submit',
  BENEFICIARY_CAROUSEL = 'Beneficiary Carousel',
  MULTI_ROLE = 'Multi Organisation',
  PROGRESSBAR_WIDGET = 'Progress Bar Widget',
  BACK_BUTTON_WIDGET = 'Back Navigation Button',
  FORWARD_BUTTON_WIDGET = 'Forward Navigation Button',
  ADD_BENEFICIARY_WIDGET = 'Add Beneficiary Widget',
  PASSWORD_CHANGE_WIDGET = 'Change Password',
  TRIP_INFORMATION_EXIT_WIDGET = 'Trip Information Exit Widget',
  PASSWORD_CHANGE_SUCCESS_WIDGET = 'Password Change Success',
  PASSWORD_NEW_WIDGET = 'New Password',
  PASSWORD_FORGOT_WIDGET = 'Forgot Password',
  ENTER_EMAIL_WIDGET = 'Enter Email',
  CHECK_EMAIL = 'Check Email',
  INTL_BENEFICIARY_ADDRESS_WIDGET = 'Beneficiary Address',
  INTL_BENEFICIARY_ADDED_WIDGET = 'Beneficiary Added',
  USER_PROFILE_WIDGET = 'User Profile',
  LOGOUT = 'Logout',
  INTEGRATION_WIDGET = 'Integration Widget',
  TRIP_LISTING_WIDGET = 'Trip Listing ',
  DESKTOP_TRIP_LISTING_WIDGET = 'Desktop Trip Listing',
  DESKTOP_EVENT_LINKED_LISTING_WIDGET = 'Desktop Event Linked Listing',
  TRIP_INFORMATION_WIDGET = 'Trip Information',
  TRIP_ADD_SELECT_WIDGET = 'Trip Add & Select',
  SWITCH_NAVIGATOR_QUICK_LINK = 'Switch Navigator Quick Link',
  BENEFICIARY_SELECTION_WIDGET = 'Beneficiary List',
  ACCOUNT_SELECTION_LIST_WIDGET = 'Account Selection List Widget',
  MULTI_ICON_QUICK_LINK_WIDGET = 'Multi Icon Quicklink',
  CONFIRM_BENEFICIARY_DETAILS = 'Confirm Beneficiary Details Button',
  LOTTIE_ANIMATION = 'Lottie Animation',
  USE_SUGGESTED_BENEFICARY_NAME = 'Use Suggested Beneficiary Name',
  CURRENCY_INPUT_FIELD_WIDGET = 'Currency Input Field Widget',
  PAYMENT_REF_WIDGET = 'Payment Reference Widget',
  MANDATORY_CTA_BUTTON = 'Mandatory CTA Button Widget',
  PROFILE_PICTURE_WIDGET = 'Profile Picture',
  NOTIFICATION_BELL_WIDGET = 'Notification Bell',
  BIOMETRICS_AUTHENTICATION_WIDGET = 'Authentication',
  NOTIFICATION_LIST_WIDGET = 'Notification List',
  NOTIFICATION_WIDGET = 'Notification',
  CURRENCY_ACCOUNTS_LISTING_WIDGET = 'Accounts Listing',
  EXCHANGE_CURRENCY_WIDGET = 'Exchange Currency',
  QUESTION_SUMMARY_WIDGET = 'Question Summary',
  ADD_CURRENCY_ACCOUNT_WIDGET = 'Add Currency Account',
  EXCHANGE_QUOTE_WIDGET = 'Exchange Quote',
  EXCHANGE_QUOTE_SUMMARY = 'Exchange Summary',
  ADD_FACILITY_STEP_1 = 'Create Facility',
  FILE_UPLOAD_WIDGET = 'File Upload',
  INTERNATIONAL_CURRENCY_COUNTRY = 'Intl. Currency & Country',
  INTERNATIONAL_BENEFICIARY_CREDENTIALS_WIDGET = 'International Beneficiary Credentials Widget',
  VERIFICATION_ORGANISATION_COMPANY_NAME = 'Company Name Widget',
  LINK_FACILITY = 'Link Facility',
  DOWNLOAD_DOCUMENT_WIDGET = 'Download Document',
  RANGE_CALENDAR_WIDGET = 'Range Calendar',
  OTP_VERIFICATION = 'OTP Verification',
  OTP_VERIFICATION_INTERNATIONAL_PAYMENTS = 'OTP Verification Intl. Payments',
  LANDING_ACCOUNTS_LISTING = 'Account Listing',
  ACCOUNTS_CONTEXT_DROPDOWN = 'Account Context Drop-down',
  PAYMENT_REASON = 'Payment Reason Widget',
  DESKTOP_USER_LISTING_WIDGET = 'Desktop User Listing',
  DESKTOP_MANAGE_USER_LISTING_WIDGET = 'Desktop Manage User Listing',
  ASSIGN_PERSONA_ROLE = 'Assign Persona & Role',
  USER_NAME_WIDGET = 'User name',
  ADD_EMPLOYEE_STEP_1_WIDGETS = 'Add Employee Step 1',
  ADD_EMPLOYEE_STEP_2_WIDGETS = 'Add Employee Step 2',
  MANAGE_USER_STEP_03_WIDGET = 'Add User Step 3',
  PROFILE_PICTURE_UPLOAD_WIDGET = 'Profile Picture Upload',
  PROFILE_PICTURE_DISPLAY = 'Profile Picture Display',
  LINK_TRIP_WIDGET = 'Link Trip',
  EDIT_BENEFICIARY_WIDGET = 'Edit Beneficiary Widget',
  DESKTOP_GUEST_USER_LISTING_WIDGET = 'Desktop Guest User Listing',
  DESKTOP_EMPLOYEE_USER_LISTING_WIDGET = 'Desktop Employee User Listing',
  DESKTOP_BULK_PAYMENT_LISTING_WIDGET = 'Bulk Payment Listing',
  DESKTOP_BULK_PAYMENT_HISTORY_LISTING_WIDGET = 'Desktop Bulk Payment History Listing',
  ONBOARDING_BACK_NEXT_BUTTON = 'Back Next Button',
  CONDITIONAL_WIDGET = 'Conditional Widget',
  UPLOAD_FILE_WITH_POPUP_WIDGET = 'Upload File With Popup Widget',
  ADD_EDIT_FEE = 'Add Edit Fee Widget',
  EU_COUNTRY_DROPDOWN = 'EU Country Dropdown',
  DOCUMENT_PREVIEW = 'Document Preview',
  FEE_CLIENT_LISTING_WIDGET = 'Fee/Client Listing',
  CLIENT_FEE_LISTING_WIDGET = 'Client Fee Listing',
  REVENIR_TRANSACTION_LIST = 'Transaction List',
  ADD_FEE_GROUP_WIDGET = 'Add Fee Group',
  FEE_GROUPS_LISTING = 'Fee Groups Listing',
  ASSIGN_GROUP_WIDGET = 'Assign Group Widget',
  COPY_SHARE_CONTENT = 'Copy/Share Content',
}

export enum NavigationOptionEnum {
  USE_AS_BLANK = 'use-as-blank',
  USE_AS_NAVIGATION = 'use-as-navigation',
  USE_AS_EXTERNAL_LINK = 'use-external-link',
}

export enum ConditionEnum {
  LESS_THAN = '<',
  GREATER_THAN = '>',
  EQUAL = '===',
  LESS_THAN_EQUAL = '<=',
  GREATER_THAN_EQUAL = '>=',
  NOT_EQUAL = '!==',
}

export enum ConditionalWidgetVariableEnum {
  KYC_PERSONAL = 'kyc_personal',
  BUSINESS_ONBOARDING = 'business_onboarding',
  KYB_BUSINESS = 'kyb_business',
  USER_LOGGED_IN = 'user_logged_in',
}
