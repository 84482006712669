import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RequestUrlSanitizerInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Rewrite the request url to remove double slashes
    // Regex ?<= not supported Safari iOS
    return next.handle(
      req.clone({ url: req.url.replace(/(https?:\/\/)|(\/)+/g, '$1$2') }),
    );
  }
}
