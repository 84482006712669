import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { APP_ZONES } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { UpdateFormDataAction } from '../../../actions/form-submission.action';
import { CreatePayment } from '../../../actions/payment.action';
import { TransferMoneyRequest } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function submitInitiatorPayment(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const requestData = store.selectSnapshot(FormActionState.getFormActionState)
    ?.response?.formData;
  const activeAccountId = store.selectSnapshot(
    AccountState.getCurrentAccountId,
  );

  if (requestData && Object.keys(requestData).length) {
    const payload: TransferMoneyRequest = {
      sourceAccountId: activeAccountId,
      bid: requestData.bid,
      amount: requestData.amount,
      paymentReference: requestData.paymentReference,
      transferReasonId: requestData.transferReasonId,
    };
    store.dispatch(
      new UpdateFormDataAction({
        transferDate: `${new Date()}`,
      }),
    );
    store.dispatch(new CreatePayment(payload));
    router.navigateByUrl(
      `/zones/${formatNameAsUrl(
        APP_ZONES.PAYMENT,
      )}/payment-processing-initiator`,
    );
  } else {
    alertHandlerService.showAlertFn(
      'error',
      'Payment request data not available, please try again',
    );
    console.error('payment request data not available, please try again');
  }
}
