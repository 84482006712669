import { Router } from '@angular/router';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
  InternationalPaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
  InternationalCreatePayment,
  InternationalPaymentDeclined,
  InternationalPaymentSuccess,
} from '../../../actions/payment.action';
import { TransferMoneyRequest } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';
import { ProjectSettingsState } from '../../../state/project-settings.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';

export function submitInternationPayment(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  actions: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  const isSmsAuthEnabled = store.selectSnapshot(
    ProjectSettingsState.getProjectSettings,
  ).smsAuthenticationEnabled;
  if (isSmsAuthEnabled) {
    redirectToPage(router, widgetProperties);
  } else {
    onSubmitInternationPayment(
      widgetProperties,
      store,
      actions,
      router,
      alertHandlerService,
    );
  }
}

export function onSubmitInternationPayment(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  actions: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
) {
  let formStateData = store.selectSnapshot(FormActionState.getFormActionState)
    ?.response?.formData;
  const activeAccountId = store.selectSnapshot(
    AccountState.getCurrentAccountId,
  );
  formStateData = {
    ...formStateData,
    review: false,
    sourceAccountId: activeAccountId,
  };
  if (formStateData) {
    actions
      .pipe(
        ofActionDispatched(InternationalPaymentSuccess),
        take(1),
        map(() => {
          router.navigateByUrl(
            `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/${
              InternationalPaymentStatusScreen.PAYMENT_SUCCESS
            }`,
            { replaceUrl: true },
          );
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(InternationalPaymentDeclined),
        take(1),
        map(() => {
          router.navigateByUrl(
            `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/${
              InternationalPaymentStatusScreen.PAYMENT_DECLINED
            }`,
            { replaceUrl: true },
          );
        }),
      )
      .subscribe();

    store.dispatch(
      new InternationalCreatePayment(filterObjectByType(formStateData)),
    );

    router.navigateByUrl(
      `/zones/${formatNameAsUrl(APP_ZONES.PAYMENT)}/${
        InternationalPaymentStatusScreen.PAYMENT_PROCESSING
      }`,
      { replaceUrl: true },
    );
  } else {
    alertHandlerService.showAlertFn(
      'error',
      'Payment request data not available, please try again.',
    );
  }
}

export function filterObjectByType(formStateData: any): TransferMoneyRequest {
  let result: TransferMoneyRequest = {
    sourceAccountId: '',
    transferReasonId: '',
    bid: '',
    amount: 0,
    paymentReference: '',
    review: false,
  };

  for (const key in formStateData) {
    if (key in result) {
      result = {
        ...result,
        [key]: formStateData[key],
      };
    }
  }

  return result;
}
