import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { checkVerificationStatusAndRedirect } from '@app/finxone-web-frontend/app/app.module';
import { Store } from '@ngxs/store';
import jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { ClearAccountCache } from '../../actions/account.action';
import { ClearBeneficiaryCache } from '../../actions/beneficiary.action';
import { ClearNotificationsCache } from '../../actions/notification.action';
import { ClearTransactionsCache } from '../../actions/transactions.action';
import { ConfigService } from '../../services/config-service/config-service.service';
import { MetadataService } from '../../services/metadata-service/metadata-service.service';
import { FormActionState } from '../../state/form-submision.state';
@Component({
  selector: 'app-post-auth',
  templateUrl: './post-auth.component.html',
})
export class PostAuthComponent {
  constructor(
    protected keycloakService: KeycloakService,
    private router: Router,
    private metaService: MetadataService,
    private store: Store,
    private configService: ConfigService,
  ) {
    // if still sitting on feature redirect after 4 seconds reload the page again
    setTimeout(() => {
      if (window.location.pathname.includes('post-auth')) {
        window.location.reload();
      }
    }, 4000);
    const redirectToOrgOnboardingFlow =
      this.store.selectSnapshot(FormActionState.getFormActionState)?.response
        ?.formData?.redirectToOrgOnboardingFlow ?? false;
    if (redirectToOrgOnboardingFlow) {
      this.router.navigateByUrl(
        '/zones/organisation-onboarding/onboarding/organisation-anonymous/default',
      );
      return;
    }
    const previousUserId = localStorage.getItem('lastLoggedInId');
    const token = localStorage.getItem('token') as string;
    const jwt: any = jwt_decode(token);
    const newUserId = jwt.sub;
    if (previousUserId && previousUserId !== newUserId) {
      localStorage.removeItem('account');
      localStorage.removeItem('beneficiary');
      localStorage.removeItem('transactions');
      localStorage.removeItem('profile');
      localStorage.removeItem('notifications');
      store.dispatch(new ClearAccountCache());
      store.dispatch(new ClearBeneficiaryCache());
      store.dispatch(new ClearTransactionsCache());
      store.dispatch(new ClearNotificationsCache());
    }
    checkVerificationStatusAndRedirect(token, router, metaService, store);
  }
}
