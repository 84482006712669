<div class="alert-wrapper" [ngStyle]="successalertMsgdiv">
  <div class="alert-message">
    <mat-icon
      alt="icon"
      class="error-succes-icon"
      svgIcon="succces-icon"
      [ngStyle]="successalertErroriconColor"
    ></mat-icon>
    <div class="alert-msg-info">
      <div [ngStyle]="successalertHeaderColor" class="alert-header">
        {{ headerMsg }}
      </div>

      <div class="alert-body">
        <span [ngStyle]="successalertHeaderColor">{{ msg }} </span>
      </div>
    </div>

    <span class="close-icon">
      <mat-icon
        [ngStyle]="successcloseIconColor"
        (click)="close()"
        alt="icon"
        svgIcon="close-icon"
      ></mat-icon>
    </span>
  </div>
</div>
