import { Component, Input } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AlertHandlerService } from '@finxone-platform/shared/services';
@Component({
  selector: 'finxone-platform-alert-error-message',
  templateUrl: './alert-error-message.component.html',
  styleUrls: ['./alert-error-message.component.scss'],
})
export class AlertErrorMessageComponent {
  @Input() msg: string | undefined = 'Your Sample text here.';
  @Input() erroralertMsgdiv: {
    background: string;
    border: string;
    color: string;
  };
  @Input() erroralertErroriconColor: { color: string };
  @Input() erroralertHeaderColor: { color: string; 'font-family': string };
  @Input() errorcloseIconColor: { color: string };
  @Input() headerMsg: undefined | string = 'Error!';
  constructor(private alertHandlerService: AlertHandlerService) {}
  close() {
    this.alertHandlerService.resetAlert();
  }
}
