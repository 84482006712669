import { Router } from '@angular/router';
import {
    InternationBeneficiaryFormStateModel,
    mapInternationBeneficiaryFormStateModelToAddBeneficiaryRequest,
} from '@app/finxone-web-frontend/app/Modules/shared/widgets/international-beneficiary-credentials/international-beneficiary-credentials.component';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { UiZoneWidgetAttributeConfig } from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { AccountService } from '../../../services/account-service/account-service.service';
import { CtaButtonSignalService } from '../../../services/cta-button-signal-service/cta-button-signal.service';
import { FormSubmissionService } from '../../../services/form-submission-service/form-submission-service.service';
import { FormActionState } from '../../../state/form-submision.state';
import { formatNameAsUrl } from '../../zone-url.utils';

export function addInternationalBeneficiary(
  store: Store,
  router: Router,
  alertHandlerService: AlertHandlerService,
  attri: UiZoneWidgetAttributeConfig,
  accountService: AccountService,
  formSubmissionService: FormSubmissionService,
  ctaButtonSignalService: CtaButtonSignalService,
) {
  const CTAButtonKey = attri.widgetProperties['buttonActionType'] as string;

  const formActionStateData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(
      FormActionTypeEnum.INTL_BENEFICIARY_ADD,
    ),
  );
  const formData =
    formActionStateData?.formData as InternationBeneficiaryFormStateModel;
  accountService
    .addBeneficiary(
      mapInternationBeneficiaryFormStateModelToAddBeneficiaryRequest(formData),
    )
    .subscribe({
      next: (response: any) => {
        ctaButtonSignalService.setSignal({
          [CTAButtonKey]: true,
        });
        formSubmissionService.submitIndividualForm(
          {
            bid: response.id,
          },
          '',
        );
        router.navigateByUrl(
          `/zones/${formatNameAsUrl(
            attri.widgetProperties['zoneToNavigate'],
          )}/${formatNameAsUrl(attri.widgetProperties['urlToNavigate'])}`,
        );
      },
      error: (error: any) => {
        ctaButtonSignalService.setSignal({
          [CTAButtonKey]: false,
        });
        alertHandlerService.showAlertFn('error', error);
      },
    });
}
