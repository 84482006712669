import { Router } from '@angular/router';
import { MetadataService } from '@app/finxone-web-frontend/app/lib/services/metadata-service/metadata-service.service';
import { FormActionState } from '@app/finxone-web-frontend/app/lib/state/form-submision.state';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  APP_ZONES,
  BaseWidgetProperties,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { UpdateCurrentAccountId } from '../../../actions/account.action';
import { AccountState } from '../../../state/account.state';
import { formatNameAsUrl } from '../../zone-url.utils';
import { redirectToPage } from '../cta-button-actions.utils';

export function checkSourceAccountAndRedirectToPayNewIntlBeneficiary(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _action: Actions,
  router: Router,
  _alertHandlerService: AlertHandlerService,
  _metaDataService: MetadataService,
) {
  //get beneficiary from form state
  const beneficiary = store.selectSnapshot(
    FormActionState.getFormActionStateWithId('intlBeneficiaryAdd'),
  )?.formData;
  //check accounts state for matching currency
  const accountsData = store.selectSnapshot(AccountState.getAccounts).accounts;
  const currencyAccount = accountsData.find(
    (v) => v.currency === beneficiary.currency,
  );
  //if exists update current account and redirect to pay intl bene
  if (currencyAccount) {
    store.dispatch(new UpdateCurrentAccountId(currencyAccount.accountId));
    redirectToPage(router, widgetProperties);
  } else {
    router.navigateByUrl(
      `/zones/${formatNameAsUrl(
        APP_ZONES.PAYMENT,
      )}/intl-no-source-currency-account`,
    );
  }
}
