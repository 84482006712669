import { Router } from '@angular/router';
import {
  InternationalPaymentStatusScreen,
  PaymentStatusScreen,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { map, take } from 'rxjs';
import {
  CreatePayment,
  PaymentDeclined,
  PaymentSuccess,
} from '../../../actions/payment.action';
import { TransferMoneyRequest } from '../../../services/account-service/account.type';
import { AccountState } from '../../../state/account.state';
import { FormActionState } from '../../../state/form-submision.state';

export function submitDomesticPaymentRequest(
  store: Store,
  actions: Actions,
  router: Router,
): void {
  let formStateData = store.selectSnapshot(FormActionState.getFormActionState)
    .response?.formData;
  const activeAccountId = store.selectSnapshot(
    AccountState.getCurrentAccountId,
  );
  formStateData = {
    ...formStateData,
    sourceAccountId: activeAccountId,
  };
  if (formStateData) {
    store.dispatch(
      new CreatePayment(formStateData as unknown as TransferMoneyRequest),
    );

    actions
      .pipe(
        ofActionDispatched(PaymentSuccess),
        take(1),
        map(() => {
          navigateToPaymentStatusScreen(
            PaymentStatusScreen.PAYMENT_SUCCESS,
            router,
          );
        }),
      )
      .subscribe();

    actions
      .pipe(
        ofActionDispatched(PaymentDeclined),
        take(1),
        map(() => {
          navigateToPaymentStatusScreen(
            PaymentStatusScreen.PAYMENT_DECLINED,
            router,
          );
        }),
      )
      .subscribe();
  }
}

export function navigateToPaymentStatusScreen(
  status: PaymentStatusScreen | InternationalPaymentStatusScreen,
  router: Router,
): void {
  router.navigateByUrl(`/zones/payments/${status}`, {
    replaceUrl: true,
  });
}
