import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { combineLatest } from 'rxjs';
import { UpdateAppData } from '../actions/app-data.action';
import { ConfigService } from '../services/config-service/config-service.service';

export interface AppDataStateModel {
  systemName: string;
  legalEntityName: string;
}

@State<AppDataStateModel>({
  name: 'appData',
  defaults: {
    systemName: '',
    legalEntityName: '',
  },
})
@Injectable()
export class AppDataState {
  constructor(
    store: Store,
    configService: ConfigService,
    private titleService: Title,
  ) {
    combineLatest([
      configService.getSystemName(),
      configService.getLegalEntityName(),
    ]).subscribe(([systemName, legalName]) => {
      store.dispatch(new UpdateAppData(systemName, legalName));
    });
  }

  @Selector()
  static getAppData(state: AppDataStateModel) {
    return state;
  }

  @Action(UpdateAppData)
  updateAppData(ctx: StateContext<AppDataStateModel>, action: UpdateAppData) {
    this.titleService.setTitle(action.systemName);
    ctx.patchState({
      systemName: action.systemName,
      legalEntityName: action.legalEntityName,
    });
  }
}
