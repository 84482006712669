import { Injectable } from '@angular/core';
import {
  KeycloakEvent,
  KeycloakOptions,
  KeycloakService,
} from 'keycloak-angular';
import { Subject } from 'rxjs';
import { clearLocalStorageExcept } from '../../utils/utils';

@Injectable({
  providedIn: 'root',
})
export class KeycloakWrapperService {
  constructor(private keycloakService: KeycloakService) {}

  public idleTimeLogout() {
    const firstTimeComplete = localStorage.getItem('firstTime');
    const appPreferences = localStorage.getItem('APP_PREFERENCES');
    localStorage.clear();
    if (appPreferences) localStorage.setItem('APP_PREFERENCES', appPreferences);
    localStorage.setItem('firstTime', firstTimeComplete as string);
    this.keycloakService.logout(window.location.origin + '/auth');
  }

  public login(options?: Keycloak.KeycloakLoginOptions): Promise<void> {
    return this.keycloakService.login(options);
  }

  public init(options?: KeycloakOptions): Promise<boolean> {
    return this.keycloakService.init(options);
  }

  public get keycloakEvents$(): Subject<KeycloakEvent> {
    return this.keycloakService.keycloakEvents$;
  }

  public updateToken(minValidity?: number | undefined): Promise<boolean> {
    return this.keycloakService.updateToken(minValidity);
  }

  public getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }

  public logout(redirectUri?: string | undefined): Promise<void> {
    clearLocalStorageExcept([
      'account',
      'transactions',
      'beneficiary',
      'notifications',
      'lastLoggedInId',
      'APP_PREFERENCES',
      'firstTime',
    ]);
    return this.keycloakService.logout(redirectUri);
  }

  public isLoggedIn(): boolean {
    return this.keycloakService.isLoggedIn();
  }

  public getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this.keycloakService.getKeycloakInstance();
  }
}
