import { Router } from '@angular/router';
import { MetadataService } from '@app/finxone-web-frontend/app/lib/services/metadata-service/metadata-service.service';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import { BaseWidgetProperties } from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { map, take } from 'rxjs';
import { ResetEventGuestState } from '../../../actions/event-guest.action';
import { GraphqlServiceService } from '../../../services/graphql-service/graphql-service.service';
import { redirectToPage } from '../cta-button-actions.utils';

export function removeGuestFromEvent(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  _: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  __: MetadataService,
  graphqlService: GraphqlServiceService,
) {
  graphqlService
    .removeGuestFromEvent()
    .pipe(
      take(1),
      map((response) => {
        if (response) {
          store.dispatch(new ResetEventGuestState());
          redirectToPage(router, widgetProperties);
          alertHandlerService.showAlertFn(
            'success',
            'Guest removed successfully',
          );
        } else {
          alertHandlerService.showAlertFn(
            'error',
            'An unexpected error occured, please try again.',
          );
        }
      }),
    )
    .subscribe();
}
