import { Injectable } from '@angular/core';
import { UsersList } from '@finxone-platform/shared/sys-config-types';
import { Action, State, StateContext, createSelector } from '@ngxs/store';

export interface ApiLoadingModel {
  getAccounts: {
    isLoading: boolean;
  };
  getTransactions: {
    isLoading: boolean;
  };
  getBeneficiaries: {
    isLoading: boolean;
  };
  getBeneficiaryRequiredFields: {
    isLoading: boolean;
  };
}

export enum ApiActions {
  'getAccounts' = 'getAccounts',
  'getTransactions' = 'getTransactions',
  'getBeneficiaries' = 'getBeneficiaries',
  'getBeneficiaryRequiredFields' = 'getBeneficiaryRequiredFields',
}

export class UpdateApiIsLoadingAction {
  static readonly type = '[ApiLoadingState] UpdateApiAction';
  constructor(public apiAction: ApiActions, public isLoading: boolean) {}
}

@State<ApiLoadingModel>({
  name: 'apiLoading',
  defaults: {
    getAccounts: {
      isLoading: false,
    },
    getTransactions: {
      isLoading: false,
    },
    getBeneficiaries: {
      isLoading: false,
    },
    getBeneficiaryRequiredFields: {
      isLoading: false,
    },
  },
})
@Injectable()
export class ApiLoadingState {
  static getApiState(apiCall: ApiActions) {
    return createSelector([ApiLoadingState], (state: ApiLoadingModel) => {
      return state[apiCall];
    });
  }

  @Action(UpdateApiIsLoadingAction)
  fetchUsers(ctx: StateContext<UsersList>, action: UpdateApiIsLoadingAction) {
    ctx.patchState({
      [action.apiAction]: {
        isLoading: action.isLoading,
      },
    });
  }
}
