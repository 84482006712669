import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Apollo } from 'apollo-angular';
import {
  AddProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';
import { GetTrips } from '../actions/trips.action';
import { GetTripsQueryGQL } from '../custom-data-models/travel-management/generated';
export interface TripType {
  id: string;
  embarkTime: string;
  embarkLocation: string;
  disembarkLocation: string;
  name: string;
  start: string;
  end: string;
  facility: {
    name: string;
    id: string;
  };
  trip_id: string;
  trip?: TripType;
  trips?: any[];
  available_on?: any;
}
export interface TripStateModel {
  trip: TripType[];
  [key: string]: TripType[];
}

@State<TripStateModel>({
  name: 'trip',
  defaults: {
    trip: [],
  },
})
@Injectable()
export class TripsState {
  constructor(private apollo: Apollo, private getTrip: GetTripsQueryGQL) {}

  @Selector()
  static getTrips(state: TripStateModel) {
    return state.trip;
  }

  @Action(GetTrips)
  fetchTrips(ctx: StateContext<TripStateModel>, action: GetTrips) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'GetTrips' }));

    // this.apollo
    //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //   .watchQuery<any>({
    //     query: GetTripsQueryDocument,
    //     variables: {
    //       offset: action.offset,
    //       limit: action.limit,
    //       where: action.where,
    //     },
    //   })
    //   .valueChanges

    // where: action.where,
    this.getTrip
      .fetch({
        offset: action.offset,
        limit: action.limit,
        where: action.where,
      })
      .subscribe((result) => {
        ctx.dispatch(new RemoveProgressBarStack({ uniqueId: 'GetTrips' }));
        const prevData: any = ctx.getState().trip;
        let tripResult;
        if (action.offset === 0) {
          tripResult = result.data;
        } else {
          tripResult = { trip: prevData?.['trip'].concat(result.data.trip) };
        }

        ctx.setState({
          ...ctx.getState(),
          trip: tripResult as any,
        });
      });
  }
}
