import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CardAdapterChildComponent } from '@app/finxone-web-frontend/app/lib/services/card-adapter-service/card-adapter-child-component';
import { PaymentProviderType } from '@finxone-platform/shared/sys-config-types';
import { AdapterCardComponent } from '../adapter-card-component/adapter-card.component';

@Component({
  selector: 'finxone-web-frontend-meawallet-card',
  templateUrl: './meawallet-card.component.html',
  styleUrls: ['./meawallet-card.component.scss'],
})
export class MeaWalletCardComponent
  extends CardAdapterChildComponent<PaymentProviderType>
  implements AfterViewInit
{
  @ViewChild('ififrame') ifIframe: ElementRef;
  iframeUrl: SafeResourceUrl;
  globalStyle: string;

  constructor(
    private router: Router,
    @Inject(AdapterCardComponent) parent: AdapterCardComponent,
  ) {
    super();
    this.parent = parent;
    this.iframeUrl = this.parent.cardIFrameUrl;
  }

  ngAfterViewInit() {
    console.log('this.parent', this.parent);
    this.globalStyle = document?.documentElement?.style?.cssText;
  }

  goToCardManagement(): void {
    this.router.navigateByUrl('/zones/card-management/management');
  }
}
