import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Constants } from '@finxone-platform/shared/sys-config-types';

export function customValidator(validatorFn: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return validatorFn(control);
  };
}

export function validDayOfMonthYearValidator(
  yearControlName: string,
  monthControlName: string,
  dayControlName: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const year = control.get(yearControlName)?.value;
    const month = control.get(monthControlName)?.value;
    const day = control.get(dayControlName)?.value;
    const maxDaysInMonth =
      month <= 11 ? 32 - new Date(year, month, 32).getDate() : NaN;
    return day > maxDaysInMonth
      ? {
          error: `There are not ${day} days in ${Constants.findMonthNameByKey(
            month,
          )} ${year}`,
        }
      : null;
  };
}

export function checkMinAgeValidation(minage: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null) {
      return null;
    }
    const minageYear = new Date().getFullYear() - minage;
    return minageYear >= control.value.year
      ? null
      : {
          error:
            ' You need to be at least ' +
            minage +
            ' years old to meet the criteria.',
        };
  };
}

export function noNumbersValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const hasNumber = /\d/.test(control.value);
    return hasNumber ? { noNumbers: true } : null;
  };
}

export function noSpecialCharsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = nameRegex.test(control.value);
    return isValid ? null : { noSpecialChars: true };
  };
}

export const nameRegex = /^(?!.*--)[\p{L}\p{N}\s'-]*$/u;
