import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { Observable, catchError, tap } from 'rxjs';
import {
  AddProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';
import { AccountService } from '../services/account-service/account-service.service';
import {
  CreatePaymentRequest,
  PaymentRequestFailed,
  PaymentRequestSuccess,
  UpdatePaymentRequest,
  UpdatePaymentRequestFailed,
} from '../actions/payment-request.action';
import { TransferMoneyRequest } from '../services/account-service/account.type';
import { CreatePayment } from '../actions/payment.action';
import { SetPaymentRequestSummary } from '@app/finxone-web-frontend/app/lib/actions/notification.action';
import { PaymentRequestDetailResponse } from '@finxone-platform/shared/utils';

export interface PaymentStateModel {}

@State<PaymentStateModel>({
  name: 'paymentRequest',
  defaults: {},
})
@Injectable()
export class PaymentRequestState {
  constructor(private accountService: AccountService) {}

  @Action(CreatePaymentRequest)
  createPayment(
    ctx: StateContext<PaymentStateModel>,
    payload: CreatePaymentRequest,
  ) {
    ctx.dispatch(new AddProgressBarStack({ uniqueId: 'CreatePaymentRequest' }));

    return this.accountService.makePaymentRequest(payload.paymentRequest).pipe(
      tap(() => {
        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'CreatePaymentRequest' }),
        );
        ctx.dispatch(new PaymentRequestSuccess());
      }),
      catchError<unknown, Observable<boolean>>((_err) => {
        ctx.dispatch(
          new RemoveProgressBarStack({ uniqueId: 'CreatePaymentRequest' }),
        );
        ctx.dispatch(new PaymentRequestFailed());
        throw _err;
      }),
    );
  }

  @Action(UpdatePaymentRequest)
  updatePaymentRequest(
    ctx: StateContext<PaymentStateModel>,
    payload: UpdatePaymentRequest,
  ) {
    const approvalStatus = payload.paymentRequest.approvedStatus;
    return this.accountService
      .updatePaymentRequest(payload.paymentRequest)
      .pipe(
        tap((response: PaymentRequestDetailResponse) => {
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'UpdatePaymentRequest' }),
          );

          // update the summary data
          ctx.dispatch(
            new SetPaymentRequestSummary({
              ...response,
              amount: response.amount.toString(),
              approvedOrRejectedDate:
                response.approvedOrRejectedDate?.toString() || '',
              createdDate: response.createdDate?.toString() || '',
            }),
          );

          if (approvalStatus === 'approved') {
            // create the payment if approved
            const payload: TransferMoneyRequest = {
              sourceAccountId: response.requester.accountId,
              bid: response.beneficiary.id,
              amount: response.amount as unknown as number,
              paymentReference: response.paymentReference,
              transferReasonId: response.paymentReasonId,
            };
            ctx.dispatch(new CreatePayment(payload));
          }

          return response;
        }),
        catchError<unknown, Observable<boolean>>((_err) => {
          ctx.dispatch(
            new RemoveProgressBarStack({ uniqueId: 'UpdatePaymentRequest' }),
          );
          ctx.dispatch(new UpdatePaymentRequestFailed());
          throw _err;
        }),
      );
  }
}
