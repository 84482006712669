import { CurrencyPipe } from '@angular/common';
import {
  FeeUnitChargeTypeEnum,
  unitChargeOptions,
} from '@finxone-platform/shared/sys-config-types';

const unitChargeAmountOptions = [
  { value: FeeUnitChargeTypeEnum.FIXED_FEE, title: 'Fixed Fee' },
];

export function valueToTitleChange(type: string): string {
  const option = unitChargeOptions.find((option) => option.value === type);
  return option ? option.title : '--';
}

export function displayFeeCharge(
  feeCharge: number,
  unitChargeType: string,
  currency: string,
  currencyPipe: CurrencyPipe,
): string {
  const isOption = unitChargeAmountOptions.some(
    (option) => option.value === unitChargeType,
  );

  if (isOption) {
    return currencyPipe.transform(feeCharge, currency, 'symbol-narrow') ?? '';
  } else {
    return `${feeCharge} % of balance`;
  }
}
