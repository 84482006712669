import { currencyCodeToFlag } from './currency-code-to-flag';

export function currencyToCircleFlag(
  currencyCode: string,
  width?: string,
): string {
  const entry = currencyCodeToFlag.find(
    (option: { country_code: string; currency_code: string }) => {
      return option.currency_code === currencyCode?.toUpperCase();
    },
  );
  if (entry) {
    return countryCodeToCircleFlag(entry.country_code, width);
  } else {
    return '';
  }
}

export function countryCodeToCircleFlag(
  countryCode: string,
  width?: string,
): string {
  const lowercasedCountryCode = checkAnomalyCountryCode(
    countryCode.toLowerCase(),
  );
  if (width) {
    return `<img src="/assets/flags/${lowercasedCountryCode}.svg" width="${width}">`;
  } else {
    return `<img src="/assets/flags/${lowercasedCountryCode}.svg">`;
  }
}

//from the circle-flags package, Saint Helena and Svalbard and Jan Maye codes are folders which link to the codes 'sh-hl' and 'no' respectively see below
// https://github.com/HatScripts/circle-flags/blob/gh-pages/flags/sh.svg https://github.com/HatScripts/circle-flags/blob/gh-pages/flags/sj.svg
function checkAnomalyCountryCode(countryCode: string) {
  let lowerCasedCountryCode;
  switch (countryCode) {
    case 'sh':
      lowerCasedCountryCode = 'sh-hl';
      break;
    case 'sj':
      lowerCasedCountryCode = 'no';
      break;
    default:
      lowerCasedCountryCode = countryCode;
      break;
  }
  return lowerCasedCountryCode;
}
