import { Injectable } from '@angular/core';
import {
  ActiveDevice,
  SupportedDevice,
} from '@finxone-platform/shared/sys-config-types';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
const DEVICE_TYPE = 'device_type';
@Injectable({
  providedIn: 'root',
})
export class DeviceTypeService {
  private _deviceType: SupportedDevice = ActiveDevice.MOBILE;
  deviceInfo: DeviceInfo;
  constructor(private deviceDetectorService: DeviceDetectorService) {}
  get deviceType(): SupportedDevice {
    return this._deviceType;
  }

  set deviceType(value: SupportedDevice) {
    this._deviceType = value;
  }

  get currentdeviceType(): SupportedDevice {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    let deviceType = this.deviceInfo.deviceType as SupportedDevice;
    if (deviceType !== this._deviceType) {
      this._deviceType = deviceType;
      localStorage.setItem(DEVICE_TYPE, deviceType);
    }
    switch (this._deviceType) {
      case ActiveDevice.MOBILE: {
        deviceType = ActiveDevice.MOBILE;
        break;
      }
      case ActiveDevice.TABLET: {
        deviceType = ActiveDevice.MOBILE;
        break;
      }
      case ActiveDevice.DESKTOP: {
        deviceType = ActiveDevice.DESKTOP;
        break;
      }
    }
    return deviceType;
  }
}
