import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  AddProgressBarStack,
  RemoveAllProgressBarStack,
  RemoveProgressBarStack,
} from '../actions/progress-bar.action';

export interface ProgressBarStateModel {
  widgets: Array<{ uniqueId?: string | number }>;
}

@State<ProgressBarStateModel>({
  name: 'progressBarWidgets',
  defaults: {
    widgets: [],
  },
})
@Injectable()
export class ProgressBarState {
  @Selector()
  static getProgressBarStack(state: ProgressBarStateModel) {
    return state.widgets;
  }

  @Action(AddProgressBarStack)
  addProgressBarStack(
    ctx: StateContext<ProgressBarStateModel>,
    action: AddProgressBarStack,
  ) {
    /* get the old value from state */
    const currentState = ctx.getState().widgets;

    /* check the existing value with new object */
    const isExist = currentState.some(
      (widget) => widget?.uniqueId === action.widget?.uniqueId,
    );

    /* if it is there then return the function. */
    if (isExist) {
      return;
    }

    /* update the state */
    ctx.patchState({
      widgets: [...currentState, action.widget],
    });
  }

  @Action(RemoveProgressBarStack)
  removeProgressBarStack(
    ctx: StateContext<ProgressBarStateModel>,
    action: RemoveProgressBarStack,
  ) {
    let currentState = ctx.getState().widgets;

    /* filter out state's data without action's object*/
    currentState = currentState.filter(
      (widget) => widget?.uniqueId !== action.widget?.uniqueId,
    );

    /* update the state */
    ctx.patchState({
      widgets: [...currentState],
    });
  }

  @Action(RemoveAllProgressBarStack)
  removeAllProgressBarStack(ctx: StateContext<ProgressBarStateModel>) {
    /* update the state */
    ctx.patchState({
      widgets: [],
    });
  }
}
