import { Injectable } from '@angular/core';
import { SetPageContext } from '@app/finxone-web-frontend/app/lib/actions/page-context.action';
import { Action, Selector, State, StateContext } from '@ngxs/store';

export interface PageContextStateModel {
  pageContextData: Record<string, string>;
}

@State<PageContextStateModel>({
  name: 'pageContextState',
})
@Injectable()
export class PageContextState {
  @Selector()
  static getPageContextState(state: PageContextStateModel) {
    return state;
  }

  @Action(SetPageContext)
  setPageContext(
    ctx: StateContext<PageContextStateModel>,
    action: SetPageContext,
  ) {
    const state = ctx.getState();
    ctx.setState({
      pageContextData: {
        ...state.pageContextData,
        ...action.payload.pageContextData,
      },
    });
  }
}
