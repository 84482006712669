import { Router } from '@angular/router';
import { FormSubmissionService } from '@app/finxone-web-frontend/app/lib/services/form-submission-service/form-submission-service.service';
import {
  emailToGuests,
  goToEditEvent,
  goToInviteGuest,
  inviteGuest,
} from '@app/finxone-web-frontend/app/lib/utils/cta-button-actions/broker-zones/invite-guest-action.utils';
import {
  BulkPaymentMasterStatus,
  FormActionTypeEnum,
} from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  BaseWidgetProperties,
  ButtonActionType,
  DocumentType,
  KycStatus,
  UiZoneWidgetAttributeConfig
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ResetFormDataAction } from '../../actions/form-submission.action';
import { AccountService } from '../../services/account-service/account-service.service';
import { BulkPaymentService } from '../../services/bulk-payment-service/bulk-payment-service.service';
import { ConfigService } from '../../services/config-service/config-service.service';
import { CtaButtonSignalService } from '../../services/cta-button-signal-service/cta-button-signal.service';
import { FeeManagementService } from '../../services/fee-management-service/fee-management.service';
import { GraphqlServiceService } from '../../services/graphql-service/graphql-service.service';
import { KeycloakWrapperService } from '../../services/keycloak-wrapper-service/keycloak-wrapper.service';
import { MetadataService } from '../../services/metadata-service/metadata-service.service';
import { BulkPaymentListState } from '../../state/bulk-payment.state';
import { FormActionState } from '../../state/form-submision.state';
import { ProfileState } from '../../state/user-profile.state';
import { formatNameAsUrl } from '../zone-url.utils';
import {
  addEmployeeMethod,
  inviteEmployeeMethod,
} from './add-employee/add-employee-action.utils';
import { editBeneficiary } from './beneficiary-zone/edit-beneficiary.utils';
import { setBeneficiaryDataAndRedirectToEditBeneficiaryPage } from './beneficiary-zone/redirect-to-edit-beneficiary.utils';
import { removeBeneficiaryFromAccount } from './beneficiary-zone/remove-beneficiary.utils';
import { submitEditBeneficiaryRequest } from './beneficiary-zone/submit-edit-beneficiary-request.utils';
import { addEmployeeToTripAction } from './broker-employee/add-employee-trip';
import { removeGuestFromEvent } from './broker-zones/remove-guest-actions.utils';
import {
  addBeneficiary,
  approveBulkPayment,
  bulkPaymentApproveRejectButtonStatus,
  bulkPaymentApproverRejectButton,
  cancelAllPayment,
  downloadCsvTemplate,
  goToPrePayment,
  makePayment,
  proceedBulkPayment,
  proceedCSVFile,
  refreshBulkPayment,
  rejectAllPayment,
} from './bulk-payments/bulk-payments.utils';
import { subscribeToAddEditFeeFormStateDataToHandleBtnValidation } from './cta-button-validations/add-edit-fee-cta.utils';
import { checkAuthSmsStatusAndRedirect } from './domestic-payments/check-sms-auth-status-and-redirect';
import { paymentApproverInitiateDomesticPayment } from './domestic-payments/payment-approver-init-payment.utils';
import { checkPaymentRequest } from './domestic-payments/payment-request';
import { paymentRequestApprove } from './domestic-payments/payment-request-approve';
import { paymentRequestProcessing } from './domestic-payments/payment-request-processing';
import { paymentRequestReject } from './domestic-payments/payment-request-reject';
import { handleAddEditFeeSubmission } from './fee-management-zone/add-edit-fee-action.utils';
import { handleAddEditFeeGroupSubmission } from './fee-management-zone/add-edit-fee-group-action.utils';
import { handleAdHocFeeSubmission } from './fee-management-zone/create-ad-hoc-fee-action.utils';
import {
  removeFeeAction,
  removeFeeGroupAction,
} from './fee-management-zone/remove-action.utils';
import { handleRemoveClientFromFeeGroupRequest } from './fee-management-zone/remove-client-from-fee-group-action.utils';
import { handleUpdateClientFeeGroupRequest } from './fee-management-zone/update-client-fee-group.utils';
import { addInternationalBeneficiary } from './intl-payments/add-international-beneficiary';
import { checkSourceAccountAndRedirectToPayNewIntlBeneficiary } from './intl-payments/check-source-account-and-redirect-intl-beneficiary';
import { submitInternationPayment } from './intl-payments/submit-international-payment';
import { getOrganisationDataFromStoreAndCallUpdateMethod } from './kyb-zone/kyb-zone-action.utils';
import { checkFormActionDataKycDetailsAndRedirect } from './kyc-zone/kyc-zone-actions.utils';
import { assignPersonaUser } from './manage-users-zone/manage-users-actions.utils';
import { handleBusinessUserRedirectionOnOnboardingFlows } from './onboarding-zone/business-user-onboarding-redirection-action.utils';
import { downloadBankStatement } from './profile-zone/download-bank-statement.utils';
import { submitProfileZoneUserProfileChanges } from './profile-zone/edit-user-profile.utils';
import { saveTransactionAction } from './revenir-zone/add-transaction-utils';
import { addUserSendInvite } from './user-management-zone-actions/add-user-send-invite.utils';
import { resetAssignPersonaFormStateAndRedirect } from './user-management-zone-actions/assign-persona-flow-actions.utils';
import { mangeUseredit } from './user-management-zone-actions/manage-user-edit.utils';
import { submitVerificationProfileChanges } from './verification-zone/submit-profile-changes-in-verification-flow';

export const ctaButtonFunctionsMap: Map<
  ButtonActionType,
  (
    widgetProperties: BaseWidgetProperties,
    store: Store,
    actions: Actions,
    router: Router,
    alertHandlerService: AlertHandlerService,
    metadataService: MetadataService,
    graphqlService?: GraphqlServiceService,
    attri?: UiZoneWidgetAttributeConfig,
    accountService?: AccountService,
    formSubmissionService?: FormSubmissionService,
    configService?: ConfigService,
    feeManagementService?: FeeManagementService,
    keycloakService?: KeycloakWrapperService,
    ctaButtonSignalService?: CtaButtonSignalService,
    bulkPaymentService?: BulkPaymentService,
  ) => void
> = new Map<ButtonActionType, any>([
  [
    ButtonActionType.KYC_EMAIL_PHONE_VERIFIED_CHECK,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      checkFormActionDataKycDetailsAndRedirect(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.UPDATE_ORGANISATION,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      getOrganisationDataFromStoreAndCallUpdateMethod(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.INVITE_GUEST,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      inviteGuest(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.GO_TO_INVITE_GUEST,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      goToInviteGuest(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.REMOVE_GUEST_FROM_EVENT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
    ) => {
      removeGuestFromEvent(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
        graphqlService,
      );
    },
  ],
  [
    ButtonActionType.ASSIGN_PERSONA_FORM_STATE_CLEAR_AND_REDIRECT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
    ) => {
      resetAssignPersonaFormStateAndRedirect(
        widgetProperties,
        store,
        actions,
        router,
      );
    },
  ],
  [
    ButtonActionType.ADD_EMPLOYEE_CONFIRM_SAVE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
    ) => {
      addEmployeeMethod(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
        graphqlService,
        attri,
      );
    },
  ],

  [
    ButtonActionType.ADD_EMPLOYEE_INVITE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      inviteEmployeeMethod(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.ASSIGN_PERSONA,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      assignPersonaUser(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.ADD_USER_SEND_INVITE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      addUserSendInvite(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.PAY_NEW_INTL_BENEFICIARY,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      checkSourceAccountAndRedirectToPayNewIntlBeneficiary(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.REDIRECT_TO_EDIT_BENEFICIARY,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      setBeneficiaryDataAndRedirectToEditBeneficiaryPage(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_EDIT_BENEFICIARY,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
    ) => {
      submitEditBeneficiaryRequest(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
        graphqlService,
        attri,
        accountService,
      );
    },
  ],
  [
    ButtonActionType.REMOVE_BENEFICIARY,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
    ) => {
      removeBeneficiaryFromAccount(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
        graphqlService,
        attri,
        accountService,
      );
    },
  ],
  [
    ButtonActionType.EDIT_BENEFICIARY,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
    ) => {
      editBeneficiary(widgetProperties, store, actions, router);
    },
  ],
  [
    ButtonActionType.MANAGE_USER_EDIT_AND_SAVE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      mangeUseredit(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.ADD_EMPLOYEE_TO_TRIP_CONFIRM,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      addEmployeeToTripAction(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
      );
    },
  ],
  [
    ButtonActionType.EMAIL_ALL_GUESTS,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      emailToGuests(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.GO_TO_EDIT_EVENT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
    ) => {
      goToEditEvent(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
      );
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      downloadCsvTemplate(alertHandlerService);
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
    ) => {
      checkPaymentRequest(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        formSubmissionService,
        configService,
      );
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_PROCESSING,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
    ) => {
      paymentRequestProcessing(widgetProperties, store, actions, router);
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_APPROVE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      paymentRequestApprove(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
      );
    },
  ],
  [
    ButtonActionType.PAYMENT_REQUEST_REJECT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      paymentRequestReject(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
      );
    },
  ],
  [
    ButtonActionType.PAYMENT_APPROVER_MAKE_PAYMENT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      paymentApproverInitiateDomesticPayment(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
    ) => {
      proceedCSVFile(store, accountService, router, alertHandlerService);
    },
  ],
  [
    ButtonActionType.REFRESH_BULK_PAYMENT_LIST,
    (widgetProperties: BaseWidgetProperties, store: Store) => {
      refreshBulkPayment(store);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_BENEFICIARY_VALIDATION,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
    ) => {
      goToPrePayment(router);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_ADD_BENEFICIARY,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
    ) => {
      addBeneficiary(store, router, alertHandlerService, accountService);
    },
  ],
  [
    ButtonActionType.DOWNLOAD_CSV_BANK_STATEMENT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
    ) => {
      downloadBankStatement(widgetProperties, store, router, DocumentType.CSV);
    },
  ],
  [
    ButtonActionType.DOWNLOAD_PDF_BANK_STATEMENT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
    ) => {
      downloadBankStatement(widgetProperties, store, router, DocumentType.PDF);
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_MAKE_PAYMENT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
      keycloakService: KeycloakWrapperService,
      ctaButtonSignalService: CtaButtonSignalService,
      bulkPaymentService: BulkPaymentService,
    ) => {
      makePayment(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
        metadataService,
        graphqlService,
        attri,
        accountService,
        formSubmissionService,
        configService,
        feeManagementService,
        keycloakService,
        ctaButtonSignalService,
        bulkPaymentService,
      );
    },
  ],
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_PERSONAL,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.REVIEW_INFORMATION_VERIFICATION_BUSINESS,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.CHECK_SMS_AUTH_STATUS_AND_REDIRECT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
    ) => {
      checkAuthSmsStatusAndRedirect(router, widgetProperties, store, actions);
    },
  ],
  [
    ButtonActionType.INIT_INTL_PAYMENT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      submitInternationPayment(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
      );
    },
  ],
  [
    ButtonActionType.TRIP_START_CONTINUE,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_END_CONTINUE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.ADD_EDIT_FEE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
    ) => {
      handleAddEditFeeSubmission(
        widgetProperties,
        store,
        __,
        router,
        alertHandlerService,
        feeManagementService,
      );
    },
  ],
  [
    ButtonActionType.CREATE_AD_HOC_FEE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
    ) => {
      handleAdHocFeeSubmission(
        widgetProperties,
        store,
        __,
        router,
        alertHandlerService,
        feeManagementService,
      );
    },
  ],
  [
    ButtonActionType.ADD_EDIT_FEE_GROUP,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
    ) => {
      handleAddEditFeeGroupSubmission(
        widgetProperties,
        store,
        __,
        router,
        alertHandlerService,
        feeManagementService,
      );
    },
  ],
  [
    ButtonActionType.UPDATE_CLIENT_FEE_GROUP,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
    ) => {
      handleUpdateClientFeeGroupRequest(
        widgetProperties,
        store,
        __,
        router,
        alertHandlerService,
        feeManagementService,
      );
    },
  ],
  [
    ButtonActionType.REDIRECT_BUSINESS_USER_TO_LOGIN,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
      keycloakService: KeycloakWrapperService,
    ) => {
      handleBusinessUserRedirectionOnOnboardingFlows(
        widgetProperties,
        store,
        router,
        keycloakService,
        alertHandlerService,
        metadataService,
        graphqlService,
        attri,
        accountService,
        formSubmissionService,
        configService,
      );
    },
  ],
  [
    ButtonActionType.REMOVE_CLIENT_FROM_FEE_GROUP,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
    ) => {
      handleRemoveClientFromFeeGroupRequest(
        widgetProperties,
        store,
        __,
        router,
        alertHandlerService,
        feeManagementService,
      );
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_UPLOAD_PASS,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.CLEAR_FORM_STATE_AND_REDIRECT_TO_FEE_GROUPS_LISTING,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
    ) => {
      store.dispatch(new ResetFormDataAction());
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.TRIP_LEAVING_COUNTRY_CONTINUE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(
          FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
        ),
      );
      let isButtonClick = true;
      data.subscribe({
        next: (res) => {
          if (isButtonClick) {
            if (
              res?.formData?.['entering_country'] !==
              res?.formData?.['leaving_country']
            ) {
              widgetProperties['urlToNavigate'] =
                'multiple-country-notification';
              redirectToPage(router, widgetProperties);
            } else if (
              res?.formData?.['entering_country'] == 'belgium' ||
              res?.formData?.['leaving_country'] == 'belgium'
            ) {
              widgetProperties['urlToNavigate'] = 'upload-boarding-pass';
              redirectToPage(router, widgetProperties);
            } else {
              widgetProperties['urlToNavigate'] = 'exit-point';
              redirectToPage(router, widgetProperties);
            }
          }
          isButtonClick = false;
        },
      });
    },
  ],
  [
    ButtonActionType.MULTIPLE_COUNTRY_NOTI,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(
          FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
        ),
      );
      let isButtonClick = true;
      data.subscribe({
        next: (res) => {
          if (isButtonClick) {
            if (
              res?.formData?.['entering_country'] == 'belgium' ||
              res?.formData?.['leaving_country'] == 'belgium'
            ) {
              widgetProperties['urlToNavigate'] = 'upload-boarding-pass';
              redirectToPage(router, widgetProperties);
            } else {
              widgetProperties['urlToNavigate'] = 'exit-point';
              redirectToPage(router, widgetProperties);
            }
            isButtonClick = false;
          }
        },
      });
    },
  ],

  [
    ButtonActionType.TRIP_EXIT_POINT_CONTINUE,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.REMOVE_FEE_GROUP,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
    ) => {
      removeFeeGroupAction(
        widgetProperties,
        store,
        __,
        router,
        alertHandlerService,
        feeManagementService,
      );
    },
  ],
  [
    ButtonActionType.REMOVE_FEE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
    ) => {
      removeFeeAction(
        widgetProperties,
        store,
        __,
        router,
        alertHandlerService,
        feeManagementService,
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_VERIFICATION_FLOW,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
    ) => {
      submitVerificationProfileChanges(
        store,
        actions,
        router,
        formSubmissionService,
      );
    },
  ],
  [
    ButtonActionType.SUBMIT_PROFILE_CHANGES_IN_PROFILE_ZONE,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
    ) => {
      submitProfileZoneUserProfileChanges(
        store,
        actions,
        router,
        formSubmissionService,
      );
    },
  ],
  [
    ButtonActionType.ADD_TRANSACTION_STEP_1,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      __: Actions,
      router: Router,
    ) => {
      saveTransactionAction(widgetProperties, store, __, router);
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_AUTHENTICATION_LINK,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      proceedBulkPayment(
        widgetProperties,
        store,
        actions,
        router,
        alertHandlerService,
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_CANCEL,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
    ) => {
      cancelAllPayment(store, router, alertHandlerService, accountService);
    },
  ],
  [
    ButtonActionType.VERIFICATION_EDIT_INFORMATION,
    (
      widgetProperties: BaseWidgetProperties,
      _: Store,
      __: Actions,
      router: Router,
    ) => {
      redirectToPage(router, widgetProperties);
    },
  ],
  [
    ButtonActionType.ADD_INTERNATIONAL_BENEFICIARY,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      _: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      __: MetadataService,
      ___: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      ____: ConfigService,
      _____: FeeManagementService,
      ______: KeycloakWrapperService,
      ctaButtonSignalService: CtaButtonSignalService,
    ) => {
      ctaButtonSignalService.setSignal({
        ['add_international_beneficiary']: false,
      });
      addInternationalBeneficiary(
        store,
        router,
        alertHandlerService,
        attri,
        accountService,
        formSubmissionService,
        ctaButtonSignalService,
      );
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
    ) => {
      approveBulkPayment(
        widgetProperties,
        store,
        router,
        alertHandlerService,
        actions,
      );
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    (
      widgetProperties: BaseWidgetProperties,
      store: Store,
      actions: Actions,
      router: Router,
      alertHandlerService: AlertHandlerService,
      metadataService: MetadataService,
      graphqlService: GraphqlServiceService,
      attri: UiZoneWidgetAttributeConfig,
      accountService: AccountService,
      formSubmissionService: FormSubmissionService,
      configService: ConfigService,
      feeManagementService: FeeManagementService,
      keycloakService: KeycloakWrapperService,
      ctaButtonSignalService: CtaButtonSignalService,
      bulkPaymentService: BulkPaymentService,
    ) => {
      rejectAllPayment(store, router, alertHandlerService, bulkPaymentService);
    },
  ],
]);

export function redirectToPage(
  router: Router,
  widgetProperties: BaseWidgetProperties,
) {
  router.navigateByUrl(
    `/zones/${formatNameAsUrl(widgetProperties['zoneToNavigate'])}/${
      widgetProperties['urlToNavigate']
    }`,
  );
}

export const ctaButtonValidationMap: Map<
  ButtonActionType,
  (
    store: Store,
    ctaButtonSignalService: CtaButtonSignalService,
    attri?: UiZoneWidgetAttributeConfig,
    configService?: ConfigService,
  ) => Observable<any>
> = new Map<ButtonActionType, any>([
  [
    ButtonActionType.BULK_PAYMENT_ADD_BENEFICIARY,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(BulkPaymentListState.getBulkPaymentList);

      return data.subscribe({
        next: (res) => {
          const totalErrorPayments =
            res?.meta?.bulkPaymentsSummary?.totalErrors;
          const totalItems = res?.meta?.bulkPaymentsSummary?.totalPayments;
          const totalPendingPayments =
            res?.meta?.bulkPaymentsSummary?.totalPendingPayments;
          let isDisableButton = false;
          if (totalItems && !totalErrorPayments && !totalPendingPayments) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_BENEFICIARY_VALIDATION,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(BulkPaymentListState.getBulkPaymentList);

      return data.subscribe({
        next: (res) => {
          const totalErrorPayments =
            res?.meta?.bulkPaymentsSummary?.totalErrors;
          const totalPendingBeneficiary =
            res?.meta?.bulkPaymentsSummary?.totalPendingBeneficiary;
          let isDisableButton = false;
          if (!totalErrorPayments && !totalPendingBeneficiary) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_START_CONTINUE,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(attri?.['formKey']),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: res?.formData?.['startDate'] ? true : false,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_END_CONTINUE,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(attri?.['formKey']),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]:
              res?.formData?.['startDate'] && res?.formData?.['endDate']
                ? true
                : false,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_COUNTRY_CONTINUE,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(
          FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
        ),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: !!res?.formData?.['entering_country'],
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_UPLOAD_PASS,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(
          FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
        ),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: !!res?.formData?.['trip-boarding-destination'],
          });
        },
      });
    },
  ],
  [
    ButtonActionType.TRIP_LEAVING_COUNTRY_CONTINUE,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(
          FormActionTypeEnum.ADD_TRIP_REVENIR_ZONE,
        ),
      );

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: !!res?.formData?.['leaving_country'],
          });
        },
      });
    },
  ],

  [
    ButtonActionType.BULK_PAYMENT_DOWNLOAD_UPLOAD_PROCESSING,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(
        FormActionState.getFormActionStateWithId(
          FormActionTypeEnum.BULK_PAYMENTS,
        ),
      );
      return data.subscribe({
        next: (res) => {
          let isDisableButton = false;
          if (res?.formData?.myFiles?.length) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_AUTHENTICATION_LINK,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(BulkPaymentListState.getBulkPaymentList);
      return data.subscribe({
        next: (res) => {
          const masterBulkPaymentStatus =
            res?.meta?.bulkPaymentsSummary?.masterStatus;
          let isDisableButton = false;
          if (
            [BulkPaymentMasterStatus.PENDING].includes(
              masterBulkPaymentStatus as unknown as BulkPaymentMasterStatus,
            )
          ) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_CANCEL,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const bulkPaymentData = store.select(
        BulkPaymentListState.getBulkPaymentList,
      );
      return bulkPaymentData.subscribe({
        next: (res) => {
          const masterBulkPaymentStatus =
            res?.meta?.bulkPaymentsSummary?.masterStatus;
          let isDisableButton = false;
          if (
            BulkPaymentMasterStatus.PENDING ==
            (masterBulkPaymentStatus as unknown as BulkPaymentMasterStatus)
          ) {
            isDisableButton = true;
          }
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]: isDisableButton,
          });
        },
      });
    },
  ],

  [
    ButtonActionType.VERIFICATION_EDIT_INFORMATION,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      const data = store.select(ProfileState.getProfile);

      return data.subscribe({
        next: (res) => {
          const key = attri?.['buttonActionType'] as string;
          ctaButtonSignalService.setSignal({
            [key]:
              res?.kycVerificationStatus !== KycStatus.NOT_STARTED
                ? false
                : true,
          });
        },
      });
    },
  ],
  [
    ButtonActionType.CREATE_AD_HOC_FEE,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      subscribeToAddEditFeeFormStateDataToHandleBtnValidation(
        store,
        ctaButtonSignalService,
        attri,
        'ad-hoc',
      );
    },
  ],
  [
    ButtonActionType.ADD_EDIT_FEE,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri?: UiZoneWidgetAttributeConfig,
    ) => {
      subscribeToAddEditFeeFormStateDataToHandleBtnValidation(
        store,
        ctaButtonSignalService,
        attri,
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproverRejectButton(
        store,
        ctaButtonSignalService,
        attri,
        configService,
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproverRejectButton(
        store,
        ctaButtonSignalService,
        attri,
        configService,
      );
    },
  ],
]);

export const ctaButtonShowHideMap: Map<
  ButtonActionType,
  (
    store: Store,
    ctaButtonSignalService: CtaButtonSignalService,
    attri?: UiZoneWidgetAttributeConfig,
    configService?: ConfigService,
  ) => Observable<any>
> = new Map<ButtonActionType, any>([
  [
    ButtonActionType.BULK_PAYMENT_APPROVER,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproveRejectButtonStatus(
        store,
        ctaButtonSignalService,
        attri,
        configService,
      );
    },
  ],
  [
    ButtonActionType.BULK_PAYMENT_REJECT,
    (
      store: Store,
      ctaButtonSignalService: CtaButtonSignalService,
      attri: UiZoneWidgetAttributeConfig,
      configService: ConfigService,
    ) => {
      bulkPaymentApproveRejectButtonStatus(
        store,
        ctaButtonSignalService,
        attri,
        configService,
      );
    },
  ],
]);
