import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CardInfoType,
  ChangePinRequest,
  PaginatedResponse,
  ReplaceCardRequest,
  SecureCardInfoResponse,
  SecureCardTokenInfo,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { Observable, Subject, catchError, map } from 'rxjs';
import { ConfigService } from '../config-service/config-service.service';

@Injectable({ providedIn: 'root' })
export class CardManagementService {
  private baseUrl = '';

  sendDataToBottomPopup: Subject<{
    popupIndex: number;
    isFullPagePopup: boolean;
  }> = new Subject();

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private store: Store,
  ) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  getCards(page = 1, size = 20): Observable<PaginatedResponse<CardInfoType>> {
    return this.http
      .get<PaginatedResponse<CardInfoType>>(
        `${this.baseUrl}/cards&page=${page}&size=${size}`,
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catchError<any, Observable<boolean>>((_err) => {
          console.error(`Error getting account cards: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  freezeCard(request: { accountId: string; cardId: string }) {
    return this.http
      .post<boolean>(this.baseUrl + '/cards/freeze', request)
      .pipe(
        catchError<boolean, Observable<boolean>>((_err) => {
          console.error(`Error fetching freezeCard: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  unfreezeCard(request: { accountId: string; cardId: string }) {
    return this.http
      .post<boolean>(this.baseUrl + '/cards/unfreeze', request)
      .pipe(
        catchError<boolean, Observable<boolean>>((_err) => {
          console.error(`Error fetching freezeCard: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  replaceCard(payload: ReplaceCardRequest) {
    return this.http
      .post<CardInfoType>(this.baseUrl + '/cards/replace-card', payload)
      .pipe(
        catchError((_err) => {
          console.error(`Error fetching replaceCard: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getCardSecureToken(payload: {
    publicKey: string;
    tokenPurpose: string;
    accountId: string;
    cardId: string;
  }) {
    return this.http
      .post<SecureCardTokenInfo>(this.baseUrl + '/cards/secure-token', payload)
      .pipe(
        catchError<SecureCardTokenInfo, Observable<SecureCardTokenInfo>>(
          (_err) => {
            console.error(
              `Error fetching getCardSecureToken: ${JSON.stringify(_err)}`,
            );
            throw _err;
          },
        ),
      );
  }

  changePin(payload: ChangePinRequest) {
    return this.http
      .post<boolean>(this.baseUrl + '/cards/change-pin', payload)
      .pipe(
        catchError<boolean, Observable<boolean>>((_err) => {
          console.error(`Error fetching changePin: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  activateCard(payload: { accountId: string; cardId: string }) {
    return this.http
      .get<boolean>(this.baseUrl + '/cards/activate', { params: payload })
      .pipe(
        catchError<boolean, Observable<boolean>>((_err) => {
          console.error(`Error fetching activateCard: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getCVVDetails(payload: {
    accountId: string;
    cardId: string;
    secureToken: string;
  }) {
    return this.http
      .post<SecureCardInfoResponse>(
        this.baseUrl + '/cards/cvv-details',
        payload,
      )
      .pipe(
        catchError((_err) => {
          console.error(
            `Error fetching getCVVDetails: ${JSON.stringify(_err)}`,
          );
          throw _err;
        }),
      );
  }

  viewCardPin(payload: {
    accountId: string;
    cardId: string;
    secureToken: string;
  }) {
    return this.http
      .post<SecureCardInfoResponse>(this.baseUrl + '/cards/view-pin', payload)
      .pipe(
        catchError((_err) => {
          console.error(`Error fetching viewCardPin: ${JSON.stringify(_err)}`);
          throw _err;
        }),
      );
  }

  getCardDetails(payload: { accountId: string; cardId: string }) {
    return this.http
      .get<CardInfoType>(this.baseUrl + '/cards/details', {
        params: payload,
      })
      .pipe(
        catchError<CardInfoType, Observable<CardInfoType>>((_err) => {
          console.error(
            `Error fetching getCardDetails: ${JSON.stringify(_err)}`,
          );
          throw _err;
        }),
      );
  }

  createVirtualCard(accountId: string): Observable<string> {
    const request = {
      accountId: [accountId],
      cardType: 'virtual',
    };
    return this.http.post(this.baseUrl + '/cards', request).pipe(
      map(() => accountId),
      catchError((_err) => {
        console.error(
          `Error fetching createVirtualCard: ${JSON.stringify(_err)}`,
        );
        throw _err;
      }),
    );
  }

  createPhysicalCard(accountId: string): Observable<string> {
    const request = {
      accountId: [accountId],
      cardType: 'physical',
    };
    return this.http.post(this.baseUrl + '/cards', request).pipe(
      map(() => accountId),
      catchError((_err) => {
        console.error(
          `Error fetching createPhysicalCard: ${JSON.stringify(_err)}`,
        );
        throw _err;
      }),
    );
  }

  deleteVirtualCard(payload: {
    cardId: string;
    accountId: string;
  }): Observable<string> {
    return this.http.delete(`${this.baseUrl}/cards/${payload.cardId}`).pipe(
      map(() => payload.accountId),
      catchError((_err) => {
        console.error(
          `Error fetching deleteVirtualCard: ${JSON.stringify(_err)}`,
        );
        throw _err;
      }),
    );
  }
}
