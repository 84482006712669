export function imageFromImageName(
  imagePath: string,
  imageName: string,
  width?: string,
): string {
  const lowerCaseImageName = imageName.toLowerCase();
  if (width) {
    return `<img src="/assets/${imagePath}/${lowerCaseImageName}.svg" width="${width}">`;
  } else {
    return `<img src="/assets/${imagePath}/${lowerCaseImageName}.svg">`;
  }
}
