import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import {
  BaseWidgetProperties,
  DocumentType,
} from '@finxone-platform/shared/sys-config-types';
import { Store } from '@ngxs/store';
import { SetFormActionWithId } from '../../../actions/form-submission.action';
import { redirectToPage } from '../cta-button-actions.utils';

export const downloadBankStatement = (
  widgetProperties: BaseWidgetProperties,
  store: Store,
  router: Router,
  documentType: DocumentType,
) => {
  dispatchDocumentType(store, documentType);
  redirectToPage(router, widgetProperties);
};

const dispatchDocumentType = (store: Store, documentType: DocumentType) => {
  store.dispatch(
    new SetFormActionWithId(
      {
        type: '',
        formData: {
          documentType,
        },
      },
      FormActionTypeEnum.PROFILE_BANK_STATEMENT,
    ),
  );
};
