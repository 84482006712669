import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, map, of } from 'rxjs';
import { GetBulkPaymentHistory } from '../actions/bulk-payment-history.action';
import { AccountService } from '../services/account-service/account-service.service';
import { BulkPaymentHistoryResponse } from '../services/account-service/account.type';

@State<BulkPaymentHistoryResponse>({
  name: 'bulkPaymentHistory',
})
@Injectable()
export class BulkPaymentHistoryState {
  constructor(private accountService: AccountService) {}

  @Selector()
  static getBulkPaymentHistory(state: BulkPaymentHistoryResponse) {
    return state;
  }
  @Action(GetBulkPaymentHistory)
  fetchBulkPaymentHistory(
    ctx: StateContext<BulkPaymentHistoryResponse>,
    action: GetBulkPaymentHistory,
  ) {
    return this.accountService
      .getBulkPaymentHistory(action.page, action.size, action.searchValue)
      .pipe(
        map((response) => {
          ctx.patchState(response);
        }),
        catchError((error) => {
          console.error('Error fetching Bulk Payment History:', error);
          return of(null);
        }),
      );
  }
}
