import { Injectable } from '@angular/core';
import { OnboardingConfigFlowTypes } from '@finxone-platform/shared/sys-config-types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UpdateProjectSettings } from '../actions/project-settings.action';

export interface ProjectSettingsStateModel {
  onboardingFlow: OnboardingConfigFlowTypes;
  defaultCurrency: string;
  checkPayeeEnabled: boolean;
  smsAuthenticationEnabled: boolean;
  enableBeneficiaryFirstAndLastName: boolean;
}

@State<ProjectSettingsStateModel>({
  name: 'projectSettings',
  defaults: {
    onboardingFlow: '' as OnboardingConfigFlowTypes,
    defaultCurrency: 'GBP',
    checkPayeeEnabled: true,
    smsAuthenticationEnabled: true,
    enableBeneficiaryFirstAndLastName: false,
  },
})
@Injectable()
export class ProjectSettingsState {
  @Selector()
  static getProjectSettings(state: ProjectSettingsStateModel) {
    return state;
  }

  @Action(UpdateProjectSettings)
  updateRole(
    ctx: StateContext<ProjectSettingsStateModel>,
    action: UpdateProjectSettings,
  ) {
    ctx.patchState({
      onboardingFlow: action.payload.onboardingFlow,
      defaultCurrency: action.payload.defaultCurrency,
      checkPayeeEnabled: action.payload.checkPayeeEnabled,
      smsAuthenticationEnabled: action.payload.smsAuthenticationEnabled,
      enableBeneficiaryFirstAndLastName:
        action.payload.enableBeneficiaryFirstAndLastName,
    });
  }
}
