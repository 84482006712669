import { Router } from '@angular/router';
import { FormActionTypeEnum } from '@finxone-platform/form-action';
import { AlertHandlerService } from '@finxone-platform/shared/services';
import {
  AddEditFeeGroupRequestPayload,
  BaseWidgetProperties,
} from '@finxone-platform/shared/sys-config-types';
import { Actions, Store } from '@ngxs/store';
import { take, tap } from 'rxjs';
import { UpdateFormDataActionWithId } from '../../../actions/form-submission.action';
import { FeeManagementService } from '../../../services/fee-management-service/fee-management.service';
import { FormActionState } from '../../../state/form-submision.state';
import { redirectToPage } from '../cta-button-actions.utils';

export function handleAddEditFeeGroupSubmission(
  widgetProperties: BaseWidgetProperties,
  store: Store,
  __: Actions,
  router: Router,
  alertHandlerService: AlertHandlerService,
  feeManagementService: FeeManagementService,
) {
  const formData = store.selectSnapshot(
    FormActionState.getFormActionStateWithId(FormActionTypeEnum.ADD_FEE_GROUP),
  )?.formData;
  if (formData) {
    if (formData?.isFormValid) {
      const payload: AddEditFeeGroupRequestPayload = {
        name: formData?.groupName,
        description: formData?.description,
      };
      if (formData?.id) {
        feeManagementService
          .submitEditFeeGroupRequest(payload, formData.id)
          .pipe(
            take(1),
            tap((res) => {
              if (res) {
                alertHandlerService.showAlertFn(
                  'success',
                  'Fee group updated successfully',
                );
                redirectToPage(router, widgetProperties);
              }
            }),
          )
          .subscribe();
      } else {
        feeManagementService
          .submitAddFeeGroupRequest(payload)
          .pipe(
            take(1),
            tap((res) => {
              if (res) {
                alertHandlerService.showAlertFn(
                  'success',
                  'Fee group created successfully',
                );
                redirectToPage(router, widgetProperties);
              }
            }),
          )
          .subscribe();
      }
    } else {
      alertHandlerService.showAlertFn(
        'error',
        'Please provide all details in the form before proceeding',
      );
      store.dispatch(
        new UpdateFormDataActionWithId(
          { showFormErrors: true },
          FormActionTypeEnum.ADD_FEE_GROUP,
        ),
      );
    }
  }
}
