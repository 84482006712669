import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { ConfigService } from '@app/finxone-web-frontend/app/lib/services/config-service/config-service.service';
import {
  ExchangeQuotePayload,
  ExchangeTransactionResponse,
} from '@finxone-platform/shared/sys-config-types';

@Injectable({
  providedIn: 'root',
})
export class ExchangeService {
  private baseUrl = '';

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.configService.getApi('account_service').subscribe((response) => {
      this.baseUrl = response;
    });
  }

  public postExchangeQuote(requestData: ExchangeQuotePayload): Observable<any> {
    return this.http.post(this.baseUrl + '/exchanges/quote', requestData);
  }

  public postRefreshQuote(
    quoteId: string,
    requestData: ExchangeQuotePayload,
  ): Observable<any> {
    const refreshUrl = `${this.baseUrl + '/exchanges/quote'}/${quoteId}`;
    return this.http.post(refreshUrl, requestData);
  }

  public postAcceptQuote(quoteId: string): Observable<any> {
    return this.http.post(this.baseUrl + '/exchanges/transactions', {
      quoteId,
    });
  }

  public getExchangeDetail(
    exchangeId: string,
  ): Observable<ExchangeTransactionResponse> {
    const url = `${this.baseUrl}/exchanges/transactions/${exchangeId}`;

    return this.http.get<ExchangeTransactionResponse>(url).pipe(
      catchError<any, Observable<boolean>>((_err) => {
        console.error(
          `Error getting exchange detail for exchangeId ${exchangeId}: ${JSON.stringify(
            _err,
          )}`,
        );
        throw _err;
      }),
    );
  }
}
