import { ColourPaletteType } from '@finxone-platform/shared/sys-config-types';

export function setupNewThemeing(theme: ColourPaletteType) {
  const { ...themeWithoutName } = theme;
  const colorPrefixMap: { [key: string]: string } = {
    border_colors: 'border',
    typography: 'typography',
    alerts: 'alerts',
    palette: 'palette',
  };
  let colorPrefix: string;
  const colorProps: { [key: string]: { [key: string]: string } } = {};
  const rootStyle = document.documentElement.style;
  Object.entries(themeWithoutName).forEach((t) => {
    colorPrefix = colorPrefixMap[t[0]];
    const colors = t[0] !== 'typography' ? t[1] : t[1]['palette'];
    if (!colorProps[t[0]]) {
      colorProps[t[0]] = {};
    }
    for (let i = 0; i < Object.keys(colors).length; i++) {
      const colorKey = `--${colorPrefix}-color-${i}`;
      const rgbKey = `--${colorPrefix}-color-${i}-rgb`;
      rootStyle.setProperty(colorKey, colors[i.toString()]);
      rootStyle.setProperty(rgbKey, hexToRgb(colors[i.toString()]));
      colorProps[t[0]][colorKey] = colors[i.toString()];
    }
  });
  rootStyle.setProperty(
    '--font-family',
    theme.typography?.fontFamily ? theme.typography?.fontFamily : 'Mulish',
  );
}

export function hexToRgb(hex: string): any {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m: any, r: any, g: any, b: any) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? parseInt(result[1], 16) +
        ', ' +
        parseInt(result[2], 16) +
        ', ' +
        parseInt(result[3], 16)
    : 0 + ',' + 0 + ',' + 0;
}
